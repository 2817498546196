import { Grid, Typography, withStyles, withTheme } from '@material-ui/core';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Scroll from 'react-scroll';

import Log from 'utilities/log';
import StepLabel from 'modules/common/stepLabel.component';

import AuthorizationInformationForm from './authorizationInformationForm.component';
import DeparturePropertyForm from './departurePropertyForm.component';
import FamilyInformationForm from './familyInformationForm.component';
import HhgInformationForm from './hhgInformationForm.component';
import NewDestinationForm from './newDestinationForm.component';
import TemporaryLivingForm from './temporaryLivingForm.component';

const styles = (theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    marginTop: theme.spacing(2),
  },
  section: {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    paddingTop: '0 !important',
    paddingLeft: '32px !important',
    position: 'relative',
    '&:last-child': {
      borderLeft: 0,
    },
  },
  selectMoveTypeText: {
    marginLeft: 25,
    marginBottom: 20,
    marginTop: -30,
  },
});

class AuthorizationIntakeForm extends PureComponent {
  constructor(props) {
    super(props);
    this.authorizationInformation = React.createRef();
    this.familyInformation = React.createRef();
    this.departureProperty = React.createRef();
    this.hhgInformation = React.createRef();
    this.temporaryLiving = React.createRef();
    this.newDestination = React.createRef();

    this.state = {
      isDrawerOpen: false,
    };
  }

  componentDidMount() {
    const { focusedGroup } = this.props;
    if (focusedGroup && this[focusedGroup]) {
      setTimeout(() => {
        if (this[focusedGroup].current) {
          Scroll.scroller.scrollTo(this[focusedGroup].current, { offset: -200, duration: 1000, align: 'top' });
          this.props.clearFocusedGroup();
        }
      });
    }
  }

  toggleDrawer = (open) => () => {
    this.setState({ isDrawerOpen: open });
  };

  render() {
    Log.trace('RENDER', 'AuthorizationIntakeForm');
    const { classes, values, ...rest } = this.props;
    const intakeRecord = this.props.intakeRecords[values.id];

    return (
      <>
        <Grid container classes={{ container: classes.container }}>
          <Grid item xs={12} classes={{ item: classes.section }}>
            <StepLabel ref={this.authorizationInformation} active>1</StepLabel>
            <AuthorizationInformationForm
              authorizationInformation={values.authorizationInformation}
              primaryInformation={values.primaryInformation}
              departureProperty={values.departureProperty}
              intakeRecord={intakeRecord}
              {...rest}
            />
          </Grid>
          <Grid item xs={12} classes={{ item: classes.section }}>
            <StepLabel ref={this.familyInformation}>2</StepLabel>
            <FamilyInformationForm familyInformation={values.familyInformation} eeIntakeForm={intakeRecord.eeIntakeForm} moveTypeId={values.authorizationInformation.moveTypeId} {...rest} />
          </Grid>
          <Grid item xs={12} classes={{ item: classes.section }}>
            <StepLabel ref={this.departureProperty}>3</StepLabel>
            <DeparturePropertyForm departureProperty={values.departureProperty} eeIntakeForm={intakeRecord.eeIntakeForm} moveTypeId={values.authorizationInformation.moveTypeId} {...rest} departureAddressComment={intakeRecord.departureAddressComment}/>
          </Grid>
          { (!values.authorizationInformation.moveTypeId && values.authorizationInformation.moveTypeId !== 0) &&
            <>
              <Typography className={classes.selectMoveTypeText} color="error">Please select a move type to initiate any services.</Typography>
            </>}
          <Grid item xs={12} classes={{ item: classes.section }}>
            <StepLabel ref={this.hhgInformation}>4</StepLabel>
            <HhgInformationForm hhgInformation={values.hhgInformation} moveTypeId={values.authorizationInformation.moveTypeId} {...rest} />
          </Grid>
          <Grid item xs={12} classes={{ item: classes.section }}>
            <StepLabel ref={this.temporaryLiving}>5</StepLabel>
            <TemporaryLivingForm
              temporaryLiving={values.temporaryLiving}
              moveTypeId={values.authorizationInformation.moveTypeId}
              contacts={values.familyInformation.contacts}
              pets={values.familyInformation.pets}
              {...rest}
            />
          </Grid>
          <Grid item xs={12} classes={{ item: classes.section }}>
            <StepLabel ref={this.newDestination}>6</StepLabel>
            <NewDestinationForm 
              newDestination={values.newDestination} 
              eeIntakeForm={intakeRecord.eeIntakeForm} 
              moveTypeId={values.authorizationInformation.moveTypeId}
              isHhgInitiated={values.hhgInformation.isHhgServiceInitiated} 
              isTempLivingInitiated={values.temporaryLiving.isTemporaryLivingServiceInitiated} 
              destinationInformationComment={intakeRecord.destinationInformationComment}
              {...rest} 
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

AuthorizationIntakeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  focusedGroup: PropTypes.string,
  clearFocusedGroup: PropTypes.func.isRequired,
  selfServicePolicySummaries: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    intakeRecords,
  } = state.intake;

  return {
    intakeRecords,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {}),
)(AuthorizationIntakeForm);
