import { Button, Grid, Typography, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { TextField } from 'modules/intake/form';

import { defaultEndDate } from 'utilities/common';
import ColorIndicator from './colorIndicator.component';
import Colors from './colors';

const styles = () => ({

});

class FinalMoveForm extends PureComponent {
  render() {
    const { finalMove, isFormEnabled, onEnableService, handleBlur, handleChange, setFieldValue, rootPath } = this.props;

    return (
      <div className="form-group">
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography gutterBottom variant="h3" className={isFormEnabled ? '':  'disabled'}>Final Move</Typography>
          </Grid>
          <Grid item md={6}>
            <Button variant="outlined"
              color="secondary"
              onClick={onEnableService}
            >{
                isFormEnabled ? 'Disable Service' : 'Enable Service'
              }</Button>
          </Grid>
        </Grid>
        {
          isFormEnabled &&
            <>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.finalMove.transfereeTravelStartDate`}
                  label="Transferee Travel Start Date"
                  value={finalMove.transfereeTravelStartDate}
                  onChange={(e) => {
                    defaultEndDate(e.target.value, finalMove.transfereeTravelEndDate, `${rootPath}.finalMove.transfereeTravelEndDate`, setFieldValue);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  type="date"
                />
                <Field
                  component={TextField}
                  name={`${rootPath}.finalMove.transfereeTravelEndDate`}
                  label="Transferee Travel End Date"
                  value={finalMove.transfereeTravelEndDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.finalMove} />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.finalMove.familyTravelStartDate`}
                  label="Family Travel Start Date"
                  value={finalMove.familyTravelStartDate}
                  onChange={(e) => {
                    defaultEndDate(e.target.value, finalMove.familyTravelEndDate, `${rootPath}.finalMove.familyTravelEndDate`, setFieldValue);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  type="date"
                />
                <Field
                  component={TextField}
                  name={`${rootPath}.finalMove.familyTravelEndDate`}
                  label="Family Travel End Date"
                  value={finalMove.familyTravelEndDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.finalMove} />
              </div>
            </>
        }
      </div>
    );
  }
}

FinalMoveForm.propTypes = {
  classes: PropTypes.object.isRequired,
  finalMove: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  onEnableService: PropTypes.func.isRequired,
  isFormEnabled: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
)(FinalMoveForm);
