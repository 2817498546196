import React, { Component } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import auth from 'auth/auth';

class Callback extends Component {
  componentDidMount() {
    auth.handleAuthentication();
  }

  render() {
    return <FullscreenSpinner />;
  }
}

export default Callback;
