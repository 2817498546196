import { object, string } from 'yup';

import { calculateDuration, formatDateForInput, formatForWire } from 'utilities/common';

import { plusMinusFiveYearsDateSchema } from 'modules/common/constants';

export default class HouseHuntingBenefits {
  static schema = object().shape({
    travelLength: string(),
    travelStartDate: plusMinusFiveYearsDateSchema,
    travelEndDate: plusMinusFiveYearsDateSchema,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.travelStartDate = formatDateForInput(json.travelStartDate);
    this.travelEndDate = formatDateForInput(json.travelEndDate);
    this.travelLength = calculateDuration(this.travelStartDate, this.travelEndDate) || '';
  }

  static fromWireData(wireData) {
    return new HouseHuntingBenefits(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      travelStartDate: this.travelStartDate,
      travelEndDate: this.travelEndDate,
      travelLength: this.travelLength,
    });
  }
}
