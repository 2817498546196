import { Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

import InputContext from 'modules/intake/form/inputContext';
import styles from 'modules/intake/form/toggleStyles';

class ToggleButton extends PureComponent {
  static contextType = InputContext;

  handleChange = () => {
    const { value } = this.props;
    const { onBlur, onFocus, onChange } = this.context;

    // TODO only sets dirty flag on every other click, need to debug further
    onFocus();
    this.props.onChange(this.props.field.name);
    this.props.form.setFieldValue(
      this.props.field.name,
      !value,
    );
    this.props.onBlur(this.props.field.name);
    this.props.form.setFieldTouched(this.props.field.name, true);
    onChange();
    onBlur();
  };

  render() {
    const { classes, disabled, value, label, form } = this.props;
    return (
      <Button
        disabled={disabled === undefined ? form.isSubmitting : disabled}
        classes={{
          root: classNames(classes.toggleButton, { [classes.selectedRoot]: value }),
          label: classNames({ [classes.selectedLabel]: value }),
        }}
        onClick={this.handleChange}
        variant="outlined"
      >
        {label}
      </Button>
    );
  }
}

ToggleButton.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(ToggleButton);
