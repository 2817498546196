import { Chip, FormGroup, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDate } from 'utilities/common';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  textField: {
    marginRight: theme.spacing(2),
  },
  comment: {
    whiteSpace: 'pre-line',
  },
}));

function AuthorizationComment(props) {
  const classes = useStyles();

  const { comment } = props;
  const serviceType = comment.serviceType ? comment.serviceType.description: '';
  const regardingType = comment.regardingType ? comment.regardingType.description: '';

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" gutterBottom>{formatDate(comment.created)}</Typography>
      <FormGroup row>
        <div className="row align-center mb-2">
          <TextField
            className={classes.textField}
            label="Service Type"
            value={serviceType}
            inputProps={{ readOnly: true }}
          />
          <TextField
            className={classes.textField}
            label="Regarding Type"
            value={regardingType}
            inputProps={{ readOnly: true }}
          />
          {comment.isWebViewable &&
            <Chip color="primary" label="Web Viewable" />
          }
        </div>
      </FormGroup>
      <Typography className={classes.comment} gutterBottom>{comment.comment}</Typography>
    </Paper>
  );
}

AuthorizationComment.propTypes = {
  comment: PropTypes.object.isRequired,
};

export default AuthorizationComment;
