import { Button, Paper, Typography, makeStyles, withStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import React from 'react';

import { ERROR, WARNING } from './errorLevels';

const ErrorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    borderColor: theme.palette.error.main,
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  label: {
    ...theme.typography.button,
  },
  ctaContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

function ValidationAlertCard(props) {
  const classes = useStyles();
  const { level, groupLabel, errors, onClick } = props;

  function renderLabel() {
    return level === ERROR ?
      <Typography className={classes.label} color="error">Alert</Typography> :
      <Typography className={classes.label}>Warning</Typography>;
  }

  function renderButton() {
    return level === ERROR ?
      <ErrorButton variant="outlined" size="large" onClick={onClick}>Update</ErrorButton> :
      <Button size="large" onClick={onClick}>Update</Button>;
  }

  return (
    <Paper className={classes.container}>
      <div className={classes.ctaContainer}>
        {renderLabel()}
        {renderButton()}
      </div>
      <Typography variant="h6" gutterBottom>{groupLabel}</Typography>
      {errors.map((error, i) => (
        <Typography key={i} gutterBottom>{error.errorMessage}</Typography>
      ))}
    </Paper>
  );
}

ValidationAlertCard.propTypes = {
  level: PropTypes.oneOf([ERROR, WARNING]),
  groupLabel: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ValidationAlertCard;
