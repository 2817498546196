import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Location } from 'modules/intake/models';
import GooglePlacesAutocomplete from 'modules/intake/form/googlePlacesAutocomplete.component';

import ErrorMessage from 'modules/intake/form/errorMessage.component';
import InputContext from 'modules/intake/form/inputContext';

// TODO the text field does not display as errored if there is an error
export default class CityAutocompleteTextField extends PureComponent {
  static contextType = InputContext;

  render() {
    const { onBlur, onFocus, onChange } = this.context;
    const { className, form, disabled, field, label } = this.props;
    return (
      <div className={className}>
        <GooglePlacesAutocomplete
          initialValue={field.value ? field.value.formattedAddress: ''}
          label={label}
          onSelect={(event, value) => {
            form.setFieldValue(field.name, new Location(value));
            this.props.onChange(event);
            onChange(event);
          }}
          onInputEdit={onChange}
          onInputClear={(event) => {
            form.setFieldValue(field.name, new Location());
            this.props.onChange(field.name);
            onChange(field.name);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={form.isSubmitting || disabled}
        />
        <ErrorMessage name={field.name} />
      </div>
    );
  }
}

CityAutocompleteTextField.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
