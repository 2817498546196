import {
  Box,
  IconButton,
  Typography,
  withStyles, withTheme,
} from '@material-ui/core';

import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Grid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';

import { formatAddress, formatDate } from 'utilities/common';
import { getDelimitedValue } from 'utilities/currencyFormatter';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  const recordBorder = `1px solid ${theme.palette.grey[500]}`;
  return {
    table: {
      borderSpacing: theme.spacing(0, 2),
    },
    tableHead: {
      height: 32,
    },
    cell: {
      borderTop: recordBorder,
      borderBottom: recordBorder,
      borderLeft: 0,
      borderRight: 0,
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderLeft: recordBorder,
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderRight: recordBorder,
      },
    },
    row: {
      backgroundColor: theme.palette.common.white,
    },
    noDataCell: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
      textAlign: 'center',
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  };
};

const FILTER_DISABLED_COLUMNS = ['organization'];

class SelfServiceSummaryGrid extends PureComponent {
  static defaultProps = {
    isLoading: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'authorizationId', title: 'Auth ID' },
        { name: 'name', title: 'Name', getCellValue: this.getNameCellValue },
        { name: 'authorizationDate', title: 'Auth Date', getCellValue: this.formatDate('authorizationDate') },
        { name: 'newJobStartDate', title: 'Start Date', getCellValue: this.formatDate('newJobStartDate') },
        { name: 'departure', title: 'Departure', getCellValue: this.getDepartureAddress },
        { name: 'destination', title: 'Destination', getCellValue: this.getDestinationAddress },
        { name: 'pointsRemaining', title: '', getCellValue: this.getPointsRemainingCellValue },
        { name: 'referralsNeedSending', title: '', getCellValue: this.getReferralsNeedSendingValue },
        { name: 'addNote', title: '', getCellValue: this.getAddNoteCellValue },
      ],
      filters: [],
      tableColumnExtensions: [
        { columnName: 'name', width: 300, wordWrapEnabled: true },
        { columnName: 'addNote', width: 65 },
      ],
      integratedFilteringColumnExtensions: [
        { columnName: 'name', predicate: this.keyPredicate },
      ],
    };
  }

  keyPredicate = (value, filter) => {
    const valueLower = (value.key || '').toLowerCase();
    const filterLower = (filter.value || '').toLowerCase();
    return valueLower.indexOf(filterLower) > -1;
  };

  formatDate = (key) => (record) => {
    return formatDate(record[key]);
  };

  getAddNoteCellValue = (record) => {
    const { onAddNote, theme: { palette } } = this.props;
    return (
      <IconButton onClick={() => onAddNote(record)}>
        <FontAwesomeIcon color={palette.primary.main} icon={['far', 'sticky-note']} size="lg" />
      </IconButton>
    );
  };

  getNameCellValue = (record) => {
    const name = `${record.firstName} ${record.lastName}`;
    return (
      <Fragment key={name}>
        <Typography>{name}</Typography>
        <Typography>{record.client.name}</Typography>
        <Box fontStyle="italic"><Typography>{record.mostRecentNote && record.mostRecentNote.note}</Typography></Box>
      </Fragment>
    );
  };

  getDepartureAddress = ({ departureCity, departureStateCode, departureCountryCode }) => {
    return formatAddress({
      city: departureCity,
      state: departureStateCode,
      country: departureCountryCode,
    });
  };

  getDestinationAddress = ({ destinationCity, destinationStateCode, destinationCountryCode }) => {
    return formatAddress({
      city: destinationCity,
      state: destinationStateCode,
      country: destinationCountryCode,
    });
  };

  getPointsRemainingCellValue = (record) => {
    return `${getDelimitedValue(record.pointsRemaining, 0, 3)} remaining`;
  };

  getReferralsNeedSendingValue = (record) => {
    if (record.referralsNeedSending > 0) {
      return `${record.referralsNeedSending} ${record.referralsNeedSending === 1 ? ' referral needs sending' : 'referrals need sending'}`;
    }
    return '';
  };

  changeFilters = (filters) => this.setState({ filters });

  filterCell = (props) => {
    return props.column.title && !FILTER_DISABLED_COLUMNS.includes(props.column.name) ?
      <TableFilterRow.Cell {...props} /> : null;
  };

  headerCell = (props) => {
    return props.column.title ? <TableHeaderRow.Cell {...props} /> : null;
  };

  headerRowComponent = (props) => {
    return <TableHeaderRow.Row className={this.props.classes.tableHead} {...props} />;
  };

  rowComponent = (props) => {
    return <Table.Row className={this.props.classes.row} {...props} />;
  };

  cellComponent = (props) => {
    let clickHandler = null;
    let pointerClass = null;
    let boldClass = null;
    if (!['addNote'].includes(props.column.name)) {
      clickHandler = () => this.props.onSelect(props.row);
      pointerClass = 'handles-click';
    }
    if (['referralsNeedSending'].includes(props.column.name)) {
      boldClass = this.props.classes.bold;
    }
    const className = classNames(this.props.classes.cell, pointerClass, boldClass);
    return <Table.Cell onClick={clickHandler} className={className} {...props} />;
  };

  tableComponent = (props) => {
    return <Table.Table className={this.props.classes.table} {...props} />;
  };

  noDataCellComponent = (props) => {
    const content = this.props.isLoading ? <Spinner logo /> : 'No Records were returned.  Check the filters above.';
    // need to remove the getMessage function to avoid exception
    // eslint-disable-next-line no-unused-vars
    const { getMessage, ...rest } = props;
    return (
      <Table.Cell {...rest} className={this.props.classes.noDataCell}>
        <Typography>{content}</Typography>
      </Table.Cell>
    );
  };

  buildSummaryObjects = (authPolicies) => {
    return authPolicies.map((a) => {
      return {
        ...a.authorizationSummary,
        pointsRemaining : a.policy.budget - a.policy.totalAmountSpent,
        mostRecentNote: a.policy.mostRecentNote,
        referralsNeedSending: a.policy.benefits.flatMap((ben) => ben.options).filter((opt) => opt.isConfirmed && !opt.sentToVendor).length,
      };
    });
  }

  render() {
    const { authPolicies } = this.props;
    const summaries = this.buildSummaryObjects(authPolicies);
    const {
      columns,
      filters,
      tableColumnExtensions,
      integratedFilteringColumnExtensions,
    } = this.state;

    return (
      <Grid
        rows={summaries}
        columns={columns}
      >
        <FilteringState
          filters={filters}
          onFiltersChange={this.changeFilters}
        />
        <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
        <Table
          columnExtensions={tableColumnExtensions}
          rowComponent={this.rowComponent}
          tableComponent={this.tableComponent}
          cellComponent={this.cellComponent}
          noDataCellComponent={this.noDataCellComponent}
        />
        <TableHeaderRow rowComponent={this.headerRowComponent} cellComponent={this.headerCell} />
        <TableFilterRow cellComponent={this.filterCell} />
      </Grid>
    );
  }
}

SelfServiceSummaryGrid.propTypes = {
  authPolicies: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onAddNote: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme,
)(SelfServiceSummaryGrid);
