import { FormControl, FormControlLabel, FormLabel, RadioGroup as MuiRadioGroup, Radio, withStyles } from '@material-ui/core';
import { fieldToRadioGroup } from 'formik-material-ui';
import { values } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ErrorMessage from 'modules/intake/form/errorMessage.component';
import InputContext from 'modules/intake/form/inputContext';

const styles = (theme) => ({
  radioGroupRoot: {
    flexDirection: 'row',
  },
});

class RadioGroup extends PureComponent {
  static contextType = InputContext;

  render() {
    const { onBlur, onFocus, onChange } = this.context;
    const { classes, options, label, field: { name }, form: { isSubmitting }, disabled } = this.props;
    const useOptions = Array.isArray(options) ? options: values(options);

    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <MuiRadioGroup
          {...fieldToRadioGroup(this.props)}
          classes={{ root: classes.radioGroupRoot }}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(e) => {
            this.props.onChange(e);
            onChange();

            this.props.onBlur(e);
            onBlur();
          }}
        >
          {useOptions.map((option) => (
            <FormControlLabel
              key={option.id}
              value={String(option.id)}
              control={<Radio />}
              label={option.description}
              disabled={option.disabled || isSubmitting || disabled}
            />
          ))}
        </MuiRadioGroup>
        <ErrorMessage name={name} />
      </FormControl>
    );
  }
}

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  form: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(RadioGroup);
