import { CALL_INTAKE_API } from 'middleware/intakeApi';

import {
  GET_USER_PROFILE_FAILURE, GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS,
} from './types';

export const getUserProfile = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAILURE],
      authenticated: true,
      endpoint: 'users/me',
      method: 'GET',
    },
  };
};
