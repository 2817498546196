import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  quantityText: {
    padding: theme.spacing(0.5),
    color: theme.palette.grey['700'],
    backgroundColor: theme.palette.grey['200'],
    maxWidth: 100,
  },
}));

const Quantity = (props) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} direction="row" alignItems="center" wrap="nowrap">
      <Grid item>
        <Typography variant="caption" className={classes.quantityDescriptionText}>
          {props.quantityDescription || 'Quantity'}
        </Typography>
      </Grid>
      <Grid item xs={2} alignContent="flex-end">
        <Typography variant="caption" className={classes.quantityText}>
          {props.quantity}
        </Typography>
      </Grid>
    </Grid>
  );
};

Quantity.propTypes = {
  quantity: PropTypes.number.isRequired,
  quantityDescription: PropTypes.string,
};

export default Quantity;
