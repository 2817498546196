import { LinearProgress, withStyles } from '@material-ui/core';

const ProgressBar = withStyles((theme) => ({
  root: {
    height: 30,
    borderRadius: 15,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
  },
  bar: (props) => ({
    borderRadius: 15,
    backgroundColor: props.barColor ? props.barColor : theme.palette.primary.main,
  }),
}))(LinearProgress);

export default ProgressBar;
