import { DateTimeFormatter, ZonedDateTime } from 'js-joda';
import { Divider, Typography, makeStyles, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Locale } from '@js-joda/locale_en-us';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => (
  {
    spacingX: {
      marginRight: theme.spacing(1),
    },
    root: {
      height: 15,
    },
  }
));

const DATE_FORMATTER = DateTimeFormatter.ofPattern('dd MMMM YYYY').withLocale(Locale.US);


const BenefitMetaData = (props) => {
  const { summary } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <div className={classNames('row-no-basis', 'align-center', 'mx-2', classes.root)}>
      <FontAwesomeIcon
        icon={['fas', 'circle']}
        color={summary.mostRecentPublish ? theme.palette.secondary.main : theme.palette.warning.main}
        className={classes.spacingX}
      />
      <Typography color="textSecondary" variant="subtitle1" className={classes.spacingX}>
        {summary.mostRecentPublish ? 'Published' : 'Unpublished'}
      </Typography>
      {summary.mostRecentPublish && (
        <Typography color="textSecondary" variant="subtitle2" className={classes.spacingX}>
          {ZonedDateTime.parse(summary.mostRecentPublish.publishedDate).format(DATE_FORMATTER)}
        </Typography>
      )}
      {summary.draft && summary.mostRecentPublish && (
        <>
          <Divider orientation="vertical" className={classes.spacingX} />
          <Typography color="textSecondary" variant="subtitle1">
            Active Draft
          </Typography>
        </>
      )}
    </div>
  );
};

BenefitMetaData.propTypes = {
  summary: PropTypes.object.isRequired,
};

export default BenefitMetaData;
