import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

function NumberFormat(props) {
  const { inputRef, prefix, suffix, allowDecimal, includeThousandsSeparator, ...rest } = props;

  return (
    <MaskedInput
      {...rest}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={createNumberMask({
        allowDecimal,
        includeThousandsSeparator,
        prefix,
        suffix,
      })}
    />
  );
}

NumberFormat.defaultProps = {
  prefix: '',
  suffix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
};

NumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  allowDecimal: PropTypes.bool,
  includeThousandsSeparator: PropTypes.bool,
};

export default NumberFormat;
