import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import logoPath from 'assets/logospinner.gif';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 110,
  },
}));

const Spinner = ({ size, color, theme, logo }) => {
  const classes = useStyles();

  if (logo) {
    return (
      <img className={classes.logo} src={logoPath} alt="Spinner" />
    );
  }

  return (
    <FontAwesomeIcon
      icon={['fas', 'spinner-third']}
      spin
      size={size || '4x'}
      color={color || theme.palette.primary.main}
    />
  );
};

Spinner.propTypes = {
  theme: PropTypes.object.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  logo: PropTypes.bool,
};

export default withTheme(Spinner);
