import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt } from 'react-router-dom';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';

import { COST_TYPES } from './constants';
import {
  CREATE_BENEFIT_FAILURE,
  CREATE_DRAFT_FAILURE,
  DELETE_BENEFIT_DRAFT_FAILURE,
  DELETE_BENEFIT_FAILURE,
  GET_BENEFIT_FAILURE,
  GET_COST_LOOKUP_APIS_FAILURE,
  GET_LOOKUP_TABLES_FAILURE,
  PUBLISH_BENEFIT_FAILURE,
  UPDATE_BENEFIT_FAILURE,
} from './benefit.types';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { areAllQuestionsEqual, getVersionFromSummaryById, useRefCallback } from './util';
import {
  createBenefit,
  createDraft,
  deleteBenefit,
  deleteBenefitDraft,
  getBenefit,
  getCostLookupApis,
  getCostLookupByTable,
  getLookupTables,
  publishBenefit,
  updateBenefit,
} from './benefit.actions';
import { isLoadingSelector } from './benefit.selectors';
import { setPageTitle, showToast } from 'modules/layout/layout.actions';
import { usePrevious } from 'modules/common/usePrevious';
import AddPhoto from './addPhoto.component';
import BenefitConflictDialog, {
  CONFLICT_BENEFIT_NOT_IN_USE_MESSAGE,
  CONFLICT_CHANGED_DRAFT_MESSAGE,
  CONFLICT_CHANGED_PUBLISH_MESSAGE,
  CONFLICT_DRAFT_DELETED_MESSAGE,
  CONFLICT_DRAFT_DELETE_PUBLISHED_MESSAGE,
  CONFLICT_DRAFT_EXISTS_MESSAGE,
  CONFLICT_PUBLISHED_DRAFT_MESSAGE,
} from './benefitConflictDialog.component';
import BenefitHistoryDialog from './benefitHistoryDialog.component';
import BenefitMetaData from './benefitMetaData.component';
import BenefitOptions from './benefitOptions.component';
import BenefitQuestions from './benefitQuestions.component';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import Dialog from 'modules/common/dialog.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import Menu from 'modules/common/menu.component';
import PositiveIntegerFormat from '../common/positiveIntegerFormat.component';
import TRCInputValidator from './form/TRCInputValidator';
import VendorQuestions from './vendorQuestions.component';

const costTypes = Object.values(COST_TYPES);

const useStyles = makeStyles((theme) => ({
  formRoot: {
    flex: 3,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  buttonLast: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  placeholderContent: {
    height: 300,
    width: 300,
  },
  addPhotoRoot: {
    paddingTop: theme.spacing(3),
  },
  policiesAffectedWarning: {
    color: theme.palette.warning.dark,
  },
  tooltipIcon: {
    position: 'absolute',
    top: 32,
    right: -22,
  },
}));

const BenefitForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    history,
    isLoading,
    lookupTables,
    costLookupApis,
    match,
    showToast: propsShowToast,
    setPageTitle: propsSetPageTitle,
    getBenefit: propsGetBenefit,
    getCostLookupByTable: propsGetCostLookupByTable,
  } = props;
  const [openBenefitHistoryDialog, setOpenBenefitHistoryDialog] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [onCostTypeChangeVersion, setOnCostTypeChangeVersion] = useState(null);
  const [showDeleteDraftConfirmation, setShowDeleteDraftConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [conflictHandlingDialog, setConflictHandlingDialog] = useState({
    isOpen: false,
    message: '',
  });
  const [enableOnPublish, setEnableOnPublish] = useState(false);
  const [benefit, setBenefit] = useState(null);
  const [version, setVersion] = useState({
    name: '',
    secondaryText: '',
    description: '',
    costType: '',
    costLookupKey: '',
    optionDetails: [],
    questionDetails: [],
    vendorQuestionDetails: [],
    imageId: '',
    publishNotes: '',
    canSelectMultipleOfAnOption: false,
    canSelectMultipleOptions: false,
  });
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasAttemptedPublish, setHasAttemptedPublish] = useState(false);
  const prevHasAttemptedPublish = usePrevious(hasAttemptedPublish);
  const [formRef, setFormRef] = useRefCallback();
  const disableConstantFields = !isReadOnly && benefit && !isEmpty(benefit.mostRecentPublish);

  const apiCostLookupOptionFields = useMemo(
    () => ((costLookupApis.find((api) => api.id === version.costLookupKey) || { optionFields: [] }).optionFields),
    [costLookupApis, version.costLookupKey],
  );
  const apiCostLookupAuthorizationFields = useMemo(
    () => ((costLookupApis.find((api) => api.id === version.costLookupKey) || { authorizationFields: [] }).authorizationFields),
    [costLookupApis, version.costLookupKey],
  );

  const tableCostLookupOptionFields = useMemo(
    () => ((lookupTables.find((table) => table.id === version.costLookupKey) || { optionFields: [] }).optionFields),
    [lookupTables, version.costLookupKey],
  );

  const tableCostLookupAuthorizationFields = useMemo(
    () => ((lookupTables.find((table) => table.id === version.costLookupKey) || { authorizationFields: [] }).authorizationFields),
    [lookupTables, version.costLookupKey],
  );

  const shouldShowPoliciesWarning = useCallback(() => {
    if (!benefit || !version || !benefit.hasPolicies) {
      return false;
    }
    const originalQuestions = benefit.mostRecentPublish.questionDetails;
    return !areAllQuestionsEqual(originalQuestions, version.questionDetails);
  }, [benefit, version]);

  const saveDraft = async () => {
    setIsSubmitting(true);
    let action;
    if (version.versionId) {
      action = await props.updateBenefit(version);
    } else {
      action = await props.createBenefit(version, { publish: false, enable: false });
    }
    setIsSubmitting(false);
    if (action.type === CREATE_BENEFIT_FAILURE || action.type === UPDATE_BENEFIT_FAILURE) {
      if (action.isCollision) {
        const isPublishedError = action.messages[0].indexOf('published') >= 0;
        const isDeletedError = action.messages[0].indexOf('deleted') >= 0;
        if (isPublishedError) {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_PUBLISHED_DRAFT_MESSAGE,
          });
        } else if (isDeletedError) {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_DRAFT_DELETED_MESSAGE,
          });
        } else {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_CHANGED_PUBLISH_MESSAGE,
          });
        }
      } else {
        propsShowToast('Failed to save benefit. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      setVersion((v) => {
        Object.assign(v, action.response);
        return v;
      });
      setIsDirty(false);
      propsShowToast('Benefit saved.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }
  };

  const attemptPublish = () => {
    setHasAttemptedPublish(true);
    let errorMessage;
    if (!isFormValid) {
      errorMessage = 'Please fix errors before publishing';
    } else if (version.optionDetails.length === 0) {
      errorMessage = 'Benefit must have at least one option.';
    } else if (!version.imageId) {
      errorMessage = 'Benefit must have an image.';
    } else if (!version.optionDetails.every((opt) => opt.imageId)) {
      errorMessage = 'All options must have an image.';
    }
    if (errorMessage) {
      propsShowToast(errorMessage, { severity: TOAST_MESSAGE_SEVERITY_ERROR, userMustDismiss: false });
      return;
    }
    setOpenPublishDialog(true);
  };

  const publish = async () => {
    setIsSubmitting(true);
    let action;
    if (version.versionId) {
      action = await props.publishBenefit(version, enableOnPublish);
    } else {
      action = await props.createBenefit(version, { publish: true, enable: enableOnPublish });
    }
    if (action.type === PUBLISH_BENEFIT_FAILURE || action.type === CREATE_BENEFIT_FAILURE) {
      setIsSubmitting(false);
      if (action.isCollision) {
        const isPublishedError = action.messages[0].indexOf('published') >= 0;
        const isDeletedError = action.messages[0].indexOf('deleted') >= 0;
        if (isPublishedError) {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_PUBLISHED_DRAFT_MESSAGE,
          });
        } else if (isDeletedError) {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_DRAFT_DELETED_MESSAGE,
          });
        } else {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_CHANGED_DRAFT_MESSAGE,
          });
        }
      } else {
        propsShowToast('Failed to publish benefit. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      setIsDirty(false);
      history.push('/benefits');
      propsShowToast('Benefit published.', { userMustDismiss: false, severity: 'success' });
    }
  };

  const startDraft = async () => {
    setIsSubmitting(true);
    const action = await props.createDraft(benefit.id);
    if (action.type === CREATE_DRAFT_FAILURE) {
      setIsSubmitting(false);
      if (action.isCollision) {
        setConflictHandlingDialog({
          isOpen: true,
          message: CONFLICT_DRAFT_EXISTS_MESSAGE,
        });
      } else {
        propsShowToast('Failed to create a new draft. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      setIsSubmitting(false);
      history.push(`/benefits/${benefit.id}/v/${action.response.versionId}`);
      window.location.reload();
    }
  };

  const editDraft = () => {
    history.push(`/benefits/${benefit.id}/v/${benefit.draft.versionId}`);
    window.location.reload();
  };

  const deleteDraft = async () => {
    const handleSuccess = () => {
      history.push('/benefits');
      propsShowToast('Benefit Draft deleted.', { userMustDismiss: false, severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    };
    setIsSubmitting(true);
    setShowDeleteDraftConfirmation(false);
    const response = await props.deleteBenefitDraft(version);
    if (response.type === DELETE_BENEFIT_DRAFT_FAILURE) {
      setIsSubmitting(false);
      if (response.isCollision) {
        const isPublishedError = response.messages[0].indexOf('published') >= 0;
        if (isPublishedError) {
          setConflictHandlingDialog({
            isOpen: true,
            message: CONFLICT_DRAFT_DELETE_PUBLISHED_MESSAGE,
          });
        } else {
          // treat this like a success. the draft has already been deleted
          handleSuccess();
        }
      } else {
        props.showToast('Failed to delete benefit draft. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    } else {
      handleSuccess();
    }
  };

  const deleteBen = async () => {
    setIsSubmitting(true);
    setShowDeleteConfirmation(false);
    const response = await props.deleteBenefit(benefit.id);
    if (response.type === DELETE_BENEFIT_FAILURE) {
      setIsSubmitting(false);
      props.showToast('Failed to delete benefit. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      setIsDirty(false);
      history.push('/benefits');
      props.showToast('Benefit deleted.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }
  };

  const renderButtons = () => {
    const buttons = [];
    if (version.isPublished) {
      buttons.push(
        <Box key="delete" color="error.main" width={125}>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
            disabled={isSubmitting}
          >
            Delete
          </Button>
        </Box>,
      );
      if (!benefit.draft) {
        buttons.push(
          <Button
            size="large"
            key="createDraft"
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={startDraft}
            disabled={isSubmitting}
          >
            Create Draft
          </Button>,
        );
      } else {
        buttons.push(
          <Button
            size="large"
            key="editDraft"
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={editDraft}
            disabled={isSubmitting}
          >
            Edit Draft
          </Button>,
        );
      }
    } else {
      buttons.push(
        <Menu
          key="publish"
          label="Publish"
          classes={{ button: classes.button }}
          disabled={isSubmitting || !version.name || (hasAttemptedPublish && !isFormValid)}
          items={[
            {
              label: 'Publish',
              action: () => {
                setEnableOnPublish(false);
                attemptPublish();
              },
            },
            {
              label: 'Publish & Enable',
              action: () => {
                setEnableOnPublish(true);
                attemptPublish();
              },
            },
          ]}
        />,
      );
      buttons.push(
        <Button
          size="large"
          key="saveDraft"
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={saveDraft}
          disabled={isSubmitting || !version.name}
        >
          Save Draft
        </Button>,
      );
      if (version.versionId) {
        buttons.push(
          <Box key="deleteDraft" color="error.main">
            <Button
              size="large"
              className={classes.button}
              color="inherit"
              variant="outlined"
              onClick={() => {
                setShowDeleteDraftConfirmation(true);
              }}
              disabled={isSubmitting}
            >
              Delete Draft
            </Button>
          </Box>,
        );
      }
    }
    return buttons;
  };

  const canAddOption = () => {
    if (!version.costType) {
      return false;
    }
    if (version.costType === COST_TYPES.TABLE_LOOKUP || version.costType === COST_TYPES.API_CALC) {
      return !!version.costLookupKey;
    }
    return true;
  };

  const checkFormValidity = useCallback(() => {
    if (!formRef || !version) {
      return;
    }
    // the one render after they click publish, dryRun should be false to show the validation errors
    const dryRun = !hasAttemptedPublish || hasAttemptedPublish === prevHasAttemptedPublish;
    // wait a tick to allow form fields to populate on page load
    setTimeout(async () => {
      const formValid = await formRef.isFormValid(dryRun);
      setIsFormValid(formValid);
    });
  }, [version, formRef, hasAttemptedPublish, prevHasAttemptedPublish]);

  useEffect(() => {
    if (!isEmpty(benefit)) {
      setIsDirty(version !== getVersionFromSummaryById(benefit, version.versionId));
    }
  }, [version, benefit]);

  useEffect(() => {
    (async () => {
      if (isEmpty(lookupTables)) {
        const action = await props.getLookupTables();
        if (action.type === GET_LOOKUP_TABLES_FAILURE) {
          props.showToast('Failed to load lookup tables. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }
      if (isEmpty(costLookupApis)) {
        const action = await props.getCostLookupApis();
        if (action.type === GET_COST_LOOKUP_APIS_FAILURE) {
          props.showToast('Failed to load cost lookup apis. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    (async () => {
      const isEdit = !!match.params.id;
      if (isEdit) {
        const action = await propsGetBenefit(match.params.id);
        if (action.type === GET_BENEFIT_FAILURE) {
          propsShowToast('Failed to load benefit. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
        const ben = action.response;
        const v = getVersionFromSummaryById(ben, match.params.versionId);
        if (!v) {
          if (ben.mostRecentPublish) {
            history.push(`/benefits/${ben.id}/v/${ben.mostRecentPublish.versionId}`);
          } else {
            setConflictHandlingDialog({
              isOpen: true,
              message: CONFLICT_BENEFIT_NOT_IN_USE_MESSAGE,
            });
            setTimeout(() => {
              history.push('/benefits');
            }, 5000);
          }
          return;
        } else {
          setBenefit(ben);
          setVersion(v);
          setIsReadOnly(v.isPublished);
        }
      }
      propsSetPageTitle('Master Benefit');
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    checkFormValidity();
  }, [checkFormValidity]);

  useEffect(() => {
    if (version.costLookupKey && version.costType === COST_TYPES.TABLE_LOOKUP) {
      propsGetCostLookupByTable(version.costLookupKey);
    }
  }, [version.costLookupKey, version.costType, propsGetCostLookupByTable]);

  if (isLoading) {
    return <FullscreenSpinner />;
  }

  return (
    <>
      <ValidatorForm ref={setFormRef} onSubmit={() => {}} instantValidate={false}>
        <div className="column">
          <Box my={2} mx={2}>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                {!isEmpty(benefit) && (
                  <Grid container spacing={0} alignItems="center">
                    <Grid item>
                      <BenefitMetaData summary={benefit} />
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          setOpenBenefitHistoryDialog(true);
                        }}
                      >
                        <FontAwesomeIcon icon={['fas', 'history']} size="1x" />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={7}>
                <Grid container justify="flex-end" spacing={1}>
                  {renderButtons()}
                  <Grid item>
                    <Button color="primary" onClick={() => history.push('/benefits')}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box m={1}>
            <Paper className="row-no-basis">
              <Grid container>
                <Grid item xs={12}>
                  <div className="row p2">
                    <AddPhoto
                      classes={{ cardRoot: classes.addPhotoRoot }}
                      imageId={version.imageId}
                      setImageId={(imageId) => setVersion({ ...version, imageId })}
                      setImageUrl={(imageUrl) => setVersion((v) => {v.imageUrl = imageUrl; return v;})}
                      isReadOnly={isReadOnly}
                    />
                    <div className={classNames('p2', classes.formRoot)}>
                      <div className="row">
                        <div className="column mr">
                          <TRCInputValidator
                            component={TextValidator}
                            InputProps={{ readOnly: isReadOnly }}
                            label="Benefit Title"
                            onChange={(value) => setVersion({ ...version, name: value })}
                            name="benefitName"
                            value={version.name}
                            validateOnBlur
                            validateOnChange
                            required
                            fullWidth
                          />
                          <TRCInputValidator
                            component={TextValidator}
                            InputProps={{ readOnly: isReadOnly }}
                            label="Secondary Text"
                            onChange={(value) => setVersion({ ...version, secondaryText: value })}
                            name="benefitSecondaryText"
                            value={version.secondaryText}
                            required
                            validateOnBlur={hasAttemptedPublish}
                            validateOnChange={hasAttemptedPublish}
                            fullWidth
                          />
                        </div>
                        <div className="column relative">
                          <TRCInputValidator
                            component={SelectValidator}
                            InputProps={{ readOnly: isReadOnly }}
                            disabled={disableConstantFields}
                            name="benefitCostType"
                            label="Cost Type"
                            value={version.costType}
                            onChange={(value) => {
                              if (value !== version.costType) {
                                const newVersion = { ...version, costType: value, costLookupKey: '' };
                                if (version.costType) {
                                  setOnCostTypeChangeVersion(newVersion);
                                } else {
                                  setVersion(newVersion);
                                }
                              }
                            }}
                            required
                            validateOnBlur={hasAttemptedPublish}
                            validateOnChange={hasAttemptedPublish}
                            fullWidth
                          >
                            {costTypes.map((costType) => (
                              <MenuItem key={costType} value={costType}>
                                {costType}
                              </MenuItem>
                            ))}
                          </TRCInputValidator>
                          {
                            disableConstantFields &&
                              <Tooltip arrow title="Cost type fields cannot be changed after a benefit has been published.">
                                <div className={classes.tooltipIcon}>
                                  <FontAwesomeIcon color={theme.palette.primary.light} icon={['fas', 'question-circle']} />
                                </div>
                              </Tooltip>
                          }
                          <TRCInputValidator
                            component={SelectValidator}
                            InputProps={{ readOnly: isReadOnly }}
                            disabled={disableConstantFields}
                            name="benefitCostLookupKey"
                            label={version.costType === COST_TYPES.TABLE_LOOKUP ? 'Table Name' : 'API Name'}
                            value={version.costLookupKey}
                            onChange={(value) => {
                              if (value !== version.costLookupKey) {
                                const newVersion = { ...version, costLookupKey: value };
                                if (version.costLookupKey) {
                                  setOnCostTypeChangeVersion(newVersion);
                                } else {
                                  setVersion(newVersion);
                                }
                              }
                            }}
                            style={{ visibility: version.costType === COST_TYPES.TABLE_LOOKUP || version.costType === COST_TYPES.API_CALC ? 'visible' : 'hidden' }}
                            required={version.costType === COST_TYPES.TABLE_LOOKUP || version.costType === COST_TYPES.API_CALC}
                            fullWidth
                          >
                            {version.costType === COST_TYPES.TABLE_LOOKUP && lookupTables.map((lookupTable) => (
                              <MenuItem key={lookupTable.id} value={lookupTable.id}>
                                {lookupTable.name}
                              </MenuItem>
                            ))}
                            {version.costType === COST_TYPES.API_CALC && (costLookupApis).map((api) => (
                              <MenuItem key={api.id} value={api.id}>
                                {api.name}
                              </MenuItem>
                            ))}
                          </TRCInputValidator>
                        </div>
                      </div>
                      <TRCInputValidator
                        component={TextValidator}
                        InputProps={{ readOnly: isReadOnly }}
                        label="Description"
                        onChange={(value) => setVersion({ ...version, description: value })}
                        name="benefitDescription"
                        value={version.description}
                        required
                        validateOnBlur={hasAttemptedPublish}
                        validateOnChange={hasAttemptedPublish}
                        fullWidth
                        multiline
                        rows={3}
                      />
                      {
                        version.costType === COST_TYPES.API_CALC && version.costLookupKey &&
                          <div className="row">
                            <div className="column mr">
                              <TRCInputValidator
                                component={TextValidator}
                                InputProps={{ readOnly: isReadOnly, inputComponent: PositiveIntegerFormat }}
                                label="Min Cost"
                                onChange={(value) => setVersion({ ...version, minCost: value })}
                                name="benefitMinCost"
                                value={version.minCost}
                                validateOnBlur={hasAttemptedPublish}
                                validateOnChange={hasAttemptedPublish}
                                fullWidth
                              />
                            </div>
                            <div className="column relative">
                              <TRCInputValidator
                                component={TextValidator}
                                InputProps={{ readOnly: isReadOnly, inputComponent: PositiveIntegerFormat }}
                                label="Max Cost"
                                onChange={(value) => setVersion({ ...version, maxCost: value })}
                                name="benefitMaxCost"
                                value={version.maxCost}
                                validateOnBlur={hasAttemptedPublish}
                                validateOnChange={hasAttemptedPublish}
                                fullWidth
                              />
                              <Tooltip arrow title={
                                <Typography>Estimate the min and max cost of the benefit. This will help determine what policies it should be offered under.</Typography>
                              }
                              >
                                <div className={classes.tooltipIcon}>
                                  <FontAwesomeIcon color={theme.palette.primary.light} icon={['fas', 'question-circle']} />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <BenefitOptions
                    version={version}
                    isReadOnly={isReadOnly}
                    hasAttemptedPublish={hasAttemptedPublish}
                    tableCostLookupOptionFields={tableCostLookupOptionFields}
                    tableCostLookupAuthorizationFields={tableCostLookupAuthorizationFields}
                    apiCostLookupOptionFields={apiCostLookupOptionFields}
                    apiCostLookupAuthorizationFields={apiCostLookupAuthorizationFields}
                    canAddOption={canAddOption()}
                    setVersion={setVersion}
                    classes={{ placeholderContent: classes.placeholderContent }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box m={1}>
            <Paper className="mb column">
              <BenefitQuestions
                version={version}
                setVersion={setVersion}
                isReadOnly={isReadOnly}
                classes={{ placeholderContent: classes.placeholderContent }}
              />
            </Paper>
          </Box>
          <Box m={1}>
            <Paper className="mb column">
              <VendorQuestions
                version={version}
                setVersion={setVersion}
                isReadOnly={isReadOnly}
              />
            </Paper>
          </Box>
        </div>
      </ValidatorForm>
      {benefit && (
        <BenefitHistoryDialog
          open={openBenefitHistoryDialog}
          onClose={() => {
            setOpenBenefitHistoryDialog(false);
          }}
          benefitId={benefit.id}
        />
      )}
      <Dialog
        open={openPublishDialog}
        title="Publish Benefit"
        content={
          <>
            {shouldShowPoliciesWarning() &&
              <Grid container spacing={0}>
                <Grid item xs={1} container alignItems="center">
                  <FontAwesomeIcon color={theme.palette.warning.dark} icon={['fas', 'exclamation-circle']} size="2x" />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="subtitle1" className={classes.policiesAffectedWarning}>
                    There are policies that offer this benefit. In order for your changes to take effect, those policies will
                    need to be updated.
                  </Typography>
                </Grid>
              </Grid>
            }
            <ValidatorForm onSubmit={() => {}}>
              <TRCInputValidator
                component={TextValidator}
                label="Notes"
                onChange={(value) => setVersion({ ...version, publishNotes: value })}
                name="benefitPublishNotes"
                value={version.publishNotes}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
              />
            </ValidatorForm>
          </>
        }
        actions={
          <div className="row-no-grow justify-end my">
            <Button size="large" onClick={() => setOpenPublishDialog(false)}>
              Cancel
            </Button>
            <Button className={classes.button} size="large" variant="contained" color="primary" disabled={isSubmitting} onClick={publish}>
              Publish
            </Button>
          </div>
        }
      />
      <Prompt
        when={isDirty}
        message={({ pathname }) => {
          return pathname === props.location.pathname || 'You have unsaved changes, are you sure you want to leave?';
        }}
      />
      <ConfirmationDialog
        onConfirm={deleteDraft}
        onCancel={() => {
          setShowDeleteDraftConfirmation(false);
        }}
        open={showDeleteDraftConfirmation}
        title="Delete Draft?"
        confirmLabel="Delete"
      >
        <Typography>Are you sure you'd like to delete this draft?</Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        onConfirm={deleteBen}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        open={showDeleteConfirmation}
        title="Delete Benefit?"
        confirmLabel="Delete"
      >
        <Typography>Are you sure you'd like to delete this benefit?</Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        onConfirm={() => {
          setVersion({ ...onCostTypeChangeVersion, optionDetails: [] });
          setOnCostTypeChangeVersion(null);
        }}
        onCancel={() => {
          setOnCostTypeChangeVersion(null);
        }}
        open={!!onCostTypeChangeVersion}
        title="Change Cost Type Field?"
        confirmLabel="Confirm"
      >
        <Typography>Changing a cost type field will result in deleting the options for this benefit. Continue?</Typography>
      </ConfirmationDialog>
      <BenefitConflictDialog
        onConfirm={() => {
          setConflictHandlingDialog({
            isOpen: false,
            message: '',
          });

        }}
        open={conflictHandlingDialog.isOpen}
        message={conflictHandlingDialog.message}
      />
    </>
  );
};

BenefitForm.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match,
  lookupTables: PropTypes.array,
  costLookupApis: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  showToast: PropTypes.func.isRequired,
  createDraft: PropTypes.func.isRequired,
  createBenefit: PropTypes.func.isRequired,
  getBenefit: PropTypes.func.isRequired,
  updateBenefit: PropTypes.func.isRequired,
  deleteBenefit: PropTypes.func.isRequired,
  deleteBenefitDraft: PropTypes.func.isRequired,
  publishBenefit: PropTypes.func.isRequired,
  benefits: PropTypes.array,
  setPageTitle: PropTypes.func.isRequired,
  getLookupTables: PropTypes.func.isRequired,
  getCostLookupByTable: PropTypes.func.isRequired,
  getCostLookupApis: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    benefits: state.benefit.benefitSummaries,
    lookupTables: state.benefit.lookupTables,
    costLookupApis: state.benefit.costLookupApis,
    isLoading: isLoadingSelector(state),
  };
};

export default
connect(mapStateToProps, {
  createBenefit,
  createDraft,
  updateBenefit,
  deleteBenefitDraft,
  deleteBenefit,
  publishBenefit,
  getBenefit,
  showToast,
  setPageTitle,
  getLookupTables,
  getCostLookupApis,
  getCostLookupByTable,
})(BenefitForm);
