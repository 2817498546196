import { Fab, Grid, IconButton, Link, Paper, Typography, makeStyles, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import { HEADER_HEIGHT_SHRINK } from 'modules/intake/constants';
import { formatDistance, getMapUrl } from 'utilities/common';
import { getDelimitedValue } from 'utilities/currencyFormatter';
import { getDistance } from 'utilities/googleMapsClient';
import { getPolicyName } from '../policyUtil';
import SelectionsProgressBar from './selectionsProgressBar.component';
import auth from 'auth/auth';

const useStyles = makeStyles((theme) => (
  {
    container: {
      height: 'auto',
      minHeight: HEADER_HEIGHT_SHRINK,
      padding: theme.spacing(2),
      zIndex: theme.zIndex.appBar,
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
      },
    },
    drawerOpen: {
      width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
    },
    addPointsButton: {
      position: 'absolute',
      top: 10,
      left: 50,
      color: theme.palette.primary.main,
    },
  }
));


const SelfServiceDetailsHeader = (props) => {

  const {
    authPolicyDetails,
    shouldShowSideDrawer,
    onAddNote,
    onAddPoints,
  } = props;
  const { departureLocation, destinationLocation } = authPolicyDetails.authorizationSummary;

  const classes = useStyles();
  const theme = useTheme();

  const [relocationDistance, setRelocationDistance] = useState(0);

  const recalculateDistance = useCallback(async () => {
    const result = await getDistance(departureLocation, destinationLocation);
    setRelocationDistance(result.value);
  }, [departureLocation, destinationLocation]);

  useEffect(() => {
    recalculateDistance();
  }, [recalculateDistance]);

  return (
    <Paper square className={classNames('position-fixed', classes.container, { [classes.drawerOpen]: shouldShowSideDrawer })}>
      <Grid container alignItems="center">
        <Grid item container spacing={4} xs={11}>
          <Grid item xs={2}>
            <Grid container direction="column">
              <Typography variant="caption" color="textSecondary" gutterBottom>EE First Name</Typography>
              <Typography variant="subtitle1">{authPolicyDetails.authorizationSummary.firstName}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column">
              <Typography variant="caption" color="textSecondary" gutterBottom>EE Last Name</Typography>
              <Typography variant="subtitle1">{authPolicyDetails.authorizationSummary.lastName}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column">
              <Typography variant="caption" color="textSecondary" gutterBottom>Departure Location</Typography>
              <Typography variant="subtitle1">{departureLocation.formattedCityState}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column">
              <Typography variant="caption" color="textSecondary" gutterBottom>Destination Location</Typography>
              <Typography variant="subtitle1">{destinationLocation.formattedCityState}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption" gutterBottom style={{ visibility: 'hidden' }}>Spacing</Typography>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>Distance: {formatDistance(relocationDistance)}</Typography>
              </Grid>
              <Grid item>
                <Link rel="noopener" target="blank" href={getMapUrl(authPolicyDetails.authorizationSummary.departureLocation, authPolicyDetails.authorizationSummary.destinationLocation)}>Map It!</Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column">
              <Typography variant="caption" color="textSecondary" gutterBottom>Program Type</Typography>
              <Typography variant="subtitle1">{authPolicyDetails.authorizationSummary.reloPolicy}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column">
              <Typography variant="caption" color="textSecondary" gutterBottom>Self Service Policy</Typography>
              <Typography variant="subtitle1">{getPolicyName(authPolicyDetails)}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column" className="relative">
              <Typography variant="caption" color="textSecondary" gutterBottom>Budget Amount</Typography>
              <Typography variant="subtitle1">{getDelimitedValue(authPolicyDetails.policy.budget, 0, 3)}</Typography>
              {
                auth.userHasPermission('selfService:write:budget') &&
                  <IconButton size="medium" className={classes.addPointsButton} onClick={onAddPoints}>
                    <FontAwesomeIcon icon={['far', 'plus-circle']} />
                  </IconButton>
              }
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <SelectionsProgressBar policy={authPolicyDetails.policy} />
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Fab color="primary" onClick={onAddNote}>
            <div className="column align-center">
              <FontAwesomeIcon icon={['far', 'plus']} color={theme.palette.primary.contrastText} />
              Notes
            </div>
          </Fab>
        </Grid>
      </Grid>
    </Paper>
  );
};

SelfServiceDetailsHeader.propTypes = {
  authPolicyDetails: PropTypes.object.isRequired,
  shouldShowSideDrawer: PropTypes.bool.isRequired,
  onAddNote: PropTypes.func.isRequired,
  onAddPoints: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    shouldShowSideDrawer: state.layout.shouldShowSideDrawer,
  };
};

export default connect(
  mapStateToProps,
  {  },
)(SelfServiceDetailsHeader);
