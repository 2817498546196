export const COLOR_PRIMARY = {
  contrastText: '#fff',
  dark: '#339097',
  light: '#4CD6E0',
  main: '#41B7C0',
};

export const COLOR_SECONDARY = {
  contrastText: '#fff',
  dark: '#416A54',
  light: '#74BE96',
  main: '#5D9878',
};

export const COLOR_WARNING = {
  contrastText: '#fff',
  dark: '#efa719',
  light: '#efa719',
  main: '#efa719',
};

export const COLOR_ERROR = {
  contrastText: '#fff',
  dark: '#A00239',
  light: '#F00256',
  main: '#C20245',
};

export const DRAWER_WIDTH_OPEN = 240;
export const DRAWER_WIDTH_CLOSED = 73;
export const DRAWER_WIDTH_CLOSED_XS = 57;
