import {
  GET_BENEFITS_FAILURE,
  GET_BENEFITS_REQUEST,
  GET_BENEFITS_SUCCESS,
  GET_BENEFIT_FAILURE,
  GET_BENEFIT_REQUEST,
  GET_BENEFIT_SUCCESS,
  GET_COST_LOOKUP_APIS_FAILURE,
  GET_COST_LOOKUP_APIS_REQUEST,
  GET_COST_LOOKUP_APIS_SUCCESS,
  GET_COST_LOOKUP_FAILURE,
  GET_COST_LOOKUP_REQUEST,
  GET_COST_LOOKUP_SUCCESS,
  GET_LOOKUP_TABLES_FAILURE,
  GET_LOOKUP_TABLES_REQUEST,
  GET_LOOKUP_TABLES_SUCCESS,
} from './benefit.types';
import produce from 'immer';

const initialState = {
  isLoadingBenefits: false,
  isLoadingBenefit: false,
  isLoadingLookupTables: false,
  isLoadingCostLookupApis: false,
  isLoadingCostLookup: false,
  benefitSummaries: null,
  lookupTables: [],
  costLookupApis: [],
  costLookupByTable: {},
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_BENEFITS_REQUEST:
      draft.isLoadingBenefits = true;
      break;

    case GET_BENEFITS_SUCCESS:
      draft.isLoadingBenefits = false;
      draft.benefitSummaries = action.response;
      break;

    case GET_BENEFITS_FAILURE:
      draft.isLoadingBenefits = false;
      break;

    case GET_BENEFIT_REQUEST:
      draft.isLoadingBenefits = true;
      break;

    case GET_BENEFIT_SUCCESS:
      draft.isLoadingBenefits = false;
      break;

    case GET_BENEFIT_FAILURE:
      draft.isLoadingBenefits = false;
      break;

    case GET_LOOKUP_TABLES_REQUEST:
      draft.isLoadingLookupTables = true;
      break;

    case GET_LOOKUP_TABLES_SUCCESS:
      draft.isLoadingLookupTables = false;
      draft.lookupTables = action.response;
      break;

    case GET_LOOKUP_TABLES_FAILURE:
      draft.isLoadingLookupTables = false;
      break;

    case GET_COST_LOOKUP_APIS_REQUEST:
      draft.isLoadingCostLookupApis = true;
      break;

    case GET_COST_LOOKUP_APIS_SUCCESS:
      draft.isLoadingCostLookupApis = false;
      draft.costLookupApis = action.response;
      break;

    case GET_COST_LOOKUP_APIS_FAILURE:
      draft.isLoadingCostLookupApis = false;
      break;

    case GET_COST_LOOKUP_REQUEST:
      draft.isLoadingCostLookup = true;
      break;

    case GET_COST_LOOKUP_SUCCESS:
      draft.isLoadingCostLookup = false;
      draft.costLookupByTable[action.context.tableName] = action.response;
      break;

    case GET_COST_LOOKUP_FAILURE:
      draft.isLoadingCostLookup = false;
      break;

    default:
      break;
  }

  return draft;
}, initialState);

