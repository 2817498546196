import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Dialog from 'modules/common/dialog.component';

function PasswordResetDialog(props) {
  const {
    onSubmit,
    onCancel,
    disabled,
    error,
    email,
    ...rest
  } = props;

  return (
    <Dialog
      {...rest}
      title="Reset Password"
      content={
        <>
          <Typography>Please confirm password reset. Instructions will be emailed to you at {email}.</Typography>
          {error && <Typography color="error">{error}</Typography>}
        </>
      }
      actions={
        <>
          <Button disabled={disabled} onClick={onCancel} variant="outlined" color="primary">Cancel</Button>
          <Button disabled={disabled} onClick={onSubmit} variant="contained" color="primary">Reset</Button>
        </>
      }
    />
  );
}

PasswordResetDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  email: PropTypes.string.isRequired,
};

export default PasswordResetDialog;
