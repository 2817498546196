import produce from 'immer';

import Location from 'modules/intake/models/location';

import {
  ADD_AUTH_POLICY_BUDGET_ADDITION_SUCCESS,
  ADD_AUTH_POLICY_NOTE_SUCCESS,
  BenefitStatusEnum,
  GET_AUTH_POLICIES_FAILURE,
  GET_AUTH_POLICIES_REQUEST,
  GET_AUTH_POLICIES_SUCCESS,
  GET_AUTH_POLICY_DETAILS_FAILURE,
  GET_AUTH_POLICY_DETAILS_REQUEST,
  GET_AUTH_POLICY_DETAILS_SUCCESS,
  TOGGLE_SENT_TO_VENDOR_FAILURE,
  TOGGLE_SENT_TO_VENDOR_REQUEST,
} from './types';

const initialState = {
  authPolicies: [],
  authPolicyDetails: null,
  clientList:[],
  isLoading : false,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_AUTH_POLICIES_REQUEST:
    case GET_AUTH_POLICY_DETAILS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_AUTH_POLICIES_FAILURE:
    case GET_AUTH_POLICY_DETAILS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_AUTH_POLICIES_SUCCESS: {
      draft.authPolicies = action.response.map((r) => fromWire(r));
      draft.clientList = getClientList(draft.authPolicies);
      draft.isLoading = false;
      break;
    }

    case GET_AUTH_POLICY_DETAILS_SUCCESS: {
      draft.authPolicyDetails =  fromWire(action.response);
      draft.isLoading = false;
      break;
    }

    case ADD_AUTH_POLICY_NOTE_SUCCESS: {
      if (draft.authPolicies && draft.authPolicies.length) {
        const targetAuthPol = draft.authPolicies.find((authPol) => authPol.authorizationSummary.authorizationId === action.response.authId);
        targetAuthPol.policy.mostRecentNote = getMostRecentNote(action.response);
      }
      if (draft.authPolicyDetails) {
        draft.authPolicyDetails.policy.notes = action.response.notes;
      }
      break;
    }

    case ADD_AUTH_POLICY_BUDGET_ADDITION_SUCCESS: {
      const policy = draft.authPolicyDetails.policy;
      policy.budgetAdditions = action.response.budgetAdditions;
      policy.budget = policy.budgetAdditions.reduce((budget, addition) => budget + addition.addition, policy.originalBudget);
      break;
    }

    case TOGGLE_SENT_TO_VENDOR_REQUEST:
    case TOGGLE_SENT_TO_VENDOR_FAILURE: {
      const selections = draft.authPolicyDetails.authSelections;
      const targetOption = selections.selectedOptions.find((selectedOption) => selectedOption.id === action.context.optionId);
      targetOption.sentToVendor = !targetOption.sentToVendor;
      addSelectionsToPolicy(draft.authPolicyDetails.policy, selections);
      break;
    }

    default:
      break;
  }

  return draft;
}, initialState);

const fromWire = (authPolicyDetails) => {
  const policy = addSelectionsToPolicy(authPolicyDetails.policy, authPolicyDetails.authSelections);
  policy.originalBudget = policy.budget;
  policy.budget = policy.budgetAdditions.reduce((budget, addition) => budget + addition.addition, policy.budget);
  const authorizationSummary = {
    ...authPolicyDetails.authorizationSummary,
    departureLocation : new Location({
      city: authPolicyDetails.authorizationSummary.departureCity,
      state: authPolicyDetails.authorizationSummary.departureStateCode,
      country: authPolicyDetails.authorizationSummary.departureCountryCode,
    }),
    destinationLocation : new Location({
      city: authPolicyDetails.authorizationSummary.destinationCity,
      state: authPolicyDetails.authorizationSummary.destinationStateCode,
      country: authPolicyDetails.authorizationSummary.destinationCountryCode,
    }),
  };
  return {
    policy : { ...policy, mostRecentNote: getMostRecentNote(policy) },
    authorizationSummary,
    authSelections: authPolicyDetails.authSelections,
  };
};

const getMostRecentNote = (policy) => {
  const sortedNotes = policy.notes ? policy.notes.sort((a, b) => new Date(b.created) - new Date(a.created)) : [];
  return sortedNotes.length ? sortedNotes[0] : null;
};

const addSelectionsToPolicy = (policy, selections) => {
  policy.totalAmountSpent = 0;
  policy.confirmedAmountSpent = 0;
  const selectedBenefits = selections ? (selections.selectedBenefits || []) : [];
  const selectedOptions = selections ? (selections.selectedOptions || []) : [];
  policy.benefits = policy.benefits.map((benefit) => {
    const selectedBenefit = selectedBenefits.find((b) => b.name === benefit.name);
    benefit.isComplete = selectedBenefit ? selectedBenefit.isComplete : false;
    benefit.options = benefit.options.filter((o) => o.cost != null).map((o) => {
      const selectedOption = selectedOptions.find((so) => so.optionId === o.id);
      o.selectedOptionId = selectedOption ? selectedOption.id : null;
      o.selected = !!selectedOption;
      o.quantity = selectedOption ? selectedOption.quantity : 0;
      o.cost = selectedOption && selectedOption.cost ? selectedOption.cost : o.cost;
      o.isConfirmed = selectedOption ? selectedOption.isConfirmed : false;
      o.confirmedDate = selectedOption && selectedOption.confirmedDate;
      o.sentToVendor = selectedOption ? selectedOption.sentToVendor : false;
      o.benefitName = benefit.name;
      const spent = selectedOption ? o.cost : 0;
      policy.totalAmountSpent += spent;
      if (o.isConfirmed) {
        policy.confirmedAmountSpent += spent;
      }
      return o;
    });
    benefit.status = calculateBenefitStatus(benefit, selections, selectedBenefit);
    
    if (selections && selections.selectedAnswers) {
      const questionMap = {};
      for (const opt of benefit.options) {
        for (const rule of opt.rules) {
          for (const question of rule.questions) {
            const selectedAnswer = selections.selectedAnswers.find((ans) => ans.questionDetailId === question.benefitQuestionDetail.id);
            if (selectedAnswer) {
              questionMap[question.benefitQuestionDetail.id] = { questionDetail: question.benefitQuestionDetail, selectedAnswer };
            }
          }
        }
      }
      benefit.questions = Object.values(questionMap);
      benefit.questions.sort((q1, q2) => q1.selectedAnswer.order - q2.selectedAnswer.order);
    }
    return benefit;
  });
  return policy;
};

const calculateBenefitStatus = (benefit, selections, selectedBenefit) => {
  if (benefit.options.length === 0) {
    return BenefitStatusEnum.NO_AVAILABLE_OPTIONS;
  }
  if (!selections || !selectedBenefit) {
    return BenefitStatusEnum.NONE;
  }
  const selectedOptionsForThisBenefit = (selections.selectedOptions || []).filter((selectedOpt) => selectedOpt.benefitName === benefit.name);
  if (selectedOptionsForThisBenefit.some((selectedOpt) => selectedOpt.costCalculationInProgress)) {
    return BenefitStatusEnum.IN_PROGRESS;
  }
  const allOptionsConfirmed = benefit.options.every((benefitOption) => {
    const selectedOption = selectedOptionsForThisBenefit.find((selectedOpt) => selectedOpt.optionId === benefitOption.id);
    return selectedOption && selectedOption.isConfirmed;
  });
  if (allOptionsConfirmed) {
    return BenefitStatusEnum.ALL_OPTIONS_CONFIRMED;
  }
  if (selectedBenefit.isComplete) {
    const hasSelectedOptions = selectedOptionsForThisBenefit.length;
    if (hasSelectedOptions) {
      const hasPendingSelectedOption = selectedOptionsForThisBenefit.some((selectedOption) => !selectedOption.isConfirmed);
      if (hasPendingSelectedOption) {
        return BenefitStatusEnum.HAS_PENDING_SELECTED_OPTIONS;
      } else if (benefit.canSelectMultipleOptions) {
        return BenefitStatusEnum.ALL_SELECTED_OPTIONS_CONFIRMED;
      } else {
        return BenefitStatusEnum.ALL_OPTIONS_CONFIRMED;
      }
    } else {
      return BenefitStatusEnum.SKIPPED;
    }
  } else {
    return BenefitStatusEnum.IN_PROGRESS;
  }
};

const getClientList = (authPolicyDetails) => {
  if (!authPolicyDetails) {
    return [];
  }
  let clients = authPolicyDetails.map((apd) => apd.authorizationSummary.client);
  clients = clients.filter((client, index, self) => {
    return self.findIndex((f) => f.id === client.id) === index;
  });
  return clients.sort((a, b) => String(a.name).localeCompare(b.name));
};
