import { object } from 'yup';

import { formatDateForInput, formatForWire } from 'utilities/common';

import { NULLABLE_BOOLEAN, plusMinusFiveYearsDateSchema } from 'modules/common/constants';

export default class SpousalAssistanceBenefits {
  static schema = object().shape({
    isEligible: NULLABLE_BOOLEAN,
    estimatedDate: plusMinusFiveYearsDateSchema,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.isEligible = json.isEligible;
    this.estimatedDate = formatDateForInput(json.estimatedDate);
  }

  static fromWireData(wireData) {
    return new SpousalAssistanceBenefits(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      isEligible: this.isEligible,
      estimatedDate: this.estimatedDate,
    });
  }
}
