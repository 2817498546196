export const GET_BENEFITS_REQUEST = 'GET_BENEFITS_REQUEST';
export const GET_BENEFITS_SUCCESS = 'GET_BENEFITS_SUCCESS';
export const GET_BENEFITS_FAILURE = 'GET_BENEFITS_FAILURE';

export const GET_BENEFIT_REQUEST = 'GET_BENEFIT_REQUEST';
export const GET_BENEFIT_SUCCESS = 'GET_BENEFIT_SUCCESS';
export const GET_BENEFIT_FAILURE = 'GET_BENEFIT_FAILURE';

export const CREATE_BENEFIT_REQUEST = 'CREATE_BENEFIT_REQUEST';
export const CREATE_BENEFIT_SUCCESS = 'CREATE_BENEFIT_SUCCESS';
export const CREATE_BENEFIT_FAILURE = 'CREATE_BENEFIT_FAILURE';

export const CREATE_DRAFT_REQUEST = 'CREATE_DRAFT_REQUEST';
export const CREATE_DRAFT_SUCCESS = 'CREATE_DRAFT_SUCCESS';
export const CREATE_DRAFT_FAILURE = 'CREATE_DRAFT_FAILURE';

export const UPDATE_MASTER_BENEFIT_REQUEST = 'UPDATE_MASTER_BENEFIT_REQUEST';
export const UPDATE_MASTER_BENEFIT_SUCCESS = 'UPDATE_MASTER_BENEFIT_SUCCESS';
export const UPDATE_MASTER_BENEFIT_FAILURE = 'UPDATE_MASTER_BENEFIT_FAILURE';

export const UPDATE_BENEFIT_REQUEST = 'UPDATE_BENEFIT_REQUEST';
export const UPDATE_BENEFIT_SUCCESS = 'UPDATE_BENEFIT_SUCCESS';
export const UPDATE_BENEFIT_FAILURE = 'UPDATE_BENEFIT_FAILURE';

export const PUBLISH_BENEFIT_REQUEST = 'PUBLISH_BENEFIT_REQUEST';
export const PUBLISH_BENEFIT_SUCCESS = 'PUBLISH_BENEFIT_SUCCESS';
export const PUBLISH_BENEFIT_FAILURE = 'PUBLISH_BENEFIT_FAILURE';

export const GET_LOOKUP_TABLES_REQUEST = 'GET_LOOKUP_TABLES_REQUEST';
export const GET_LOOKUP_TABLES_SUCCESS = 'GET_LOOKUP_TABLES_SUCCESS';
export const GET_LOOKUP_TABLES_FAILURE = 'GET_LOOKUP_TABLES_FAILURE';

export const GET_COST_LOOKUP_APIS_REQUEST = 'GET_COST_LOOKUP_APIS_REQUEST';
export const GET_COST_LOOKUP_APIS_SUCCESS = 'GET_COST_LOOKUP_APIS_SUCCESS';
export const GET_COST_LOOKUP_APIS_FAILURE = 'GET_COST_LOOKUP_APIS_FAILURE';

export const GET_COST_LOOKUP_REQUEST = 'GET_COST_LOOKUP_REQUEST';
export const GET_COST_LOOKUP_SUCCESS = 'GET_COST_LOOKUP_SUCCESS';
export const GET_COST_LOOKUP_FAILURE = 'GET_COST_LOOKUP_FAILURE';

export const GET_BENEFIT_HISTORY_REQUEST = 'GET_BENEFIT_HISTORY_REQUEST';
export const GET_BENEFIT_HISTORY_SUCCESS = 'GET_BENEFIT_HISTORY_SUCCESS';
export const GET_BENEFIT_HISTORY_FAILURE = 'GET_BENEFIT_HISTORY_FAILURE';

export const DELETE_BENEFIT_DRAFT_REQUEST = 'DELETE_BENEFIT_DRAFT_REQUEST';
export const DELETE_BENEFIT_DRAFT_SUCCESS = 'DELETE_BENEFIT_DRAFT_SUCCESS';
export const DELETE_BENEFIT_DRAFT_FAILURE = 'DELETE_BENEFIT_DRAFT_FAILURE';

export const DELETE_BENEFIT_REQUEST = 'DELETE_BENEFIT_REQUEST';
export const DELETE_BENEFIT_SUCCESS = 'DELETE_BENEFIT_SUCCESS';
export const DELETE_BENEFIT_FAILURE = 'DELETE_BENEFIT_FAILURE';