import { boolean, object, string } from 'yup';

import { calculateDuration, formatDateForInput, formatForWire } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, plusMinusFiveYearsDateSchema } from 'modules/common/constants';

import { convertInPendingView, grabCountryCode } from '../details/authorizationIntake/utils';

export default class HomesaleProcessBenefits {
  static schema = object().shape({
    didExplainSteps: boolean(),
    didExplainPartnership: boolean(),
    didIntroduceAgents: boolean(),
    sendAgentInfoDate: plusMinusFiveYearsDateSchema,
    preferredAgentName: string().max(300, MAX_LENGTH_ERROR_MESSAGE),
    preferredAgency: string().max(300, MAX_LENGTH_ERROR_MESSAGE),
    preferredAgencyEmail: string().max(100, MAX_LENGTH_ERROR_MESSAGE),
    preferredAgencyPhone: string().max(50, MAX_LENGTH_ERROR_MESSAGE),
    preferredBmaAppointmentDate: plusMinusFiveYearsDateSchema,
    preferredListingDate: plusMinusFiveYearsDateSchema,
    listingLength: string(),
    inspectionStartDate: plusMinusFiveYearsDateSchema,
    appraisalStartDate: plusMinusFiveYearsDateSchema,
    approximateAcquisitionDate: plusMinusFiveYearsDateSchema,
    didAskForOccupants: boolean(),
    occupantsDuringListing: string().max(500, MAX_LENGTH_ERROR_MESSAGE),
    didSetEquityExpectations: boolean(),
    didIncludeSpecificBenefits: boolean(),
    listingStartDate: plusMinusFiveYearsDateSchema,
    listingEndDate: plusMinusFiveYearsDateSchema,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.didExplainSteps = !!json.didExplainSteps;
    this.didExplainPartnership = !!json.didExplainPartnership;
    this.didIntroduceAgents = !!json.didIntroduceAgents;
    this.sendAgentInfoDate = formatDateForInput(json.sendAgentInfoDate);
    this.preferredAgentName = json.preferredAgentName || '';
    this.preferredAgency = json.preferredAgency || '';
    this.preferredAgencyEmail = json.preferredAgencyEmail || '';
    this.preferredAgencyPhone = json.preferredAgencyPhone ? convertInPendingView(json.preferredAgencyPhone) : '';
    this.preferredAgencyPhoneCountry = json.preferredAgencyPhone ? grabCountryCode(json.preferredAgencyPhone) : 'USA';
    this.preferredBmaAppointmentDate = formatDateForInput(json.preferredBmaAppointmentDate);
    this.listingStartDate = formatDateForInput(json.listingStartDate);
    this.listingEndDate = formatDateForInput(json.listingEndDate);
    this.listingLength = calculateDuration(this.listingStartDate, this.listingEndDate) || '';
    this.inspectionStartDate = formatDateForInput(json.inspectionStartDate);
    this.appraisalStartDate = formatDateForInput(json.appraisalStartDate);
    this.approximateAcquisitionDate = formatDateForInput(json.approximateAcquisitionDate);
    this.didAskForOccupants = !!json.didAskForOccupants;
    this.occupantsDuringListing = json.occupantsDuringListing || '';
    this.didSetEquityExpectations = !!json.didSetEquityExpectations;
    this.didIncludeSpecificBenefits = !!json.didIncludeSpecificBenefits;
  }

  static fromWireData(wireData) {
    return new HomesaleProcessBenefits(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      didExplainSteps: this.didExplainSteps,
      didExplainPartnership: this.didExplainPartnership,
      didIntroduceAgents: this.didIntroduceAgents,
      sendAgentInfoDate: this.sendAgentInfoDate,
      preferredAgentName: this.preferredAgentName,
      preferredAgency: this.preferredAgency,
      preferredAgencyEmail: this.preferredAgencyEmail,
      preferredAgencyPhone: this.preferredAgencyPhone,
      preferredBmaAppointmentDate: this.preferredBmaAppointmentDate,
      listingStartDate: this.listingStartDate,
      listingEndDate: this.listingEndDate,
      listingLength: this.listingLength,
      inspectionStartDate: this.inspectionStartDate,
      appraisalStartDate: this.appraisalStartDate,
      approximateAcquisitionDate: this.approximateAcquisitionDate,
      didAskForOccupants: this.didAskForOccupants,
      occupantsDuringListing: this.occupantsDuringListing,
      didSetEquityExpectations: this.didSetEquityExpectations,
      didIncludeSpecificBenefits: this.didIncludeSpecificBenefits,
    });
  }
}
