import { number, object, string } from 'yup';

import { formatForWire } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, NOTE_MAX_LENGTH, REQUIRED_STRING } from 'modules/common/constants';

export default class Note {
  static schema = object().shape({
    id: number(),
    note: REQUIRED_STRING.max(NOTE_MAX_LENGTH, MAX_LENGTH_ERROR_MESSAGE),
    created: string(),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.id = json.id;
    this.note = json.note;
    this.created = json.created;
  }

  static fromWireData(wireData) {
    return new Note(wireData);
  }

  toWireData() {
    return formatForWire({
      id: this.id,
      note: this.note,
      created: this.created,
    });
  }
}
