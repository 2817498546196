import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/es6/map';
import 'core-js/es6/promise';
import 'core-js/es6/set';

import 'js-joda-timezone';

import * as Sentry from '@sentry/browser';
import { Router } from 'react-router-dom';
import { render } from 'react-dom';
import React from 'react';

import './index.scss';
import 'firebase/storage';
import * as firebase from 'firebase/app';
import Root from './root.container';
import Script from 'react-load-script';
import buildIconLibrary from './utilities/iconLoader';
import configureStore from './store/configureStore';
import history from './browserHistory';

const basename = process.env.PUBLIC_URL || '/';
const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyAbyqOp7xI2sFOhCD9J4QXnxF5wC4H-VDg';
const store = configureStore();
buildIconLibrary();

const environment = process.env.REACT_APP_ENVIRONMENT_NAME || 'LOCAL';
const sentryKey = process.env.REACT_APP_SENTRY_KEY || 'https://2020c91b29a2443bba83883e48d82241@sentry.io/1880241';
if (environment !== 'LOCAL') {
  Sentry.init({ dsn: sentryKey });
  Sentry.setTag('environment', environment);
}

const PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
const firebaseConfig = {
  projectId: PROJECT_ID,
  storageBucket: `${PROJECT_ID}.appspot.com`,
  apiKey: process.env.REACT_APP_FB_API_KEY,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const App = () => (
  <Router basename={basename} history={history}>
    <Script
      url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`}
    />
    <Root store={store} />
  </Router>
);

function startApp() {
  render(<App />, document.getElementById('root'));
}

// if (process.env.NODE_ENV !== 'production') {
localStorage.setItem('debug', 'trc:*');
// }

startApp();
