import { get } from 'lodash';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import auth from 'auth/auth';

class Login extends Component {
  async componentDidMount() {
    const redirect = get(this.props, 'location.state.from.pathname', '/');
    const search = get(this.props, 'location.state.from.search', '');
    const finalRedirect = redirect + search;
    window.localStorage.setItem('redirectAfterLogin', finalRedirect);

    try {
      const sessionRenewed = await auth.renewSession();
      if (sessionRenewed) {
        this.props.history.replace(finalRedirect);
      } else {
        auth.login();
      }
    } catch (err) {
      this.props.history.push('/error', { error: err.toString() });
    }
  }

  render() {
    return <FullscreenSpinner />;
  }
}

Login.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default Login;
