export default (theme) => ({
  container: ({ alignCenter, labelPlacement }) => ({
    marginTop: alignCenter ? labelPlacement === 'top' ? 6 : 18 : 0,
  }),
  toggleSelectRoot: {
    margin: theme.spacing(1),
  },
  toggleButton: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
  selectedRoot: {
    backgroundColor: theme.palette.secondary.main,
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  selectedLabel: {
    color: theme.palette.secondary.contrastText,
  },
});
