import { withStyles } from '@material-ui/core/styles';

import { Field } from 'formik';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { TextField } from 'modules/intake/form';
import Hint from 'modules/common/hint.component';
import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class PropertyPricingForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.pricing, nextProps.pricing) ||
      !isEqual(this.props.balance, nextProps.balance);
  }

  render() {
    Log.trace('RENDER', 'PropertyPricingForm');
    const {
      pricing,
      balance,
      handleChange,
      handleBlur,
      rootPath,
    } = this.props;

    const mortgageBalance = balance.propertyCreditBalances.reduce((total, line) => {
      const value = parseFloat(line.balance);
      if (isNaN(value)) {
        return total;
      }
      return total + value;
    }, 0);

    const desiredListingPrice = parseFloat(pricing.desiredListingPrice);
    const showHelpfulHint = !isNaN(desiredListingPrice) && desiredListingPrice < mortgageBalance;

    return (
      <>
        <div className="row">
          <Field
            component={TextField}
            name={`${rootPath}.desiredListingPrice`}
            label="Desired List Price"
            value={pricing.desiredListingPrice}
            onChange={handleChange}
            onBlur={handleBlur}
            allowDecimal={true}
            numeric
            prefix="$"
          />
          <Field
            component={TextField}
            name={`${rootPath}.satisfiedSellingPrice`}
            label="Satisfactory Offer Price"
            value={pricing.satisfiedSellingPrice}
            onChange={handleChange}
            onBlur={handleBlur}
            allowDecimal={true}
            numeric
            prefix="$"
          />
        </div>
        {showHelpfulHint &&
          <Hint>Be mindful of possible negative equity</Hint>
        }
      </>
    );
  }
}

PropertyPricingForm.propTypes = {
  pricing: PropTypes.object.isRequired,
  balance: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyPricingForm);
