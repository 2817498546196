import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

import ErrorMessage from 'modules/intake/form/errorMessage.component';
import InputContext from 'modules/intake/form/inputContext';
import SimpleAutocomplete from 'modules/intake/form/simpleAutocomplete.component';

export default class AutocompleteTextField extends PureComponent {
  static contextType = InputContext;

  render() {
    const { onBlur, onFocus, onChange } = this.context;
    const { className, suggestions, form, field, label, disabled, showSuggestionOnFocus } = this.props;
    return (
      <div className={classNames('form-control-wrapper', className)}>
        <SimpleAutocomplete
          suggestions={suggestions}
          showSuggestionOnFocus={showSuggestionOnFocus}
          initialValue={field.value}
          label={label}
          onSelect={(value) => {
            form.setFieldValue(field.name, value ? value.description : '');
            onChange();
          }}
          onInputEdit={(value) => {
            form.setFieldValue(field.name, value);
            onChange();
          }}
          onFocus={onFocus}
          onBlur={() => {
            this.props.onBlur({ value: field.value });
            onBlur();
          }}
          disabled={disabled || form.isSubmitting}
        />
        <ErrorMessage name={field.name} />
      </div>
    );
  }
}

AutocompleteTextField.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  form: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  suggestions: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  showSuggestionOnFocus: PropTypes.bool,
};
