import { Button, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { GET_BENEFITS_FAILURE, UPDATE_MASTER_BENEFIT_FAILURE } from './benefit.types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { getBenefits, updateMasterBenefit } from './benefit.actions';
import { isLoadingSelector } from './benefit.selectors';
import { setPageTitle, showToast } from 'modules/layout/layout.actions';
import { sortSummaries, transformSummaryIntoBenefit } from './util';
import BenefitCard from './benefitCard.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

const BenefitsContainer = (props) => {
  const { isLoading, summaries, history } = props;

  const persistUpdates = async (summary, errorCallback) => {
    const benefit = transformSummaryIntoBenefit(summary);
    const action = await props.updateMasterBenefit(benefit);
    if (action.type === UPDATE_MASTER_BENEFIT_FAILURE) {
      errorCallback();
    }
  };

  useEffect(() => {
    (async () => {
      props.setPageTitle('Master Benefits');
      const action = await props.getBenefits();
      if (action.type === GET_BENEFITS_FAILURE) {
        props.showToast('Failed to load benefits', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    })();
  }, []); // eslint-disable-line

  if (isLoading) {
    return <FullscreenSpinner />;
  }
  return (
    <div className="px-2">
      <div className="row justify-end my-2">
        <Button size="large" variant="contained" color="primary" onClick={() => history.push('/benefits/add')}>
          Add
        </Button>
      </div>
      <Grid container spacing={3}>
        {(summaries || []).map((summary) => (
          <Grid item className="column-no-grow" key={summary.id}>
            <BenefitCard
              summary={summary}
              onEdit={(version) => {
                history.push(`/benefits/${summary.id}/v/${version.versionId}`);
              }}
              onToggleOption={(optId, enabled, errorCallback) => {
                const selectedOption = summary.options.find((opt) => opt.id === optId);
                selectedOption.enabled = enabled;
                persistUpdates(summary, errorCallback);
              }}
              onToggleEnabled={(enabled, errorCallback) => {
                summary.enabled = !summary.enabled;
                summary.options.forEach((opt) => {
                  opt.enabled = enabled;
                });
                persistUpdates(summary, errorCallback);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    summaries: state.benefit.benefitSummaries ? sortSummaries(state.benefit.benefitSummaries) : null,
    isLoading: isLoadingSelector(state),
  };
};

BenefitsContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  summaries: PropTypes.array,
  history: PropTypes.object.isRequired,

  getBenefits: PropTypes.func.isRequired,
  updateMasterBenefit: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getBenefits, updateMasterBenefit, setPageTitle, showToast })(BenefitsContainer);
