import { Field } from 'formik';
import { Link, MenuItem, TextField as MuiTextField, Paper, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { CityAutocompleteTextField, Select, TextField } from 'modules/intake/form';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import { formatDistance, getMapUrl } from 'utilities/common';
import { getDistance } from 'utilities/googleMapsClient';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import Log from 'utilities/log';

import { HEADER_HEIGHT_SHRINK } from '../constants';

const styles = (theme) => ({
  container: {
    height: 'auto',
    minHeight: HEADER_HEIGHT_SHRINK,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    zIndex: theme.zIndex.appBar + 1,
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
  },
  destinationColumn: {
    marginLeft: 8,
    display: 'inline-flex',
    flexDirection: 'column',
  },
  drawerOpen: {
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
  },
  inputGroup: {
    display: 'inline-flex',
    marginRight: theme.spacing(2),
    verticalAlign: 'top',
    '&:last-child': {
      marginRight: 0,
    },
  },
  routeDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
  },
  textField: {
    marginRight: theme.spacing(1),
    maxWidth: 185,
  },
  reloSelect: {
    minWidth: 150,
  },
});

class IntakeRecordHeader extends Component {

  state = {
    showReloPolicyConfirmation: false,
    newReloPolicyId: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.primaryInformation, nextProps.primaryInformation)
    || this.state.showReloPolicyConfirmation !== nextState.showReloPolicyConfirmation
     || this.props.shouldShowSideDrawer !== nextProps.shouldShowSideDrawer
     || this.props.eeIntakeForm !== nextProps.eeIntakeForm;
  }

  recalculateDistance = async () => {
    const { departureLocation, destinationLocation } = this.props.primaryInformation;
    const result = await getDistance(departureLocation, destinationLocation);
    this.props.setFieldValue('primaryInformation.relocationDistance', result.value);
  };

  handleChangeReloPolicy = () => {
    this.props.setFieldValue('authorizationInformation.selfServicePolicyId', '', false);
    this.props.setFieldValue('authorizationInformation.selfServicePolicyBudget', '', false);
    this.props.setFieldValue('primaryInformation.relocationPolicyCodeId', this.state.newReloPolicyId, false);

    this.setState({ showReloPolicyConfirmation: false });
  };

  render() {
    Log.trace('RENDER', 'IntakeRecordHeader');
    const {
      classes,
      eeIntakeForm,
      primaryInformation,
      handleChange,
      handleBlur,
      shouldShowSideDrawer,
    } = this.props;

    const { showReloPolicyConfirmation } = this.state;

    return (
      <>
        <Paper square className={classNames('position-fixed', classes.container, { [classes.drawerOpen]: shouldShowSideDrawer })}>
          <div className={classes.inputGroup}>
            <div>
              <Field
                component={TextField}
                name="primaryInformation.mtAuthorizationId"
                label="File"
                value={primaryInformation.mtAuthorizationId}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
              <Field
                component={TextField}
                name="primaryInformation.transferee.firstName"
                label="EE First Name"
                className={classes.textField}
                value={primaryInformation.transferee.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.firstName != null) && (
                <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
              )}
            </div>
            <div>
              <Field
                component={TextField}
                name="primaryInformation.transferee.nickname"
                label="EE Nickname"
                className={classes.textField}
                value={primaryInformation.transferee.nickname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.nickName != null) && (
                <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
              )}
            </div>
            <div>
              <Field
                component={TextField}
                name="primaryInformation.transferee.lastName"
                label="EE Last Name"
                className={classes.textField}
                value={primaryInformation.transferee.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.lastName != null) && (
                <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
              )}
            </div>
          </div>
          <div className={classes.inputGroup}>
            <div className={classes.destinationColumn}>
              <MuiTextField
                type="text"
                name="departureLocation"
                label="Departure Location"
                variant="outlined"
                classes={{ root: 'mr-4' }}
                value={primaryInformation.departureLocation.formattedAddress}
                disabled
              />
              {primaryInformation.relocationDistance &&
                <div className={classes.routeDetails}>
                  <Typography>Distance: {formatDistance(primaryInformation.relocationDistance)}</Typography>
                  <Link rel="noopener" target="blank" href={getMapUrl(primaryInformation.departureLocation, primaryInformation.destinationLocation)}>Map It!</Link>
                </div>
              }
            </div>
            <div>
              <Field
                component={CityAutocompleteTextField}
                name="primaryInformation.destinationLocation"
                label="Destination Location"
                className={classes.textField}
                value={primaryInformation.destinationLocation}
                onChange={async (event) => {
                  await this.recalculateDistance();
                  handleChange(event);
                }}
                onBlur={handleBlur}
              />
              {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.destinationCity != null) && (
                <Typography style={{ color:'green', marginTop: '8px' }}>^ Sourced from EE</Typography>
              )}
            </div>
          </div>
          <div className={classes.inputGroup}>
            <Field
              component={Select}
              name="primaryInformation.relocationPolicyCodeId"
              label="Relocation Policy"
              className={classes.reloSelect}
              value={primaryInformation.relocationPolicyCodeId || -1}
              onChange={(event) => {
                if (event.target.value !== primaryInformation.relocationPolicyCodeId) {
                  this.setState({ showReloPolicyConfirmation: true, newReloPolicyId: event.target.value }, () => {
                  });

                } else {
                  handleChange(event);
                }
              }}
              onBlur={handleBlur}
              labelWidth={108}
            >
              {primaryInformation.relocationPolicyCodes.map((relocationPolicyCode) => (
                <MenuItem key={relocationPolicyCode.id}
                  value={relocationPolicyCode.id}
                  disabled={!(relocationPolicyCode.active && relocationPolicyCode.webViewable)}
                >{relocationPolicyCode.policyCode}</MenuItem>
              ))}
            </Field>
          </div>
          <div className={classes.inputGroup}>
            <div>
              <Field
                component={TextField}
                name="primaryInformation.newJobStartDate"
                label="EE Start Date"
                className={classes.textField}
                value={primaryInformation.newJobStartDate}
                onChange={handleChange}
                onBlur={handleBlur}
                type="date"
              />
              {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.newJobStartDate != null) && (
                <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
              )}
            </div>
          </div>
       
        </Paper>
        <ConfirmationDialog
          open={showReloPolicyConfirmation}
          title="Change Relocation Policy?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleChangeReloPolicy}
          onCancel={() => this.setState({ showReloPolicyConfirmation: false })}
        >
          <Typography>
            Changing the Relocation Policy will remove any Self Service Policy that was assigned.{' '}
            <br />
            <br />
            Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </>
    );
  }
}

IntakeRecordHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  primaryInformation: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  shouldShowSideDrawer: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    shouldShowSideDrawer: state.layout.shouldShowSideDrawer,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {}),
)(IntakeRecordHeader);
