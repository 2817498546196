import { boolean, number, object, string } from 'yup';

import { formatForWire, isNotDefined, parseNumbers } from 'utilities/common';

import { METADATA_ITEM_SHAPE } from '../constants';

export default class AuthorizationComment {
  static schema = object().shape({
    comment: string(),
    isWebViewable: boolean(),
    serviceTypeId: number(),
    // TODO these are not required, need propery schema to test
    // regardingTypeId: number(),
    // regardingType: METADATA_ITEM_SHAPE,
    serviceType: METADATA_ITEM_SHAPE,
  });

  constructor(wireData, serviceTypes, regardingTypes) {
    const json = wireData || {};
    this.comment = json.comment || '';
    this.isWebViewable = !!json.isWebViewable;
    this.serviceTypeId = json.serviceTypeId || '';
    this.regardingTypeId = json.regardingTypeId || '';
    this.regardingType = isNotDefined(this.regardingTypeId) ? null: regardingTypes[this.regardingTypeId];
    this.serviceType = isNotDefined(this.serviceTypeId) ? null: serviceTypes[this.serviceTypeId];
  }

  static fromWireData(wireData, serviceTypes, regardingTypes) {
    return new AuthorizationComment(wireData, serviceTypes, regardingTypes);
  }

  toWireData() {
    return formatForWire({
      comment: this.comment,
      isWebViewable: this.isWebViewable,
      ...parseNumbers({
        serviceTypeId: this.serviceTypeId,
        regardingTypeId: this.regardingTypeId,
      }),
    });
  }
}
