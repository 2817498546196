import { boolean, object, string } from 'yup';

import { calculateDuration, formatDateForInput, formatForWire } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, NULLABLE_BOOLEAN, plusMinusFiveYearsDateSchema } from 'modules/common/constants';

export default class HhgBenefits {
  static schema = object().shape({
    didReview: boolean(),
    notes: string(),
    didConfirmProvider: boolean(),
    provider: string().max(300, MAX_LENGTH_ERROR_MESSAGE),
    providerLink: string(),
    preferredSurveyDate: plusMinusFiveYearsDateSchema,
    preferredPackDate: plusMinusFiveYearsDateSchema,
    possibleStorageStartDate: plusMinusFiveYearsDateSchema,
    possibleStorageEndDate: plusMinusFiveYearsDateSchema,
    preferredDeliveryDate: plusMinusFiveYearsDateSchema,
    hasDestinationStorage : NULLABLE_BOOLEAN,
  });

  constructor(wireData, temporaryLiving) {
    const json = wireData || {};
    this.didReview = json.didReview || false;
    this.notes = json.notes || '';
    this.didConfirmProvider = json.didConfirmProvider || false;
    this.hasDestinationStorage = json.hasDestinationStorage;
    this.provider = json.provider || '';
    this.providerLink = json.providerLink || '';
    this.preferredSurveyDate = formatDateForInput(json.preferredSurveyDate);
    this.preferredPackDate = formatDateForInput(json.preferredPackDate);
    // default storage dates to temporary living dates
    this.possibleStorageStartDate = formatDateForInput(json.possibleStorageStartDate || temporaryLiving.estimatedStartDate);
    this.possibleStorageEndDate = formatDateForInput(json.possibleStorageEndDate || temporaryLiving.estimatedEndDate);
    this.storageLength = calculateDuration(this.possibleStorageStartDate, this.possibleStorageEndDate) || '';
    this.preferredDeliveryDate = formatDateForInput(json.preferredDeliveryDate);
  }

  static fromWireData(wireData) {
    return new HhgBenefits(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      didReview: this.didReview,
      notes: this.notes,
      didConfirmProvider: this.didConfirmProvider,
      hasDestinationStorage: this.hasDestinationStorage,
      provider: this.provider,
      providerLink: this.providerLink,
      preferredSurveyDate: this.preferredSurveyDate,
      preferredPackDate: this.preferredPackDate,
      possibleStorageStartDate: this.possibleStorageStartDate,
      possibleStorageEndDate: this.possibleStorageEndDate,
      storageLength: this.storageLength,
      preferredDeliveryDate: this.preferredDeliveryDate,
    });
  }
}
