import { array, object } from 'yup';

import { formatForWire } from 'utilities/common';

import TimelineEntry from './timelineEntry';

export default class MiscellaneousBenefits {
  static schema = object().shape({
    miscTimelineEntries: array().of(TimelineEntry.schema),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.miscTimelineEntries = (json.miscTimelineEntries || []).map((instance) => new TimelineEntry(instance));
  }

  static fromWireData(wireData) {
    return new MiscellaneousBenefits(wireData);
  }

  toWireData() {
    return formatForWire({
      miscTimelineEntries: this.miscTimelineEntries.map((instance) => instance.toWireData()),
    });
  }
}
