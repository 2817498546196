import { ErrorMessage as FormikErrorMessage } from 'formik';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ErrorMessage = (props) => {
  return (
    <Typography variant="caption" color="error">
      <FormikErrorMessage name={props.name} />
    </Typography>
  );
};

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ErrorMessage;
