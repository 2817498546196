import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  OutlinedInput,
  withStyles,
} from '@material-ui/core';
import { fieldToSelect } from 'formik-material-ui';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import InputContext from 'modules/intake/form/inputContext';

const styles = (theme) => ({
  root: {
    minWidth: 'unset',
    marginRight: theme.spacing(1),
  },
  selectMenu: {
    minHeight: 16,
  },
});

class Select extends PureComponent {
  static contextType = InputContext;

  render() {
    const { onBlur: contextOnBlur, onFocus: contextOnFocus, onChange: contextOnChange } = this.context;
    const {
      classes,
      field: { name, value: formikValue, onChange: formikOnChange, onBlur: formikOnBlur },
      form: { touched, errors, setFieldTouched },
      labelWidth,
      label,
      fullWidth,
      value: propsValue,
      onChange: propsOnChange,
      onBlur: propsOnBlur,
    } = this.props;

    const fieldError = getIn(errors, name);
    const showError = getIn(touched, name) && !!fieldError;
    const onBlur = (event) => {
      if (propsOnBlur) {
        propsOnBlur(event);
      } else {
        setFieldTouched(name);
        formikOnBlur(event);
      }
      contextOnBlur(event);
    };
    return (
      <FormControl
        variant="outlined"
        fullWidth={fullWidth}
        classes={{ root: classes.root }}
      >
        <InputLabel>{label}</InputLabel>
        <MuiSelect
          classes={{ selectMenu: classes.selectMenu }}
          {...fieldToSelect(this.props)}
          error={showError}
          value={propsValue !== undefined ? propsValue : formikValue}
          onBlur={onBlur}
          onFocus={contextOnFocus}
          onChange={(event) => {
            if (propsOnChange) {
              propsOnChange(event);
            } else {
              formikOnChange(event);
            }
            contextOnChange(event);
            onBlur(event);
          }}
          input={<OutlinedInput name={name} labelWidth={labelWidth || 100} />}
          fullWidth={fullWidth}
        />
        {showError && <FormHelperText error>{fieldError}</FormHelperText>}
      </FormControl>
    );
  }
}

Select.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  value: PropTypes.any,
  field: PropTypes.shape({
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }),
  labelWidth: PropTypes.number,
};

export default withStyles(styles)(Select);
