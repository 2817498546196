import { Button, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Spinner from 'modules/common/spinner.component';

import Dialog from 'modules/common/dialog.component';

const styles = (theme) => ({
  buttonProgress: {
    width: 100,
  },
});

class SubmitIntakeDialog extends Component {
  render() {
    const {
      classes,
      onSubmit,
      onCancel,
      isSubmitting,
      ...rest
    } = this.props;

    return (
      <Dialog
        {...rest}
        title="Submit Intake Record"
        content={
          <Typography>Once submitted to MoveTrack the intake record will not be editable. Are you sure you want to submit?</Typography>
        }
        actions={
          <>
            {!isSubmitting &&
              <Button size="large" variant="outlined" color="secondary" onClick={onCancel}>Cancel</Button>
            }
          
            <Button
              size="large"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              onClick={onSubmit}
              className={classes.buttonProgress}
            >
              {isSubmitting ? <Spinner size="lg" color="#fff" /> : 'Submit'}
            </Button>
          </>
        }
      />
    );
  }
}

SubmitIntakeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default compose(
  withStyles(styles),
)(SubmitIntakeDialog);
