import { object, string } from 'yup';

import { formatAddress, formatForWire } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, NULLABLE_STRING } from 'modules/common/constants';

export default class Location {
  static schema = object().shape({
    formattedAddress: string(),
    city: NULLABLE_STRING.max(60, MAX_LENGTH_ERROR_MESSAGE),
    state: NULLABLE_STRING.max(60, MAX_LENGTH_ERROR_MESSAGE),
    country: NULLABLE_STRING.max(60, MAX_LENGTH_ERROR_MESSAGE),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.city = json.city || '';
    this.state =  json.state || '';
    this.country = json.country || '';
    this.formattedAddress = formatAddress(json);
  }

  get formattedCityState() {
    if (!this.state) {
      return this.city;
    }
    return `${this.city}, ${this.state}`;
  }

  static fromWireData(wireData) {
    return new Location(wireData);
  }

  static generateErrors(errors, prefix) {
    const locationErrors = [];
    const cityError = errors[`${prefix}City`];
    if (cityError) {
      locationErrors.push(cityError);
    }

    const stateError = errors[`${prefix}StateCode`];
    if (stateError) {
      locationErrors.push(stateError);
    }

    const countryError = errors[`${prefix}CountryCode`];
    if (countryError) {
      locationErrors.push(countryError);
    }

    return locationErrors.length > 0 ? locationErrors.join(', '): null;
  }

  toWireData() {
    return formatForWire({
      city: this.city,
      state: this.state,
      country: this.country,
    });
  }
}
