import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextValidator } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { getObjectId } from './util';
import BenefitOptionCard from './benefitOptionCard.component';
import BenefitOptionDialog from './benefitOptionDialog.component';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import PositiveIntegerFormat from 'modules/common/positiveIntegerFormat.component';
import TRCInputValidator from './form/TRCInputValidator';

const useStyles = makeStyles((theme) => ({
  placeholderContent: {},
  multiOptionContainer:{
    borderColor: 'rgba(21, 88, 94, 0.5)',
    maxWidth: '40%',
    position: 'relative',
    padding: theme.spacing(2),
  },
  multiOptionEditButton:{
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    padding: '10px',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgb(247 249 249)',
    },
  },
  quantityDescriptionTooltip: {
    minWidth: '400px',
  },
}));

export const BenefitOptions = (props) => {
  const { version, setVersion, isReadOnly, hasAttemptedPublish, canAddOption, apiCostLookupOptionFields, apiCostLookupAuthorizationFields, tableCostLookupOptionFields, tableCostLookupAuthorizationFields } = props;
  const benefitOptions = version.optionDetails;

  const [openBenefitOptionDialog, setOpenBenefitOptionDialog] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState(null);
  const [otherOpts, setOtherOpts] = useState([]);
  const [confirmDeleteCallback, setConfirmDeleteCallback] = useState(null);

  const classes = useStyles(props);
  const theme = useTheme();

  const findSelectedOptIndex = useCallback((selectedOption) => {
    const targetOptId = getObjectId(selectedOption);
    return benefitOptions.findIndex((opt) => {
      const optId = getObjectId(opt);
      return optId === targetOptId;
    });
  }, [benefitOptions]);

  const editBenefitOption = (targetOpt, newOpt, vrsn) => {
    const optIdx = findSelectedOptIndex(targetOpt);
    vrsn.optionDetails.splice(optIdx, 1, newOpt);
    setVersion({ ...vrsn });
  };

  const moveOptionUp = (targetOpt, vrsn) => {
    const optIdx = findSelectedOptIndex(targetOpt);
    const optToReplace = vrsn.optionDetails[optIdx - 1];
    vrsn.optionDetails[optIdx - 1] = targetOpt;
    vrsn.optionDetails[optIdx] = optToReplace;
    setVersion({ ...vrsn });
  };

  const moveOptionDown = (targetOpt, vrsn) => {
    const optIdx = findSelectedOptIndex(targetOpt);
    const optToReplace = vrsn.optionDetails[optIdx + 1];
    vrsn.optionDetails[optIdx + 1] = targetOpt;
    vrsn.optionDetails[optIdx] = optToReplace;
    setVersion({ ...vrsn });
  };

  useEffect(() => {
    if (selectedOpt) {
      const optIdx = findSelectedOptIndex(selectedOpt);
      const optsCopy = [...benefitOptions];
      optsCopy.splice(optIdx, 1);
      setOtherOpts(optsCopy);
    } else {
      setOtherOpts(benefitOptions);
    }
  }, [selectedOpt, benefitOptions, findSelectedOptIndex]);

  return (
    <>
      <Box p={2}>
        <Box m={2}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h5">Benefit Options</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container alignItems="flex-start" justify="flex-end">
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ visibility: isReadOnly ? 'hidden' : 'visible' }}
                  disabled={!canAddOption}
                  onClick={() => setOpenBenefitOptionDialog(true)}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box px={4}>
          <Paper variant="outlined" className={classes.multiOptionContainer}>
            <Grid direction="column" container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={version.canSelectMultipleOptions}
                      onChange={(e) => {
                        setVersion({ ...version, canSelectMultipleOptions: e.target.checked });
                      }}
                      disabled={isReadOnly}
                    />
                  }
                  label="Allow Selection of Multiple Options For This Benefit"
                />
              </Grid>
              <Grid item container direction="column" spacing={0}>
                <Grid item >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={version.canSelectMultipleOfAnOption}
                        onChange={(e) => {
                          setVersion({ ...version, canSelectMultipleOfAnOption: e.target.checked, maxOptionQuantity: null, quantityDescription: null });
                        }}
                        disabled={isReadOnly}
                      />
                    }
                    label="Allow Selection Of A Single Option Multiple Times"
                  />
                </Grid>
                {
                  version.canSelectMultipleOfAnOption &&
                    <>
                      <Grid item>
                        <TRCInputValidator
                          component={TextValidator}
                          InputProps={{ readOnly: isReadOnly, inputComponent: PositiveIntegerFormat }}
                          disabled={!version.canSelectMultipleOfAnOption}
                          label="Max Quantity"
                          onChange={(value) => {
                            if (value !== version.maxOptionQuantity) {
                              setVersion({ ...version, maxOptionQuantity: value });
                            }
                          }}
                          name="maxQuantity"
                          value={version.maxOptionQuantity}
                          required={version.canSelectMultipleOfAnOption}
                          validateOnBlur={hasAttemptedPublish}
                          validateOnChange={hasAttemptedPublish}
                        />
                      </Grid>
                      <Grid item container direction="row" alignItems="center">
                        <Grid item xs={11}>
                          <TRCInputValidator
                            component={TextValidator}
                            InputProps={{ readOnly: isReadOnly }}
                            disabled={!version.canSelectMultipleOfAnOption}
                            label="Quantity Description"
                            onChange={(value) => {
                              if (value !== version.quantityDescription) {
                                setVersion({ ...version, quantityDescription: value });
                              }
                            }}
                            name="quantityDescription"
                            value={version.quantityDescription}
                            required={version.canSelectMultipleOfAnOption}
                            validateOnBlur={hasAttemptedPublish}
                            validateOnChange={hasAttemptedPublish}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip 
                            arrow 
                            placement="top-end" 
                            classes={{ tooltip: classes.quantityDescriptionTooltip }} 
                            title={
                              <>
                                <Typography color="inherit">This will be what's used to prompt the transferee for how many of the option they'd like. It could be different for different types of benefits.  For example:</Typography>
                                <Box fontStyle="italic" mt={2}>
                                  <Typography>How many pets will you be relocating?</Typography>
                                  <Box mt={1}>
                                    <Typography>How many people, including you, will be traveling?</Typography>
                                  </Box>
                                  <Box mt={1}>
                                    <Typography>How many times would you like the property cleaned?</Typography>
                                  </Box>
                                </Box>
                              </>
                            }
                          >
                            <div>
                              <FontAwesomeIcon color={theme.palette.primary.light} icon={['fas', 'question-circle']} />
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                }
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Grid className="flex-grow" container spacing={0}>
          {benefitOptions.length === 0 && (
            <Grid item xs={3} className="row p2">
              <Paper className={classNames('center-container', 'px-1', classes.placeholderContent)} elevation={4}>
                <Typography align="center" variant="h5" color="textSecondary">
                  No Options Added
                </Typography>
              </Paper>
            </Grid>
          )}
          {benefitOptions.length > 0 &&
            benefitOptions.map((opt, idx) => (
              <Grid item key={getObjectId(opt)} className="p2">
                <BenefitOptionCard
                  benefitOption={opt}
                  costType={version.costType}
                  tableCostLookupOptionFields={tableCostLookupOptionFields}
                  apiCostLookupOptionFields={apiCostLookupOptionFields}
                  onEdit={() => {
                    setOpenBenefitOptionDialog(true);
                    setSelectedOpt(opt);
                  }}
                  onDelete={() => setConfirmDeleteCallback(() => () => {
                    const targetOptionId = getObjectId(opt);
                    const optionIdx = version.optionDetails.findIndex((optionInList) => {
                      const optionId = getObjectId(optionInList);
                      return optionId === targetOptionId;
                    });
                    version.optionDetails.splice(optionIdx, 1);
                    setVersion({ ...version, optionDetails: [...version.optionDetails] });
                    setConfirmDeleteCallback(null);
                  })}
                  isReadOnly={isReadOnly}
                  optionsLength={benefitOptions.length}
                  index={idx}
                  moveUp={() => moveOptionUp(opt, version)}
                  moveDown={() => moveOptionDown(opt, version)}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      <BenefitOptionDialog
        open={openBenefitOptionDialog}
        close={() => {
          setOpenBenefitOptionDialog(false);
          setSelectedOpt(null);
        }}
        costType={version.costType}
        costLookupKey={version.costLookupKey}
        apiCostLookupOptionFields={apiCostLookupOptionFields}
        apiCostLookupAuthorizationFields={apiCostLookupAuthorizationFields}
        tableCostLookupAuthorizationFields={tableCostLookupAuthorizationFields}
        selectedOpt={selectedOpt}
        otherOpts={otherOpts}
        setBenefitOption={(newOpt) => {
          if (selectedOpt) {
            editBenefitOption(selectedOpt, newOpt, version);
          } else {
            setVersion({ ...version, optionDetails: [...benefitOptions, newOpt] });
          }
        }}
      />
      {confirmDeleteCallback &&
        <ConfirmationDialog
          onConfirm={confirmDeleteCallback}
          onCancel={() => {
            setConfirmDeleteCallback(null);
          }}
          open
          title="Delete Option?"
          confirmLabel="Delete"
        >
          <Typography>Are you sure you want to delete this option?</Typography>
        </ConfirmationDialog>
      }
    </>
  );
};

BenefitOptions.propTypes = {
  version: PropTypes.object.isRequired,
  setVersion: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  hasAttemptedPublish: PropTypes.bool.isRequired,
  canAddOption: PropTypes.bool.isRequired,
  apiCostLookupOptionFields: PropTypes.array,
  apiCostLookupAuthorizationFields: PropTypes.array,
  tableCostLookupOptionFields: PropTypes.array,
  tableCostLookupAuthorizationFields: PropTypes.array,
};

export default BenefitOptions;
