import { object, string } from 'yup';

import { formatForWire } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE } from 'modules/common/constants';

export default class LegalName {
  static schema = object().shape({
    firstName: string().max(300, MAX_LENGTH_ERROR_MESSAGE),
    middleName: string().max(300, MAX_LENGTH_ERROR_MESSAGE),
    lastName: string().max(300, MAX_LENGTH_ERROR_MESSAGE),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.id = json.id;
    this.firstName = json.firstName || '';
    this.middleName = json.middleName || '';
    this.lastName = json.lastName || '';
  }

  static fromWireData(wireData) {
    return new LegalName(wireData);
  }

  toWireData() {
    return formatForWire({
      id: this.id,
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
    });
  }
}
