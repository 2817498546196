import { boolean, object, string } from 'yup';

import { formatForWire, hasNonNullValue, isNotDefined, parseNumbers } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, NULLABLE_BOOLEAN, NULLABLE_STRING } from 'modules/common/constants';

export default class DestinationInformation {
  static schema = object().shape({
    isDestinationServiceInitiated: boolean(),
    isPurchasing: NULLABLE_BOOLEAN,
    purchasingDestinationHomeTypeId: NULLABLE_STRING,
    purchasingFamiliarWithAreaId: NULLABLE_STRING,
    purchasingMinPrice: string(),
    purchasingMaxPrice: string(),
    purchasingBedroomsPreferred: string(),
    purchasingBathroomsPreferred: string(),
    purchasingSquareFootage: string(),
    purchasingFireplacePreferenceId: NULLABLE_STRING,
    purchasingGaragePreferenceId: NULLABLE_STRING,
    purchasingBasementPreferenceId: NULLABLE_STRING,
    purchasingHasSchoolRequest: NULLABLE_BOOLEAN,
    purchasingImportantFeaturesSpecialRequests: string().max(5000, MAX_LENGTH_ERROR_MESSAGE),

    isRenting: NULLABLE_BOOLEAN,
    rentingDestinationHomeTypeId: NULLABLE_STRING,
    rentingFamiliarWithAreaId: NULLABLE_STRING,
    rentingMinPrice: string(),
    rentingMaxPrice: string(),
    rentingBedroomsPreferred: string(),
    rentingBathroomsPreferred: string(),
    rentingSquareFootage: string(),
    rentingFireplacePreferenceId: NULLABLE_STRING,
    rentingGaragePreferenceId: NULLABLE_STRING,
    rentingBasementPreferenceId: NULLABLE_STRING,
    rentingHasSchoolRequest: NULLABLE_BOOLEAN,
    rentingImportantFeaturesSpecialRequests: string().max(5000, MAX_LENGTH_ERROR_MESSAGE),
  });

  constructor(wireData) {
    const json = wireData || {};

    this.isDestinationServiceInitiated = !!json.isDestinationServiceInitiated;
    this.isPurchasing = json.isPurchasing;
    this.purchasingDestinationHomeTypeId = json.purchasingDestinationHomeTypeId;
    this.purchasingFamiliarWithAreaId = json.purchasingFamiliarWithAreaId;
    this.purchasingMinPrice = isNotDefined(json.purchasingMinPrice) ? '': json.purchasingMinPrice;
    this.purchasingMaxPrice = isNotDefined(json.purchasingMaxPrice) ? '': json.purchasingMaxPrice;
    this.purchasingBedroomsPreferred = isNotDefined(json.purchasingBedroomsPreferred) ? '': json.purchasingBedroomsPreferred;
    this.purchasingBathroomsPreferred = isNotDefined(json.purchasingBathroomsPreferred) ? '': json.purchasingBathroomsPreferred;
    this.purchasingSquareFootage = isNotDefined(json.purchasingSquareFootage) ? '': json.purchasingSquareFootage;
    this.purchasingFireplacePreferenceId = json.purchasingFireplacePreferenceId;
    this.purchasingGaragePreferenceId = json.purchasingGaragePreferenceId;
    this.purchasingBasementPreferenceId = json.purchasingBasementPreferenceId;
    this.purchasingHasSchoolRequest = json.purchasingHasSchoolRequest;
    this.purchasingImportantFeaturesSpecialRequests = json.purchasingImportantFeaturesSpecialRequests || '';

    this.isRenting = json.isRenting;
    this.rentingDestinationHomeTypeId = json.rentingDestinationHomeTypeId;
    this.rentingFamiliarWithAreaId = json.rentingFamiliarWithAreaId;
    this.rentingMinPrice = isNotDefined(json.rentingMinPrice) ? '': json.rentingMinPrice;
    this.rentingMaxPrice = isNotDefined(json.rentingMaxPrice) ? '': json.rentingMaxPrice;
    this.rentingBedroomsPreferred = isNotDefined(json.rentingBedroomsPreferred) ? '': json.rentingBedroomsPreferred;
    this.rentingBathroomsPreferred = isNotDefined(json.rentingBathroomsPreferred) ? '': json.rentingBathroomsPreferred;
    this.rentingSquareFootage = isNotDefined(json.rentingSquareFootage) ? '': json.rentingSquareFootage;
    this.rentingFireplacePreferenceId = json.rentingFireplacePreferenceId;
    this.rentingGaragePreferenceId = json.rentingGaragePreferenceId;
    this.rentingBasementPreferenceId = json.rentingBasementPreferenceId;
    this.rentingHasSchoolRequest = json.rentingHasSchoolRequest;
    this.rentingImportantFeaturesSpecialRequests = json.rentingImportantFeaturesSpecialRequests || '';
  }

  static fromWireData(wireData) {
    return new DestinationInformation(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  isPopulated() {
    return [
      this.isPurchasing,
      this.purchasingDestinationHomeTypeId,
      this.purchasingFamiliarWithAreaId,
      this.purchasingFireplacePreferenceId,
      this.purchasingGaragePreferenceId,
      this.purchasingBasementPreferenceId,
      this.isRenting,
      this.rentingDestinationHomeTypeId,
      this.rentingFamiliarWithAreaId,
      this.rentingFireplacePreferenceId,
      this.rentingGaragePreferenceId,
      this.rentingBasementPreferenceId,
    ].some(hasNonNullValue) || [
      this.purchasingMinPrice,
      this.purchasingMaxPrice,
      this.purchasingBedroomsPreferred,
      this.purchasingBathroomsPreferred,
      this.purchasingSquareFootage,
      this.purchasingHasSchoolRequest,
      this.purchasingImportantFeaturesSpecialRequests,
      this.isRenting,
      this.rentingMinPrice,
      this.rentingMaxPrice,
      this.rentingBedroomsPreferred,
      this.rentingBathroomsPreferred,
      this.rentingSquareFootage,
      this.rentingHasSchoolRequest,
      this.rentingImportantFeaturesSpecialRequests,
    ].some((value) => !!value);
  }

  toWireData() {
    return formatForWire({
      isDestinationServiceInitiated: this.isDestinationServiceInitiated,
      isPurchasing: this.isPurchasing,
      purchasingDestinationHomeTypeId: this.purchasingDestinationHomeTypeId,
      purchasingFamiliarWithAreaId: this.purchasingFamiliarWithAreaId,
      purchasingFireplacePreferenceId: this.purchasingFireplacePreferenceId,
      purchasingGaragePreferenceId: this.purchasingGaragePreferenceId,
      purchasingBasementPreferenceId: this.purchasingBasementPreferenceId,
      purchasingHasSchoolRequest: this.purchasingHasSchoolRequest,
      purchasingImportantFeaturesSpecialRequests: this.purchasingImportantFeaturesSpecialRequests,
      isRenting: this.isRenting,
      rentingDestinationHomeTypeId: this.rentingDestinationHomeTypeId,
      rentingFamiliarWithAreaId: this.rentingFamiliarWithAreaId,
      rentingFireplacePreferenceId: this.rentingFireplacePreferenceId,
      rentingGaragePreferenceId: this.rentingGaragePreferenceId,
      rentingBasementPreferenceId: this.rentingBasementPreferenceId,
      rentingHasSchoolRequest: this.rentingHasSchoolRequest,
      rentingImportantFeaturesSpecialRequests: this.rentingImportantFeaturesSpecialRequests,

      ...parseNumbers({
        purchasingMinPrice: this.purchasingMinPrice,
        purchasingMaxPrice: this.purchasingMaxPrice,
        purchasingBedroomsPreferred: this.purchasingBedroomsPreferred,
        purchasingBathroomsPreferred: this.purchasingBathroomsPreferred,
        purchasingSquareFootage: this.purchasingSquareFootage,
        rentingMinPrice: this.rentingMinPrice,
        rentingMaxPrice: this.rentingMaxPrice,
        rentingBedroomsPreferred: this.rentingBedroomsPreferred,
        rentingBathroomsPreferred: this.rentingBathroomsPreferred,
        rentingSquareFootage: this.rentingSquareFootage,
      }),
    });
  }
}
