import { object, string } from 'yup';

import { formatForWire, isNotDefined, parseNumbers } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, REQUIRED_STRING } from 'modules/common/constants';

export default class Pet {
  static schema = object().shape({
    petTypeId: REQUIRED_STRING,
    petName: string().max(100, MAX_LENGTH_ERROR_MESSAGE),
    breed: string().max(100, MAX_LENGTH_ERROR_MESSAGE),
    weight: string(),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.id = json.id;
    this.petTypeId = isNotDefined(json.petTypeId) ? '' : json.petTypeId;
    this.petName = json.petName || '';
    this.breed = json.breed || '';
    this.weight = isNotDefined(json.weight) ? '' : json.weight;
  }

  static fromWireData(wireData) {
    return new Pet(wireData);
  }

  toWireData() {
    return formatForWire({
      id: this.id,
      petTypeId: this.petTypeId,
      petName: this.petName,
      breed: this.breed,
      ...parseNumbers({ weight: this.weight }),
    });
  }
}
