import * as Sentry from '@sentry/browser';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
//import Truncate from 'react-truncate';

import { COST_TYPES } from './constants';
import { displayPointsValue } from 'utilities/currencyFormatter';
import { downloadImage } from 'utilities/firebase';
import Spinner from 'modules/common/spinner.component';

const useStyles = makeStyles((theme) => (
  {
    root: {
      width: 300,
    },
    content: {
      padding: theme.spacing(2),
    },
    innerContent: {
      paddingLeft: 0,
    },
    header: {
      padding: 0,
      marginBottom: theme.spacing(2),
    },
    actions: {
      marginBottom: theme.spacing(1),
    },
  }
));

const BenefitOptionCard = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { benefitOption, onEdit, onDelete, isReadOnly, index, optionsLength, moveDown, moveUp, apiCostLookupOptionFields, tableCostLookupOptionFields, costType } = props;
  const [imageUrl, setImageUrl] = useState(null);

  const getOptionFieldLabel = (fieldName) => {
    switch (costType) {
      case COST_TYPES.API_CALC:
        return (apiCostLookupOptionFields.find((field) => field.name === fieldName) || {}).label;
      case COST_TYPES.TABLE_LOOKUP:
        return (tableCostLookupOptionFields.find((field) => field.columnName === fieldName) || {}).label;
      default:
        throw new Error(`bad cost type value: ${costType}`);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (benefitOption.imageId) {
          setImageUrl(await downloadImage(benefitOption.imageId));
        }
      } catch (e) {
        Sentry.captureException(new Error(`Download Image Error: ${JSON.stringify(e)}`));
      }
    })();
  }, [benefitOption.imageId]);

  return (
    <Card className={classes.root} m={2}>
      <div className={classes.content}>
        <CardHeader className={classes.header} title={benefitOption.name} subheader={benefitOption.cost != null ? displayPointsValue(benefitOption.cost) : ''} />
        {benefitOption.imageId && !imageUrl && (
          <div className="center-container">
            <Spinner />
          </div>
        )}
        {imageUrl && <CardMedia image={imageUrl} className="media" />}
        <CardContent className={classes.innerContent}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {benefitOption.description}
          </Typography>
          {Object.entries(benefitOption.costVariables).map(([key, value]) => (
            <Grid container spacing={0}>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>{getOptionFieldLabel(key)}:&nbsp;</Typography>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>{value}</Typography>
            </Grid>
          ))}
        </CardContent>
      </div>
      <CardActions className={classes.actions}>
        {!isReadOnly && 
          <Grid container>
            <Grid item container xs={4}>
              {index !== 0 &&
                <Grid item>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={moveUp}
                  >
                    <ChevronLeft fontSize="large" />
                  </IconButton>
                </Grid>
              }
              {index < optionsLength - 1 &&
                <Grid item>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={moveDown}
                  >
                    <ChevronRight fontSize="large" />
                  </IconButton>
                </Grid>
              }
            </Grid>
            <Grid item container justify="flex-end" xs={8}>
              <IconButton onClick={onDelete}>
                <FontAwesomeIcon color={theme.palette.error.main} icon={['far', 'trash-alt']} />
              </IconButton>
              <IconButton color="primary" onClick={onEdit}>
                <FontAwesomeIcon icon={['fas', 'pen']} color={theme.palette.primary.main} />
              </IconButton>
            </Grid>
          </Grid>
        }
        
      </CardActions>
    </Card>
  );
};

BenefitOptionCard.propTypes = {
  benefitOption: PropTypes.object.isRequired,
  costType: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  moveUp: PropTypes.func.isRequired,
  moveDown: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  optionsLength: PropTypes.number.isRequired,
  apiCostLookupOptionFields: PropTypes.array,
  tableCostLookupOptionFields: PropTypes.array,
};

export default BenefitOptionCard;
