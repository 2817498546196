import { CALL_API } from './middleware/api';
import { GET_FIREBASE_TOKEN_FAILURE, GET_FIREBASE_TOKEN_REQUEST, GET_FIREBASE_TOKEN_SUCCESS } from './index.types';

export const getFirebaseToken = () => {
  return {
    [CALL_API]: {
      types: [GET_FIREBASE_TOKEN_REQUEST, GET_FIREBASE_TOKEN_SUCCESS, GET_FIREBASE_TOKEN_FAILURE],
      authenticated: true,
      endpoint: 'firebase/token',
      method: 'GET',
    },
  };
};
