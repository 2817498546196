import { Box, Grid, MenuItem, TextField } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import { ADD_AUTH_POLICY_NOTE_FAILURE, GET_AUTH_POLICIES_FAILURE } from './types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

import * as LayoutActions from 'modules/layout/layout.actions';
import * as SelfServiceActions from './selfService.actions';
import { NOTE_MAX_LENGTH } from 'modules/common/constants';
import AddNoteDialog from 'modules/common/addNoteDialog.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import SelfServiceSummaryGrid from './selfServiceSummaryGrid.component';

const SelfServiceContainer = (props) => {
  const { isLoading, authPolicies, clientList, history } = props;

  const [clientId, setClientId] = useState(-1);
  const [filteredAuthPolicies, setFilteredAuthPolicies] = useState([]);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [selectedAuthDetails, setSelectedAuthDetails] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      dispatch(LayoutActions.setPageTitle('Self Service'));
      const action = await dispatch(SelfServiceActions.getAuthPolicies());
      if (action.type === GET_AUTH_POLICIES_FAILURE) {
        dispatch(LayoutActions.showToast('Failed to load Authorization Policies', { severity: TOAST_MESSAGE_SEVERITY_ERROR }));
      }
    }
    init();
    }, []); // eslint-disable-line

  useEffect(() => {
    const filteredPolicies =  clientId === -1 ? authPolicies : 
      authPolicies.filter((auth) => auth.authorizationSummary.client.id === clientId);
    setFilteredAuthPolicies(filteredPolicies);

  }, [authPolicies, clientId]);

  const handleSummarySelect = (authDetails) => {
    history.push(`/selfService/${authDetails.authorizationId}`);
  };

  const saveNote = async (authId, note) => {
    setIsSavingNote(true);
    const action = await dispatch(SelfServiceActions.addNote(authId, { note }));
    setIsSavingNote(false);
    if (action.type === ADD_AUTH_POLICY_NOTE_FAILURE) {
      dispatch(LayoutActions.showToast('Failed to add note to the authorization', { severity: TOAST_MESSAGE_SEVERITY_ERROR }));
    } else {
      setSelectedAuthDetails(null);
    }
  };

  if (isLoading) {
    return <FullscreenSpinner />;
  }

  return (
    <Box pl={1} pr={1}>
      <Grid container alignItems="center" justifyContent="flex-start">
        <Box ml={1} mr={1}>
          <TextField
            select
            label="Clients"
            value={clientId}
            onChange={(event) => setClientId(event.target.value)}
            SelectProps={{
              autoWidth: true,
            }}
            variant="outlined"
          >
            <MenuItem value={-1}>
              <em>All Clients</em>
            </MenuItem>
            {clientList.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Grid>
      <SelfServiceSummaryGrid 
        authPolicies={filteredAuthPolicies} 
        onSelect={handleSummarySelect}
        onAddNote={setSelectedAuthDetails}
        isLoading={isLoading}
      />
      {selectedAuthDetails &&
        <AddNoteDialog
          open
          disabled={isSavingNote}
          onDiscard={() => setSelectedAuthDetails(null)}
          onSubmit={(note) => saveNote(selectedAuthDetails.authorizationId, note)}
          maxLength={NOTE_MAX_LENGTH}
        />
      }
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    authPolicies: state.selfService.authPolicies,
    clientList: state.selfService.clientList,
    isLoading: state.selfService.isLoading,
  };
};

SelfServiceContainer.propTypes = {
  authPolicies: PropTypes.array.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  clientList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getAuthPolicies: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(SelfServiceContainer);
