import { MenuItem } from '@material-ui/core';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import CustomAutocomplete from 'modules/intake/form/customAutocomplete.component';

class SimpleAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: props.suggestions,
    };
  }

  getSuggestionValue(suggestion) {
    return suggestion.description;
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const nextSuggestions = this.props.suggestions.filter((suggestion) => {
      return suggestion.description.toLowerCase().match(inputValue);
    });

    this.setState({
      suggestions: nextSuggestions,
    });
  };


  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.suggestions,
    });
  };

  renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion.description}
      </MenuItem>
    );
  };

  handleSelection = (event, { suggestion, method }) => {
    if (method === 'enter') {
      event.preventDefault();
    }

    this.props.onSelect(suggestion);
  };

  render() {
    return (
      <CustomAutocomplete
        getSuggestionValue={this.getSuggestionValue}
        handleSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        handleSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestion={this.renderSuggestion}
        handleSelection={this.handleSelection}
        showSuggestionOnFocus={this.props.showSuggestionOnFocus}
        suggestions={this.state.suggestions}
        initialValue={this.props.initialValue}
        label={this.props.label}
        placeholder={this.props.placeholder}
        onInputClear={this.props.onInputClear}
        onInputEdit={this.props.onInputEdit}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        disabled={this.props.disabled}
      />
    );
  }
}

SimpleAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onInputClear: PropTypes.func,
  onInputEdit: PropTypes.func,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  suggestions: PropTypes.array.isRequired,
  showSuggestionOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SimpleAutocomplete;
