import { array, object, string } from 'yup';
import { map } from 'lodash';

import { formatDateForInput, formatForWire, isNotDefined, parseNumbers } from 'utilities/common';

import {
  MAX_LENGTH_ERROR_MESSAGE,
  NULLABLE_BOOLEAN,
  NULLABLE_STRING,
  plusMinusFiveYearsDateSchema,
  validDateRangeSchema,
} from 'modules/common/constants';
import { METADATA_ITEM_SHAPE } from '../constants';

import Address from './address';
import PropertyCreditBalance from './propertyCreditBalance';
import TitleLegalName from './titleLegalName';

const getSubset = (errors, keys) => {
  return keys.reduce((results, key) => {
    return {
      ...results,
      [key]: errors[key],
    };
  }, {});
};

const TOP_FIELDS = ['isSellingDepartureProperty', 'isEligibleForHomesale', 'isRental', 'homesaleTypeId'];
const TITLE_LEGAL_NAME_FIELDS = ['propertyTitleLegalNames', 'nameChangedSincePurchase', 'marriedSincePurchase', 'divorcedSincePurchase'];
const ADDRESS_FIELDS = ['address1', 'address2', 'city', 'stateCode', 'postalCode', 'coutryCode'];
const BALANCE_FIELDS = ['propertyCreditBalances'];
const ESCROW_FIELDS = ['isTaxesEscrowed', 'isInsuranceEscrowed'];
const FLOOD_INSURANCE_FIELDS = ['isFloodInsuranceRequired'];
const HOA_FIELDS = ['hasHoa', 'hoaPaymentScheduleTypeId', 'hoaPaymentAmount'];
const NEW_CONSTRUCTION_FIELDS = ['isNewConstruction'];
const EXTERIOR_DETAILS_FIELDS = [
  'housePropertyTypeId',
  'yearBuilt',
  'purchasedDate',
  'purchasePrice',
  'overallHouseStyle',
  'houseSidingTypes',
  'ageOfHouseRoof',
  'hasRoofBeenReplaced',
  'hasRoofWarranty',
];
const FEATURES_FIELDS = [
  'numberBedrooms',
  'numberBathrooms',
  'squareFootage',
  'lotSize',
  'hasBasement',
  'basementFinishTypeId',
  'hasGarage',
  'isGarageAttached',
  'numberGarageParkingSpots',
  'hasFireplace',
  'houseHeatTypeId',
  'houseWaterSourceTypeId',
  'houseSewageTypeId',
  'hasUndergroundTank',
  'undergroundTankLastServiceDate',
  'hasPool',
  'housePoolTypeId',
  'hasHotTub',
  'hasSprinklerSystem',
  'hasWaterSoftener',
  'isWaterSoftenerRented',
  'hasAlarmSystem',
  'alarmSystemCompany',
  'alarmSystemContractTerm',
  'hasSolarPanels',
];
const NOTES_FIELDS = [
  'recentRemodeling',
  'capitalImprovements',
  'easements',
  'specialAssessments',
  'knownDefects',
  'outstandingInsuranceClaims',
  'hasWarranties',
];
const INSPECTION_FIELDS = ['homeInspectionsToOrder'];
const WARRANTIES_FIELDS = [
  'buildersWarrantyDocuments',
  'buildersWarrantyTransferrable',
  'homeWarrantyDocuments',
  'homeWarrantyTransferrable',
  'termiteWarrantyDocuments',
  'termiteWarrantyTransferrable',
  'otherWarrantyDescription',
  'otherWarrantyDocuments',
  'otherWarrantyTransferrable',
];
const PRICING_FIELDS = ['desiredListingPrice', 'satisfiedSellingPrice'];
const LEASE_BREAK_FIELDS = [
  'leaseEndDate',
  'hasGivenNotice',
  'hasLeaseBreakPenalty',
  'leaseBreakPenalty',
  'rentalLeaveDate',
];

export default class DeparturePropertyInformation {
  static schema = object().shape({
    isRental: NULLABLE_BOOLEAN,
    isSellingDepartureProperty: NULLABLE_BOOLEAN,
    isEligibleForHomesale: NULLABLE_BOOLEAN,
    homesaleTypeId: NULLABLE_STRING,
    titleLegalName: TitleLegalName.schema,
    address: object().shape({
      city: string().max(40, MAX_LENGTH_ERROR_MESSAGE),
      stateCode: string().max(3, MAX_LENGTH_ERROR_MESSAGE),
      postalCode: string().max(10, MAX_LENGTH_ERROR_MESSAGE),
      countryCode: string().max(3, MAX_LENGTH_ERROR_MESSAGE),
    }),
    balance:  object().when('isRental', {
      is: true,
      then: () => object(),
      otherwise: () => object().shape({
        propertyCreditBalances: array().of(PropertyCreditBalance.schema),
      }),
    }),
    escrow: object().shape({
      isTaxesEscrowed: NULLABLE_BOOLEAN,
      isInsuranceEscrowed: NULLABLE_BOOLEAN,
    }),
    floodInsurance: object().shape({
      isFloodInsuranceRequired: NULLABLE_BOOLEAN,
    }),
    hoa: object().shape({
      hasHoa: NULLABLE_BOOLEAN,
      hoaPaymentScheduleTypeId: NULLABLE_STRING,
      hoaPaymentAmount: string().max(10, MAX_LENGTH_ERROR_MESSAGE),
    }),
    newConstruction: object().shape({
      isNewConstruction: NULLABLE_BOOLEAN,
    }),
    exteriorDetails: object().shape({
      housePropertyTypeId: NULLABLE_STRING,
      yearBuilt: string(),
      purchasedDate: validDateRangeSchema,
      purchasePrice: string().max(10, MAX_LENGTH_ERROR_MESSAGE),
      overallHouseStyle: string().max(100, MAX_LENGTH_ERROR_MESSAGE),
      houseSidingTypes: array().of(METADATA_ITEM_SHAPE),
      ageOfHouseRoof: string(),
      hasRoofBeenReplaced: NULLABLE_BOOLEAN,
      hasRoofWarranty: NULLABLE_BOOLEAN,
    }),
    features: object().shape({
      numberBedrooms: string(),
      numberBathrooms: string(),
      squareFootage: string(),
      lotSize: string(),
      hasBasement: NULLABLE_BOOLEAN,
      basementFinishTypeId: NULLABLE_STRING,
      hasGarage: NULLABLE_BOOLEAN,
      isGarageAttached: NULLABLE_BOOLEAN,
      numberGarageParkingSpots: string(),
      hasFireplace: NULLABLE_BOOLEAN,
      houseHeatTypeId: NULLABLE_STRING,
      houseWaterSourceTypeId: NULLABLE_STRING,
      houseSewageTypeId: NULLABLE_STRING,
      hasUndergroundTank: NULLABLE_STRING,
      undergroundTankLastServiceDate: validDateRangeSchema,
      hasPool: NULLABLE_BOOLEAN,
      housePoolTypeId: NULLABLE_STRING,
      hasHotTub: NULLABLE_BOOLEAN,
      hasSprinklerSystem: NULLABLE_BOOLEAN,
      hasWaterSoftener: NULLABLE_BOOLEAN,
      isWaterSoftenerRented: NULLABLE_BOOLEAN,
      hasAlarmSystem: NULLABLE_BOOLEAN,
      alarmSystemCompany: string().max(300, MAX_LENGTH_ERROR_MESSAGE),
      alarmSystemContractTerm: string().max(50, MAX_LENGTH_ERROR_MESSAGE),
      hasSolarPanels: NULLABLE_BOOLEAN,
      solarPanelOwnTypeId: NULLABLE_STRING,
      isSolarPanelStayingWithProperty: NULLABLE_BOOLEAN,
    }),
    notes: object().shape({
      recentRemodeling: string().max(3000, MAX_LENGTH_ERROR_MESSAGE),
      capitalImprovements: string().max(3000, MAX_LENGTH_ERROR_MESSAGE),
      easements: string().max(3000, MAX_LENGTH_ERROR_MESSAGE),
      specialAssessments: string().max(3000, MAX_LENGTH_ERROR_MESSAGE),
      knownDefects: string().max(3000, MAX_LENGTH_ERROR_MESSAGE),
      outstandingInsuranceClaims: string().max(3000, MAX_LENGTH_ERROR_MESSAGE),
      encumbrances: string().max(3000, MAX_LENGTH_ERROR_MESSAGE),
      hasWarranties: NULLABLE_BOOLEAN,
    }),
    inspection: object().shape({
      homeInspectionsToOrder: array().of(METADATA_ITEM_SHAPE),
    }),
    pricing: object().shape({
      desiredListingPrice: string().max(10, MAX_LENGTH_ERROR_MESSAGE),
      satisfiedSellingPrice: string().max(10, MAX_LENGTH_ERROR_MESSAGE),
    }),
    leaseBreak: object().shape({
      leaseEndDate: plusMinusFiveYearsDateSchema,
      hasGivenNotice: NULLABLE_BOOLEAN,
      hasLeaseBreakPenalty: NULLABLE_BOOLEAN,
      leaseBreakPenalty: string(),
      rentalLeaveDate: plusMinusFiveYearsDateSchema,
    }),
  });

  constructor(wireData, transferee, nonTransfereeContacts, metadata) {
    const json = wireData || {};
    this.id = json.id;
    this.intakeRecordId = json.intakeRecordId;
    this.isSellingDepartureProperty = json.isSellingDepartureProperty;
    this.isEligibleForHomesale = json.isEligibleForHomesale;
    this.isRental = json.isRental;
    this.homesaleTypeId = json.homesaleTypeId;
    this.titleLegalName = new TitleLegalName({
      propertyTitleLegalNames: json.propertyTitleLegalNames,
      nameChangedSincePurchase: json.nameChangedSincePurchase,
      marriedSincePurchase: json.marriedSincePurchase,
      divorcedSincePurchase: json.divorcedSincePurchase,
    }, transferee, nonTransfereeContacts);
    this.address = new Address({
      address1: json.address1,
      address2: json.address2,
      city: json.city,
      stateCode: json.stateCode,
      postalCode: json.postalCode,
      countryCode: json.countryCode,
    });
    this.balance = {
      propertyCreditBalances: json.propertyCreditBalances ?
        json.propertyCreditBalances.map((instance) => new PropertyCreditBalance(instance)): [],
    };
    this.escrow = {
      isTaxesEscrowed: json.isTaxesEscrowed,
      isInsuranceEscrowed: json.isInsuranceEscrowed,
    };
    this.floodInsurance = {
      isFloodInsuranceRequired: json.isFloodInsuranceRequired,
    };
    this.hoa = {
      hasHoa: json.hasHoa,
      hoaPaymentScheduleTypeId: isNotDefined(json.hoaPaymentScheduleTypeId) ? '': json.hoaPaymentScheduleTypeId,
      hoaPaymentAmount: json.hoaPaymentAmount || '',
    };
    this.newConstruction = {
      isNewConstruction: json.isNewConstruction,
    };
    this.exteriorDetails = {
      housePropertyTypeId: json.housePropertyTypeId,
      yearBuilt: json.yearBuilt || '',
      purchasedDate: formatDateForInput(json.purchasedDate),
      purchasePrice: json.purchasePrice || '',
      overallHouseStyle: json.overallHouseStyle || '',
      houseSidingTypes: json.houseSidingTypes ? json.houseSidingTypes.map((id) => {
        return metadata.houseSidingTypes[id];
      }): [],
      ageOfHouseRoof: json.ageOfHouseRoof || '',
      hasRoofBeenReplaced: json.hasRoofBeenReplaced,
      hasRoofWarranty: json.hasRoofWarranty,
    };
    this.features = {
      numberBedrooms: isNotDefined(json.numberBedrooms) ? '': json.numberBedrooms,
      numberBathrooms: isNotDefined(json.numberBathrooms) ? '': json.numberBathrooms,
      squareFootage: isNotDefined(json.squareFootage) ? '': json.squareFootage,
      lotSize: json.lotSize || '',
      hasBasement: json.hasBasement,
      basementFinishTypeId: json.basementFinishTypeId,
      hasGarage: json.hasGarage,
      isGarageAttached: json.isGarageAttached,
      numberGarageParkingSpots: json.numberGarageParkingSpots || '',
      hasFireplace: json.hasFireplace,
      houseHeatTypeId: json.houseHeatTypeId,
      houseWaterSourceTypeId: json.houseWaterSourceTypeId,
      houseSewageTypeId: json.houseSewageTypeId,
      hasUndergroundTank: json.hasUndergroundTank,
      undergroundTankLastServiceDate: formatDateForInput(json.undergroundTankLastServiceDate),
      hasPool: json.hasPool,
      housePoolTypeId: json.housePoolTypeId,
      hasHotTub: json.hasHotTub,
      hasSprinklerSystem: json.hasSprinklerSystem,
      hasWaterSoftener: json.hasWaterSoftener,
      isWaterSoftenerRented: json.isWaterSoftenerRented,
      hasAlarmSystem: json.hasAlarmSystem,
      alarmSystemCompany: json.alarmSystemCompany || '',
      alarmSystemContractTerm: json.alarmSystemContractTerm || '',
      hasSolarPanels: json.hasSolarPanels,
      solarPanelOwnTypeId: json.solarPanelOwnTypeId,
      isSolarPanelStayingWithProperty: json.isSolarPanelStayingWithProperty,
    };
    this.notes = {
      hasWarranties: json.hasWarranties,
      recentRemodeling: json.recentRemodeling || '',
      capitalImprovements: json.capitalImprovements || '',
      easements: json.easements || '',
      specialAssessments: json.specialAssessments || '',
      knownDefects: json.knownDefects || '',
      outstandingInsuranceClaims: json.outstandingInsuranceClaims || '',
      encumbrances: json.encumbrances || '',
    };
    this.inspection = {
      homeInspectionsToOrder: json.homeInspectionsToOrder ? json.homeInspectionsToOrder.map((id) => {
        return metadata.houseInspectionTypes[id];
      }): [],
    };
    this.warranties = {
      buildersWarrantyDocuments: json.buildersWarrantyDocuments || '',
      buildersWarrantyTransferrable: json.buildersWarrantyTransferrable || '',
      homeWarrantyDocuments: json.homeWarrantyDocuments || '',
      homeWarrantyTransferrable: json.homeWarrantyTransferrable || '',
      termiteWarrantyDocuments: json.termiteWarrantyDocuments || '',
      termiteWarrantyTransferrable: json.termiteWarrantyTransferrable || '',
      otherWarrantyDescription: json.otherWarrantyDescription || '',
      otherWarrantyDocuments: json.otherWarrantyDocuments || '',
      otherWarrantyTransferrable: json.otherWarrantyTransferrable || '',
    };
    this.pricing = {
      desiredListingPrice: json.desiredListingPrice || '',
      satisfiedSellingPrice: json.satisfiedSellingPrice || '',
    };
    this.leaseBreak = {
      leaseEndDate: formatDateForInput(json.leaseEndDate),
      hasGivenNotice: json.hasGivenNotice,
      hasLeaseBreakPenalty: json.hasLeaseBreakPenalty,
      leaseBreakPenalty: json.leaseBreakPenalty || '',
      rentalLeaveDate: formatDateForInput(json.rentalLeaveDate),
    };
  }

  static fromWireData(wireData) {
    return new DeparturePropertyInformation(wireData);
  }

  isPopulated() {
    return this.isRental ||
      this.isEligibleForHomesale ||
      this.homesaleTypeId ||
      this.isSellingDepartureProperty ||
      this.hoa ||
      this.balance ||
      this.escrow ||
      this.floodInsurance ||
      this.titleLegalName ||
      this.newConstruction ||
      this.exteriorDetails ||
      this.features ||
      this.notes ||
      this.inspection ||
      this.pricing ||
      this.leaseBreak;  
  }

  static generateErrors(errors) {
    if (!errors) {
      return null;
    }

    return {
      ...getSubset(errors, TOP_FIELDS),
      titleLegalName: getSubset(errors, TITLE_LEGAL_NAME_FIELDS),
      address: getSubset(errors, ADDRESS_FIELDS),
      balance: getSubset(errors, BALANCE_FIELDS),
      escrow: getSubset(errors, ESCROW_FIELDS),
      floodInsurance: getSubset(errors, FLOOD_INSURANCE_FIELDS),
      hoa: getSubset(errors, HOA_FIELDS),
      newConstruction: getSubset(errors, NEW_CONSTRUCTION_FIELDS),
      exteriorDetails: getSubset(errors, EXTERIOR_DETAILS_FIELDS),
      features: getSubset(errors, FEATURES_FIELDS),
      notes: getSubset(errors, NOTES_FIELDS),
      inspection: getSubset(errors, INSPECTION_FIELDS),
      warranties: getSubset(errors, WARRANTIES_FIELDS),
      pricing: getSubset(errors, PRICING_FIELDS),
      leaseBreak: getSubset(errors, LEASE_BREAK_FIELDS),
    };
  }

  toWireData() {
    const baseData = {
      id: this.id,
      intakeRecordId: this.intakeRecordId,
      ...this.address.toWireData(),
      isRental: this.isRental,
    };


    let rentalData = {};
    if (this.isRental) {
      rentalData = {
        ...parseNumbers(this.leaseBreak, [
          'leaseBreakPenalty',
        ]),
      };
    }

    let ownershipData = {};
    let sellingData = {};
    // distinguish from null
    if (this.isRental === false) {
      ownershipData = {
        isEligibleForHomesale: this.isEligibleForHomesale,
        homesaleTypeId: this.homesaleTypeId,
        isSellingDepartureProperty: this.isSellingDepartureProperty,
      };

      if (this.isSellingDepartureProperty) {
        sellingData = {
          ...parseNumbers(this.hoa, [
            'hoaPaymentAmount',
          ]),
          propertyCreditBalances: this.balance.propertyCreditBalances.map((instance) => instance.toWireData()),
          ...this.escrow,
          ...this.floodInsurance,
          ...this.titleLegalName.toWireData(),
          isNewConstruction: this.newConstruction.isNewConstruction,
          ...parseNumbers(this.exteriorDetails, [
            'ageOfHouseRoof',
            'purchasePrice',
            'yearBuilt',
          ]),
          houseSidingTypes: map(this.exteriorDetails.houseSidingTypes, 'id'),
          ...parseNumbers(this.features, [
            'numberBedrooms',
            'numberBathrooms',
            'squareFootage',
            'numberGarageParkingSpots',
          ]),
          ...this.notes,
          homeInspectionsToOrder: map(this.inspection.homeInspectionsToOrder, 'id'),
          ...this.warranties,
          ...parseNumbers(this.pricing, [
            'satisfiedSellingPrice',
            'desiredListingPrice',
          ]),
        };
      }
    }

    return formatForWire({
      ...baseData,
      ...rentalData,
      ...ownershipData,
      ...sellingData,
    });
  }
}
