import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Dialog from 'modules/common/dialog.component';

function DeleteIntakeRecordDialog(props) {
  const {
    onSubmit,
    onCancel,
    ...rest
  } = props;

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };
  

  return (
    <Dialog
      {...rest}
      title="Delete Intake Record"
      content={
        <Typography>Are you sure you want to delete this record from the intake tool?</Typography>
      }
      actions={
        <>
          <Button onClick={onCancel} variant="outlined" color="primary">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">Delete</Button>
        </>
      }
    />
  );
}

DeleteIntakeRecordDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeleteIntakeRecordDialog;
