import {
  GET_API_VERSION_FAILURE,
  GET_API_VERSION_REQUEST,
  GET_API_VERSION_SUCCESS,
  HIDE_SIDE_DRAWER,
  HIDE_TOAST_MESSAGE,
  SET_PAGE_TITLE,
  SHOW_SIDE_DRAWER,
  SHOW_TOAST_MESSAGE,
  TOAST_MESSAGE_SEVERITY_ERROR,
  TOAST_MESSAGE_SEVERITY_INFO,
} from './types';

import { CALL_INTAKE_API } from 'middleware/intakeApi';

export const setPageTitle = (title) => {
  return {
    type: SET_PAGE_TITLE,
    response: title,
  };
};

export const showToast = (message, options = {}) => {
  return {
    type: SHOW_TOAST_MESSAGE,
    response: { 
      message, 
      userMustDismiss: options ? options.userMustDismiss : null, 
      severity: options ? options.severity : null, 
    },
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST_MESSAGE,
  };
};

// DEPRECATED
// leaving in to support old code.
export const handleToastMessage = (message, isFailure) => {
  return (dispatch) => {
    dispatch(showToast(message, { userMustDismiss: false, severity: isFailure ? TOAST_MESSAGE_SEVERITY_ERROR : TOAST_MESSAGE_SEVERITY_INFO }));
  };
};

export const showSideDrawer = () => {
  return {
    type: SHOW_SIDE_DRAWER,
  };
};

export const hideSideDrawer = () => {
  return {
    type: HIDE_SIDE_DRAWER,
  };
};

export const getApiVersion = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_API_VERSION_REQUEST, GET_API_VERSION_SUCCESS, GET_API_VERSION_FAILURE],
      authenticated: false,
      endpoint: 'buildVersion',
      method: 'GET',
    },
  };
};
