import { array, object } from 'yup';

import { formatForWire } from 'utilities/common';

import { NULLABLE_BOOLEAN } from 'modules/common/constants';
import LegalName from './legalName';

const generateDefaultTitleNames = (transferee, contacts) => {
  const additionalNames = contacts.filter((contact) => {
    return contact.isSpouse() && (contact.firstName || contact.lastName);
  }).map((contact) => {
    return new LegalName({ firstName: contact.firstName, middleName: '', lastName: contact.lastName });
  });

  return [
    new LegalName({ firstName: transferee.firstName, middleName: '', lastName: transferee.lastName }),
    ...additionalNames,
  ];
};

export const CHANGES_SINCE_PURCHASE = [
  { id: 'legalNameChanged', description: 'Legal Name Change', modelField: 'nameChangedSincePurchase' },
  { id: 'married', description: 'Married', modelField: 'marriedSincePurchase' },
  { id: 'divorced', description: 'Divorced', modelField: 'divorcedSincePurchase' },
];

export default class TitleLegalName {
  static schema = object().shape({
    propertyTitleLegalNames: array().of(LegalName.schema),
    nameChangedSincePurchase: NULLABLE_BOOLEAN,
    marriedSincePurchase: NULLABLE_BOOLEAN,
    divorcedSincePurchase: NULLABLE_BOOLEAN,
  });

  constructor(wireData, transferee, nonTransfereeContacts) {
    const json = wireData || {};
    this.changesSincePurchase = CHANGES_SINCE_PURCHASE.reduce((prev, changeType) => {
      return json[changeType.modelField] ? [...prev, changeType.id] : prev;
    }, []);

    this.propertyTitleLegalNames = json.propertyTitleLegalNames && json.propertyTitleLegalNames.length > 0 ?
      json.propertyTitleLegalNames.map((instance) => new LegalName(instance)):
      generateDefaultTitleNames(transferee, nonTransfereeContacts);
  }

  static fromWireData(wireData) {
    return new TitleLegalName(wireData);
  }

  toWireData() {
    const changesSincePurchase = CHANGES_SINCE_PURCHASE.reduce((prev, changeType) => ({
      ...prev,
      [changeType.modelField]: this.changesSincePurchase.includes(changeType.id),
    }), {});

    return formatForWire({
      ...changesSincePurchase,
      propertyTitleLegalNames: this.propertyTitleLegalNames.map((instance) => instance.toWireData()),
    });
  }
}
