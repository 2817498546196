import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { LocalDate } from 'js-joda';
import PropTypes from 'prop-types';
import React from 'react';

import { formatLocalDate } from 'utilities/common';

import { NOTE_MAX_LENGTH } from './constants';
import { Note } from 'modules/intake/models';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: 0,
  },
}));

function NoteForm(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    message: '',
    noteDate: formatLocalDate(LocalDate.now().toString()),
  });

  const handleTextChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
    props.onChange({ note: state.message });
  };

  function canSave() {
    return !props.disabled &&
      state.message.trim().length > 0 &&
      state.message.length <= NOTE_MAX_LENGTH;
  }

  function handleSave() {
    props.onSave(new Note({ note: state.message }));
  }

  function handleCancel() {
    props.onCancel();
  }

  return (
    <Grid container item xs={12}>
      <Typography variant="caption" className="full-width text-right">{state.noteDate}</Typography>
      <TextField
        autoFocus
        multiline
        rows={4}
        name="message"
        value={state.message}
        onChange={handleTextChange('message')}
        className={classes.formControl}
        fullWidth
        inputProps={{
          maxLength: NOTE_MAX_LENGTH,
        }}
        helperText={`${state.message.length} / ${NOTE_MAX_LENGTH}`}
      />
      <div className="row">
        <Button fullWidth onClick={handleSave} disabled={!canSave()}>Save</Button>
        <Button fullWidth onClick={handleCancel} disabled={props.disabled}>Cancel</Button>
      </div>
    </Grid>
  );
}

NoteForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default NoteForm;
