import { applyMiddleware, createStore } from 'redux';
import benefitApi from 'middleware/api';
import intakeApi from 'middleware/intakeApi';
import rootReducer from 'index.reducer';
import thunk from 'redux-thunk';

const configureStore = (preloadedState) =>
  createStore(rootReducer, preloadedState, applyMiddleware(thunk, intakeApi, benefitApi));

export default configureStore;
