import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Log from 'utilities/log';

import InputContext from 'modules/intake/form/inputContext';

const CHANGE_EVENT_DEBOUNCE_TIME = 10000;

export default class InputListener extends PureComponent {
  handleChange = debounce(() => {
    Log.trace('InputListener handleChange');
    if (typeof this.props.onChange === 'function') {
      setTimeout(() => this.props.onChange());
    }
  }, CHANGE_EVENT_DEBOUNCE_TIME);

  handleFocus = () => {
    Log.trace('InputListener handleFocus');
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus();
    }
    // reset the autosave clock
    this.handleChange();
  };

  handleBlur = () => {
    Log.trace('InputListener handleBlur');
    if (typeof this.props.onBlur === 'function') {
      // wait a tick for form values to propagate
      setTimeout(() => this.props.onBlur());
    }
  };

  submitForm = () => {
    Log.trace('InputListener submitForm');
    if (typeof this.props.submitForm === 'function') {
      this.props.submitForm();
    }
  }

  render() {
    const { children } = this.props;
    return (
      <InputContext.Provider
        value={{
          onChange: this.handleChange,
          onFocus: this.handleFocus,
          onBlur: this.handleBlur,
          submitForm: this.submitForm,
        }}
      >
        {children}
      </InputContext.Provider>
    );
  }
}

InputListener.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  submitForm: PropTypes.func,
  children: PropTypes.node.isRequired,
};
