import {
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Typography,
  styled,
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';

import { wasCreatedToday  } from 'utilities/common';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';

import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import Note from './note.component';
import NoteForm from './noteForm.component';

const styles = (theme) => ({
  listRoot: {
    width: 400,
  },
  addNoteButtonRoot: {
    margin: '0 auto',
  },
  drawerHeader: {
    paddingTop: 64,
    minHeight: 120,
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerSpacer: {
    width: 44,
  },
  drawerRoot: {
    backgroundColor: '#fafafa',
    height: '55%',
  },
});

const byDateDescending = (a, b) => {
  return String(b.created).localeCompare(String(a.created));
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

class NotesDrawer extends PureComponent {
  state = {
    isAddingNote: false,
    isDirty: false,
    showConfirmationDialog: false,
    lastEvent: null,
  };

  handleAddNote = () => {
    this.setState({ isAddingNote: true, isDirty: false });
  };

  handleChangeNote = () => {
    this.setState({ isDirty: true });
  };

  handleSaveNote = (note) => {
    this.props.saveNote(note);
    this.setState({ isAddingNote: false, isDirty: false });
  };

  handleCancelNote = () => {
    this.setState({
      isAddingNote: false,
      isDirty: false,
      lastEvent: null,
      showConfirmationDialog: false,
    });
  };

  handleClose = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (this.state.isDirty) {
      this.setState({ showConfirmationDialog: true, lastEvent: event.persist() });
      return;
    }

    this.props.onClose(event);
    this.handleCancelNote();
  };

  handleConfirmClose = () => {
    this.props.onClose(this.state.lastEvent);
    this.handleCancelNote();
  };

  getTodaysNotes = () => {
    return this.props.notes.filter((note) => wasCreatedToday(note)).sort(byDateDescending);
  };

  getPriorNotes = () => {
    return this.props.notes.filter((note) => !wasCreatedToday(note)).sort(byDateDescending);
  };

  render() {
    const { open, classes, isSubmitting } = this.props;
    const { isAddingNote, showConfirmationDialog } = this.state;
    return (
      <Drawer anchor="right" open={open} variant="persistent" classes={{ paper: classes.drawerRoot }} onClose={this.handleClose}>
        <DrawerHeader classes={{ root: classes.drawerHeader }}>
          <IconButton onClick={this.handleClose} edge="end">
            {!open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
          <ListSubheader>NOTES</ListSubheader>
          <div className={classes.headerSpacer} />
        </DrawerHeader>
        <Divider />
        <List classes={{ root: classes.listRoot }}>
          {!isAddingNote &&
            <ListItem >
              <Button variant="contained" color="primary" classes={{ root: classes.addNoteButtonRoot }}  onClick={this.handleAddNote}>Add Note</Button>
            </ListItem>
          }
          {isAddingNote &&
            <Fragment>
              <ListSubheader disableSticky>New Note:</ListSubheader>
              <ListItem>
                <NoteForm
                  onSave={this.handleSaveNote}
                  onCancel={this.handleCancelNote}
                  onChange={this.handleChangeNote}
                  disabled={isSubmitting}
                />
              </ListItem>
              <Divider />
            </Fragment>
          }
          <ListSubheader disableSticky>Notes:</ListSubheader>
          {this.getTodaysNotes().map((note, i) => (
            <ListItem key={i} classes={{ root: 'mb-2' }}>
              <Note note={note} />
            </ListItem>
          ))}
          <Divider />
          <ListSubheader disableSticky>Prior Notes:</ListSubheader>
          {this.getPriorNotes().map((note, i) => (
            <ListItem key={i} classes={{ root: 'mb-2' }}>
              <Note note={note} />
            </ListItem>
          ))}
        </List>
        <ConfirmationDialog
          onConfirm={this.handleConfirmClose}
          onCancel={() => this.setState({ showConfirmationDialog: false, lastEvent: null })}
          open={showConfirmationDialog}
          title="Unsaved changes"
          confirmLabel="Yes, close"
          cancelLabel="No"
        >
          <Typography>Your note has not been saved. Are you sure you want to close?</Typography>
        </ConfirmationDialog>
      </Drawer>
    );
  }
}

NotesDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  notes: PropTypes.array.isRequired,
  saveNote: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
)(NotesDrawer);
