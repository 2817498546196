import { Typography, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { TextField, ToggleSelect } from 'modules/intake/form';
import { YES_NO_OPTIONS } from 'utilities/common';
import Hint from 'modules/common/hint.component';
import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class PropertyLeaseBreakForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.leaseBreak, nextProps.leaseBreak) ||
    !isEqual(this.props.eeIntakeForm, nextProps.eeIntakeForm);
  }

  render() {
    Log.trace('RENDER', 'PropertyLeaseBreakForm');
    const {
      eeIntakeForm,
      leaseBreak,
      handleChange,
      handleBlur,
      rootPath,
    } = this.props;

    return (
      <>
        <div className="row mb-1">
          <div>
            <Field
              component={TextField}
              name={`${rootPath}.leaseEndDate`}
              label="Lease End Date"
              value={leaseBreak.leaseEndDate}
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
            />
            {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.leaseEndDate && (
              <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
            )}
          </div>
        </div>
        <div className="row mb-1">
          <div>
            <Field
              component={ToggleSelect}
              name={`${rootPath}.hasGivenNotice`}
              value={leaseBreak.hasGivenNotice}
              onChange={handleChange}
              onBlur={handleBlur}
              options={YES_NO_OPTIONS}
              label="Have you put your notice in?"
            />
            {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.hasGivenNotice != null) && (
              <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
            )}
          </div>
        </div>
        <div className="row mb-1">
          <div>
            <Field
              component={ToggleSelect}
              name={`${rootPath}.hasLeaseBreakPenalty`}
              value={leaseBreak.hasLeaseBreakPenalty}
              onChange={handleChange}
              onBlur={handleBlur}
              options={YES_NO_OPTIONS}
              label="Are you facing a lease break penalty?"
              alignCenter
            />
            {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.hasLeaseBreakPenalty != null) && (
              <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
            )}
          </div>
          <div>
            <Field
              component={TextField}
              name={`${rootPath}.leaseBreakPenalty`}
              label="Penalty Amount"
              value={leaseBreak.leaseBreakPenalty}
              onChange={handleChange}
              onBlur={handleBlur}
              allowDecimal={true}
              numeric
              prefix="$"
              disabled={!leaseBreak.hasLeaseBreakPenalty}
            />
            {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.leaseBreakPenalty && (
              <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
            )}
          </div>
        </div>
        <div className="row mb-1">
          <Field
            component={TextField}
            name={`${rootPath}.rentalLeaveDate`}
            label="Rental Leave Date"
            value={leaseBreak.rentalLeaveDate}
            onChange={handleChange}
            onBlur={handleBlur}
            type="date"
          />
        </div>
        <Hint>Ask for a copy of their lease, their letter and proof of payment.</Hint>
      </>
    );
  }
}

PropertyLeaseBreakForm.propTypes = {
  leaseBreak: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyLeaseBreakForm);
