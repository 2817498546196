import { Grid, MenuItem, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ChipSelect } from 'modules/intake/form';
import { metadataSort } from 'utilities/common';
import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class PropertyInspectionForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.inspection, nextProps.inspection);
  }

  render() {
    Log.trace('RENDER', 'PropertyInspectionForm');
    const {
      inspection,
      metadata: { houseInspectionTypes },
      rootPath,
      handleBlur,
      handleChange,
    } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} md={9}>
          <Field
            component={ChipSelect}
            name={`${rootPath}.homeInspectionsToOrder`}
            label="Inspections to Order"
            value={inspection.homeInspectionsToOrder}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            labelWidth={124}
          >
            {values(houseInspectionTypes).sort(metadataSort).map((houseInspectionType) => (
              <MenuItem key={houseInspectionType.id} value={houseInspectionType}>
                {houseInspectionType.description}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
    );
  }
}

PropertyInspectionForm.propTypes = {
  inspection: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyInspectionForm);
