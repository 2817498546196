import produce from 'immer';

import {
  GET_API_VERSION_FAILURE,
  GET_API_VERSION_SUCCESS,
  HIDE_SIDE_DRAWER,
  HIDE_TOAST_MESSAGE,
  SET_PAGE_TITLE,
  SHOW_SIDE_DRAWER,
  SHOW_TOAST_MESSAGE,
  TOAST_MESSAGE_SEVERITY_INFO,
} from './types';

const initialState = {
  title: null,
  shouldShowToast: false,
  toastMessage: '',
  userMustDismissToast: null,
  toastSeverity: TOAST_MESSAGE_SEVERITY_INFO,
  shouldShowSideDrawer: false,
  apiVersion: '',
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      draft.title = action.response;
      break;

    case SHOW_TOAST_MESSAGE:
      draft.shouldShowToast = true;
      draft.toastMessage = action.response.message;
      draft.userMustDismissToast = action.response.userMustDismiss;
      draft.toastSeverity = action.response.severity || TOAST_MESSAGE_SEVERITY_INFO;
      break;

    case HIDE_TOAST_MESSAGE:
      draft.shouldShowToast = false;
      break;

    case SHOW_SIDE_DRAWER:
      draft.shouldShowSideDrawer = true;
      break;

    case HIDE_SIDE_DRAWER:
      draft.shouldShowSideDrawer = false;
      break;

    case GET_API_VERSION_FAILURE:
      draft.apiVersion = '';
      break;

    case GET_API_VERSION_SUCCESS:
      draft.apiVersion = action.response;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
