import * as firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';

export const createImage = async (file) => {
  const storageRef = firebase.storage().ref();
  const imageId = uuidv4();
  const imageRef = storageRef.child(`/benefit_images/${imageId}`);
  await imageRef.put(file);
  return imageId;
};

export const deleteImage = (imageId) => {
  const storageRef = firebase.storage().ref();
  const imageRef = storageRef.child(`/benefit_images/${imageId}`);
  return imageRef.delete();
};

export const downloadImage = (imageId) => {
  const storageRef = firebase.storage().ref();
  const imageRef = storageRef.child(`/benefit_images/${imageId}`);
  return imageRef.getDownloadURL();
};
