
import { Button, FormLabel, Grid, MenuItem, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Field, FieldArray } from 'formik';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AutosaveDeleteButton, Select, TextField } from 'modules/intake/form';
import { showToast } from 'modules/layout/layout.actions';
import Log from 'utilities/log';

import { PropertyCreditBalance } from 'modules/intake/models';
import styles from 'modules/intake/styles';

const addCreditBalance = (arrayHelpers) => () => {
  arrayHelpers.push(new PropertyCreditBalance());
};

class PropertyCreditBalanceForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.balance, nextProps.balance) ||
      this.props.isSubmitting !== nextProps.isSubmitting;
  }

  render() {
    Log.trace('RENDER', 'PropertyCreditBalanceForm');
    const {
      balance,
      handleChange,
      handleBlur,
      rootPath,
      classes,
      metadata: { propertyCreditBalanceTypes },
      isSubmitting,
    } = this.props;

    return (
      <FieldArray
        name={`${rootPath}.propertyCreditBalances`}
        render={(arrayHelpers) => (
          <Paper classes={{ root: 'card-root' }}>
            <Grid container justify="space-between">
              <Grid item>
                <FormLabel>Mortgage/Lien Holders/Line of Credit</FormLabel>
              </Grid>
              <Grid item>
                <Button disabled={isSubmitting} onClick={addCreditBalance(arrayHelpers)}>Add</Button>
              </Grid>
            </Grid>
            {balance.propertyCreditBalances.map((propertyCreditBalance, index) => (
              <div className="input-row" key={index}>
                <div>
                  <Field
                    component={TextField}
                    name={`${rootPath}.propertyCreditBalances.${index}.name`}
                    label="Name"
                    value={propertyCreditBalance.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Field
                    component={Select}
                    name={`${rootPath}.propertyCreditBalances.${index}.propertyCreditBalanceTypeId`}
                    label="Type"
                    className={classes.inputRowSelect}
                    value={propertyCreditBalance.propertyCreditBalanceTypeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelWidth={30}
                  >
                    {values(propertyCreditBalanceTypes).map((balanceType) => (
                      <MenuItem key={balanceType.id} value={balanceType.id}>{balanceType.description}</MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={TextField}
                    name={`${rootPath}.propertyCreditBalances.${index}.balance`}
                    label="Balance"
                    value={propertyCreditBalance.balance}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    allowDecimal={true}
                    numeric
                    prefix="$"
                  />
                  <Field
                    component={TextField}
                    name={`${rootPath}.propertyCreditBalances.${index}.rate`}
                    label="Rate"
                    value={propertyCreditBalance.rate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    numeric
                    allowDecimal={true}
                    suffix="%"
                  />
                </div>
                <AutosaveDeleteButton disabled={isSubmitting} onClick={() => arrayHelpers.remove(index)} />
              </div>
            ))}
          </Paper>
        )}
      />
    );
  }
}

PropertyCreditBalanceForm.propTypes = {
  balance: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  connect(null, {
    showToast,
  }),
)(PropertyCreditBalanceForm);
