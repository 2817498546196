import { createSelector } from 'reselect';

const isLoadingBenefitsSelector = (state) => state.benefit.isLoadingBenefits;
const isLoadingBenefitSelector = (state) => state.benefit.isLoadingBenefit;
const isLoadingLookupTablesSelector = (state) => state.benefit.isLoadingLookupTables;
const isLoadingCostLookupApisSelector = (state) => state.benefit.isLoadingCostLookupApis;
const costLookupSelector = (state) => state.benefit.costLookupByTable;

export const isLoadingSelector = createSelector(
  isLoadingBenefitsSelector,
  isLoadingBenefitSelector,
  isLoadingLookupTablesSelector,
  isLoadingCostLookupApisSelector,
  (...isLoadingBooleans) => isLoadingBooleans.some((isLoading) => isLoading),
);

export const costLookupByTableSelector = createSelector(
  costLookupSelector,
  (costLookup) => {
    return (tableName) => costLookup[tableName];
  },
);