import { DateTimeFormatter, DayOfWeek, LocalDate } from 'js-joda';
import { Locale } from '@js-joda/locale_en-us';
import { Typography } from '@material-ui/core';
import { last, values } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import memoize from 'memoize-one';

const DATE_WIDTH = 44;
const MARKER_PADDING_X = 5;
const MARKER_HEIGHT = 4;
const MONTH_FORMATTER = DateTimeFormatter.ofPattern('MMMM y').withLocale(Locale.US);
const TODAY = LocalDate.now();

const DAY_LABELS = {
  [DayOfWeek.SUNDAY]: 'Sun',
  [DayOfWeek.MONDAY]: 'Mon',
  [DayOfWeek.TUESDAY]: 'Tue',
  [DayOfWeek.WEDNESDAY]: 'Wed',
  [DayOfWeek.THURSDAY]: 'Thu',
  [DayOfWeek.FRIDAY]: 'Fri',
  [DayOfWeek.SATURDAY]: 'Sat',
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: DATE_WIDTH * 7,
  },
  dayLabels: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      width: DATE_WIDTH,
      textAlign: 'center',
    },
  },
  dates: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  date: {
    minHeight: DATE_WIDTH,
    width: DATE_WIDTH,
    textAlign: 'center',
    paddingTop: 4,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  dateSpacer: {
    width: 0,
  },
  dateText: {
    zIndex: 1,
  },
  contrast: {
    color: theme.palette.common.white,
  },
  indicator: {
    borderRadius: DATE_WIDTH / 2,
    fontSize: 12,
    height: DATE_WIDTH,
    paddingTop: DATE_WIDTH / 2,
    position: 'absolute',
    width: DATE_WIDTH,
  },
  marker: {
    height: MARKER_HEIGHT,
    marginBottom: 2,
    marginLeft: MARKER_PADDING_X,
    marginRight: MARKER_PADDING_X,
    width: DATE_WIDTH - MARKER_PADDING_X * 2,
  },
  markerStart: {
    borderTopLeftRadius: MARKER_HEIGHT / 2,
    borderBottomLeftRadius: MARKER_HEIGHT / 2,
    marginLeft: MARKER_PADDING_X,
    marginRight: 0,
    width: DATE_WIDTH - MARKER_PADDING_X,
  },
  markerEnd: {
    borderTopRightRadius: MARKER_HEIGHT / 2,
    borderBottomRightRadius: MARKER_HEIGHT / 2,
    marginLeft: 0,
    marginRight: MARKER_PADDING_X,
    width: DATE_WIDTH - MARKER_PADDING_X,
  },
  markerMiddle: {
    marginLeft: 0,
    marginRight: 0,
    width: DATE_WIDTH,
  },
}));

const getDatesInMonth = (calendarMonth) => {
  let date = LocalDate.from(calendarMonth);
  const days = [];
  while (date.monthValue() === calendarMonth.monthValue()) {
    days.push(date);
    date = date.plusDays(1);
  }
  return days;
};

const parseMarkers = memoize((markers) => {
  return markers.map(({ color, dates }) => ({
    color,
    dates: dates.map(({ startDate, endDate }) => ({
      startDate: LocalDate.parse(startDate),
      endDate: endDate ? LocalDate.parse(endDate): LocalDate.parse(startDate),
    })),
  }));
});

const parseCallouts = (callouts) => callouts.map(({ color, date }) => ({ color, date: LocalDate.parse(date) }));

const reduceMarkers = memoize((markers) => {
  const reducedMarkers = [];

  markers.forEach(({ color, dates }) => {
    const markerGroup = [];
    dates.sort((a, b) => a.startDate.compareTo(b.startDate)).forEach(({ startDate, endDate }) => {
      const lastRange = last(markerGroup);
      if (!lastRange || startDate.isAfter(lastRange.endDate.plusDays(1))) {
        // create new group
        markerGroup.push({ startDate, endDate });
      } else if (startDate.equals(lastRange.endDate.plusDays(1)) || endDate.isAfter(lastRange.endDate)) {
        // extend existing group
        lastRange.endDate = endDate;
      }
    });

    reducedMarkers.push({
      color,
      dates: markerGroup,
    });
  });

  return reducedMarkers;
});

const getMarkers = (markers, date, classes) => {
  const markerTags = [];

  markers.forEach(({ color, dates }, i) => {
    let isStartDate = false;
    let isEndDate = false;
    let isMiddleDate = false;

    dates.forEach(({ startDate, endDate }) => {
      isStartDate = date.equals(startDate) || isStartDate;
      isEndDate = date.equals(endDate) || isEndDate;
      isMiddleDate = (date.isAfter(startDate) && date.isBefore(endDate)) || isMiddleDate;
    });

    if (isStartDate || isEndDate || isMiddleDate) {
      markerTags.push(
        {
          priority: (isEndDate && !isStartDate) || (isStartDate && !isEndDate) || isMiddleDate ? 0 : 1,
          tag: (
            <div
              key={i}
              style={{ backgroundColor: color }}
              className={classNames(classes.marker, {
                [classes.markerStart]: isStartDate && !isEndDate,
                [classes.markerEnd]: isEndDate && !isStartDate,
                [classes.markerMiddle]: isMiddleDate,
              })}
            />
          ),
        },
      );
    }
  });

  return markerTags.length > 0 ? markerTags.sort((a, b) => a.priority - b.priority).map(({ tag }) => tag): null;
};

const getCalloutIndicator = (callouts, date, classes) => {
  const callout = callouts.find((instance) => instance.date.equals(date));
  if (!callout) {
    return null;
  }

  const label = date.equals(TODAY) ? 'Today': DAY_LABELS[date.dayOfWeek()];
  return (
    <div className={classes.indicator} style={{ backgroundColor: callout.color }}>{label}</div>
  );
};

function Calendar(props) {
  const classes = useStyles();

  const { year, month, markers, callouts } = props;
  const parsedMarkers = parseMarkers(markers || []);
  const minMarkers = reduceMarkers(parsedMarkers);

  const parsedCallouts = parseCallouts(callouts || []);

  const calendarMonth = LocalDate.of(year, month, 1);
  const calendarDates = getDatesInMonth(calendarMonth);

  const startOffset = calendarMonth.dayOfWeek().value();

  return (
    <div className={classes.container}>
      <Typography gutterBottom variant="h6">
        {calendarMonth.format(MONTH_FORMATTER)}
      </Typography>
      <div className={classes.dayLabels}>
        {values(DAY_LABELS).map((label) => <Typography key={label} component="div" variant="caption">{label}</Typography>)}
      </div>
      <div className={classes.dates}>
        <div className={classes.dateSpacer} style={{ width: startOffset * DATE_WIDTH }} />
        {calendarDates.map((date) => {
          const calloutIndicator = getCalloutIndicator(parsedCallouts, date, classes);
          return (
            <div key={date.dayOfMonth()} className={classNames(classes.date, { [classes.contrast]: calloutIndicator })}>
              {calloutIndicator}
              <div className={classes.dateText}>
                {String(date.dayOfMonth()).padStart(2, '0')}
              </div>
              {getMarkers(minMarkers, date, classes)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

Calendar.propTypes = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  markers: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    dates: PropTypes.arrayOf(PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string,
    })),
  })),
  callouts: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  })),
};

Calendar.defaultProps = {
  primaryMarkers: [],
  secondaryMarkers: [],
  specialDates: [],
};

export default Calendar;
