import { boolean, object, string } from 'yup';

import { formatDateForInput, formatForWire, parseNumbers } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, NULLABLE_BOOLEAN, plusMinusFiveYearsDateSchema } from 'modules/common/constants';

export default class RelocationAllowanceBenefits {
  static schema = object().shape({
    hasRelocationAllowance: NULLABLE_BOOLEAN,
    didExplain: boolean(),
    relocationAmount: string().max(10, MAX_LENGTH_ERROR_MESSAGE),
    paymentDate: plusMinusFiveYearsDateSchema,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.hasRelocationAllowance = json.hasRelocationAllowance === null ? true : json.hasRelocationAllowance;
    this.didExplain = json.didExplain || false;
    this.relocationAmount = json.relocationAmount || '';
    this.paymentDate = formatDateForInput(json.paymentDate);
  }

  static fromWireData(wireData) {
    return new RelocationAllowanceBenefits(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      hasRelocationAllowance: this.hasRelocationAllowance,
      didExplain: this.didExplain,
      paymentDate: this.paymentDate,
      ...parseNumbers({
        relocationAmount: this.relocationAmount,
      }),
    });
  }
}
