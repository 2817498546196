import { boolean, object } from 'yup';

import { formatForWire, isNotDefined, parseNumbers } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, REQUIRED_STRING } from 'modules/common/constants';

export default class Vehicle {
  static schema = object().shape({
    make: REQUIRED_STRING.max(100, MAX_LENGTH_ERROR_MESSAGE),
    model: REQUIRED_STRING.max(100, MAX_LENGTH_ERROR_MESSAGE),
    year: REQUIRED_STRING.max(4, MAX_LENGTH_ERROR_MESSAGE),
    isInWorkingOrder: boolean(),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.id = json.id;
    this.make = json.make || '';
    this.model = json.model || '';
    this.year = json.year || '';
    this.isInWorkingOrder = isNotDefined(json.isInWorkingOrder) ? true: json.isInWorkingOrder;
  }

  static fromWireData(wireData) {
    return new Vehicle(wireData);
  }

  toWireData() {
    return formatForWire({
      id: this.id,
      make: this.make,
      model: this.model,
      ...parseNumbers({
        year: this.year,
      }),
      isInWorkingOrder: this.isInWorkingOrder,
    });
  }
}
