import { Button, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Dialog from 'modules/common/dialog.component';

function ImportIntakeRecordDialog(props) {
  const [authId, setAuthId] = useState('');
  const {
    onSubmit,
    onCancel,
    disabled,
    error,
    ...rest
  } = props;

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(authId);
  };

  const handleChange = (event) => {
    setAuthId(event.target.value);
  };

  return (
    <Dialog
      {...rest}
      title="Import Auth File"
      content={
        <form noValidate onSubmit={handleSubmit}>
          <Typography>Enter the Auth ID for the file you'd like to import.</Typography>
          <TextField
            autoFocus
            value={authId}
            onChange={handleChange}
            label="Auth ID"
            disabled={disabled}
          />
          {error && <Typography color="error">{error}</Typography>}
        </form>
      }
      actions={
        <>
          <Button disabled={disabled} onClick={onCancel} variant="outlined" color="primary">Cancel</Button>
          <Button disabled={disabled || !authId} onClick={handleSubmit} variant="contained" color="primary">Import</Button>
        </>
      }
    />
  );
}

ImportIntakeRecordDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

export default ImportIntakeRecordDialog;
