import { object } from 'yup';

import { formatForWire } from 'utilities/common';

import CandidateInternBenefits from './candidateInternBenefits';
import FinalMoveBenefits from './finalMoveBenefits';
import HhgBenefits from './hhgBenefits';
import HomesaleProcessBenefits from './homesaleProcessBenefits';
import HouseHuntingBenefits from './houseHuntingBenefits';
import MiscellaneousBenefits from './miscellaneousBenefits';
import RelocationAllowanceBenefits from './relocationAllowanceBenefits';
import SpousalAssistanceBenefits from './spousalAssistanceBenefits';
import TemporaryLivingBenefits from './temporaryLivingBenefits';

export default class Benefits {
  static schema = object().shape({
    relocationAllowance: RelocationAllowanceBenefits.schema,
    candidateIntern: CandidateInternBenefits.schema,
    homesaleProcess: HomesaleProcessBenefits.schema,
    householdGoods: HhgBenefits.schema,
    temporaryLiving: TemporaryLivingBenefits.schema,
    houseHunting: HouseHuntingBenefits.schema,
    finalMove: FinalMoveBenefits.schema,
    miscellaneous: MiscellaneousBenefits.schema,
    spousalAssistance: SpousalAssistanceBenefits.schema,
  });

  constructor(wireData, temporaryLiving, primaryInformation) {
    const json = wireData || {};
    this.relocationAllowance = new RelocationAllowanceBenefits(json.relocationAllowance);
    this.candidateIntern = new CandidateInternBenefits(json.candidateInternInfo, primaryInformation);
    this.homesaleProcess = new HomesaleProcessBenefits(json.homesaleProcess);
    this.householdGoods = new HhgBenefits(json.hhgBenefitsTimeline, temporaryLiving);
    this.houseHunting = new HouseHuntingBenefits(json.houseHunting);
    this.finalMove = new FinalMoveBenefits(json.finalMove);
    this.miscellaneous = new MiscellaneousBenefits({
      miscTimelineEntries: json.miscTimelineEntries,
    });
    this.temporaryLiving = new TemporaryLivingBenefits({
      estimatedStartDate: json.temporaryLivingInfo.estimatedStartDate,
      estimatedEndDate: json.temporaryLivingInfo.estimatedEndDate,
      costCap: json.temporaryLivingInfo.costCap,
    });
    this.spousalAssistance = new SpousalAssistanceBenefits(json.spousalAssistance);
  }

  static fromWireData(wireData, temporaryLiving, primaryInformation) {
    return new Benefits(wireData, temporaryLiving, primaryInformation);
  }

  static generateErrors(errors) {
    if (!errors) {
      return null;
    }

    const {
      finalMove,
      homesaleProcess,
      houseHunting,
      householdGoods,
      relocationAllowance,
      spousalAssistance,
      temporaryLivingTimeline,
      candidateInternInfo,
    } = errors;
    return {
      finalMove: FinalMoveBenefits.generateErrors(finalMove),
      homesaleProcess: HomesaleProcessBenefits.generateErrors(homesaleProcess),
      houseHunting: HouseHuntingBenefits.generateErrors(houseHunting),
      householdGoods: HhgBenefits.generateErrors(householdGoods),
      relocationAllowance: RelocationAllowanceBenefits.generateErrors(relocationAllowance),
      spousalAssistance: SpousalAssistanceBenefits.generateErrors(spousalAssistance),
      temporaryLiving: TemporaryLivingBenefits.generateErrors(temporaryLivingTimeline),
      candidateIntern: CandidateInternBenefits.generateErrors(candidateInternInfo),
    };
  }

  toWireData() {
    return formatForWire({
      relocationAllowance: this.relocationAllowance.toWireData(),
      candidateInternInfo: this.candidateIntern.toWireData(),
      homesaleProcess: this.homesaleProcess.toWireData(),
      hhgBenefitsTimeline: this.householdGoods.toWireData(),
      houseHunting: this.houseHunting.toWireData(),
      finalMove: this.finalMove.toWireData(),
      miscellaneous: this.miscellaneous.toWireData(),
      temporaryLiving: this.temporaryLiving.toWireData(),
      spousalAssistance: this.spousalAssistance.toWireData(),
    });
  }
}
