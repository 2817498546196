import { boolean, object } from 'yup';

import { formatForWire, isNotDefined } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, REQUIRED_STRING } from 'modules/common/constants';

export default class Email {
  static schema = object().shape({
    emailAddress: REQUIRED_STRING.max(300, MAX_LENGTH_ERROR_MESSAGE),
    emailTypeId: REQUIRED_STRING,
    contactInfoOwnerTypeId: REQUIRED_STRING,
    isPrimary: boolean(),
  });

  constructor(wireData) {
    const json = wireData || {};

    this.id = json.id;
    this.emailAddress = json.emailAddress || '';
    this.emailTypeId = isNotDefined(json.emailTypeId) ? '': json.emailTypeId;
    this.contactInfoOwnerTypeId = isNotDefined(json.contactInfoOwnerTypeId) ? '': json.contactInfoOwnerTypeId;
    this.isPrimary = !!json.isPrimary;
  }

  static fromWireData(wireData) {
    return new Email(wireData);
  }

  toWireData() {
    return formatForWire({
      id: this.id,
      emailAddress: this.emailAddress,
      emailTypeId: this.emailTypeId,
      contactInfoOwnerTypeId: this.contactInfoOwnerTypeId,
      isPrimary: this.isPrimary,
    });
  }
}
