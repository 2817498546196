import * as Sentry from '@sentry/browser';
import { Box, Card, CardActionArea, CardContent, CardMedia, Chip, Divider, Fab, Switch, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { downloadImage } from 'utilities/firebase';
import { formatLocalDateLong } from 'utilities/common';
import { getVersionFromSummary, mergeOptionsWithOptionDetails } from './util';
import { showToast } from 'modules/layout/layout.actions';
import Spinner from 'modules/common/spinner.component';

const useStyles = makeStyles((theme) => (
  {
    card: {
      width: 400,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipClickable: {
      cursor: 'pointer',
    },
    iconOverlayImg: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    spacingX: {
      marginRight: theme.spacing(1),
    },
    footer: {
      height: 15,
      marginBottom: theme.spacing(2),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
  }
));

const BenefitCard = (props) => {
  const { summary, onEdit, onToggleOption, onToggleEnabled } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const theme = useTheme();
  const classes = useStyles();
  const version = getVersionFromSummary(summary);

  const [enabled, setEnabled] = useState(summary.enabled);
  const [options, setOptions] = useState(mergeOptionsWithOptionDetails(summary.options, version.optionDetails));
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const toggleBenefitOption = (opt, enabledNewValue) => {
    opt.enabled = enabledNewValue;
    setOptions([...options]);
    const errorCallback = () => {
      props.showToast(`Failed to ${(enabledNewValue ? 'enable ' : 'disable ')}${opt.name} option`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      opt.enabled = !enabledNewValue;
      // react doesn't know to re-render this option unless you cloneDeep here
      setOptions(cloneDeep(options));
    };
    onToggleOption(opt.id, enabledNewValue, errorCallback);
  };

  const toggleBenefit = () => {
    const enabledNewValue = !enabled;
    setEnabled(enabledNewValue);
    const optionsToggled = {};
    setOptions(options.map((opt) => {
      if (opt.enabled !== enabledNewValue) {
        opt.enabled = enabledNewValue;
        optionsToggled[opt.id] = opt;
      }
      return opt;
    }));
    const errorCallback = () => {
      props.showToast(`Failed to ${(enabledNewValue ? 'enable ' : 'disable ')}${version.name}`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setEnabled(!enabledNewValue);
      setOptions(options.map((opt) => {
        if (optionsToggled[opt.id]) {
          opt.enabled = !enabledNewValue;
        }
        return opt;
      }));
    };
    onToggleEnabled(enabledNewValue, errorCallback);
  };

  const renderOption = (opt) => {
    const toggleable = summary.enabled;
    if (toggleable) {
      return (
        <Chip
          key={opt.id}
          className={classNames(classes.chip, classes.chipClickable)}
          color={opt.enabled ? 'primary' : 'default'}
          label={opt.name}
          onClick={() => toggleBenefitOption(opt, !opt.enabled)}
        />
      );
    } else {
      return (
        <Tooltip title={`To enable this option, ${version.isPublished ? 'enable' : 'publish' } the benefit.`} key={opt.id}>
          <Chip
            className={classes.chip}
            label={opt.name}
          />
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (version.imageId) {
          setImageUrl(await downloadImage(version.imageId));
        }
      } catch (e) {
        setImageLoadFailed(true);
        Sentry.captureException(new Error(`Download Image Error: ${JSON.stringify(e)}`));
      }
    })();
  }, [version.imageId]);

  return (
    <Card className={classes.card}>
      <Box>
        {!version.imageId &&
          <div className="relative">
            <Fab className={classes.iconOverlayImg} color="primary" size="medium" onClick={() => onEdit(version)}>
              <FontAwesomeIcon size="2x" icon={['fas', 'pen']} color={theme.palette.common.white} />
            </Fab>
          </div>
        }
        {version.imageId && !imageUrl && !imageLoadFailed && (
          <div className="center-container">
            <Spinner />
          </div>
        )}
        {imageUrl &&
          <CardActionArea onClick={() => onEdit(version)} className="relative">
            <Fab className={classes.iconOverlayImg} color="primary" size="medium" >
              <FontAwesomeIcon size="2x" icon={['fas', 'pen']} color={theme.palette.common.white} />
            </Fab>
            <CardMedia image={imageUrl} className="media" />
          </CardActionArea>
        }
        <CardContent>
          <div className="row justify-space-between align-center">
            <Typography variant="h5">
              {version.name}
            </Typography>
            {summary.mostRecentPublish &&
              <Switch
                checked={enabled}
                onChange={toggleBenefit}
                value={enabled}
                color={enabled ? 'primary' : 'default'}
              />
            }
            {imageLoadFailed &&
              <Fab color="primary" size="medium" onClick={() => onEdit(version)}>
                <FontAwesomeIcon size="2x" icon={['fas', 'pen']} color={theme.palette.common.white} />
              </Fab>
            }
          </div>
        </CardContent>
        <Divider variant="middle" />
        <CardContent>
          {
            <>
              <Typography variant="subtitle1" className={classes.mb2}>
                Benefit Option(s)
              </Typography>
              <div className={classes.mb2}>
                {options.map(renderOption)}
              </div>
            </>
          }
        </CardContent>
      </Box>
      <Box>
        <Box py={2}>
          <Divider />
        </Box>
        <div className={classNames('row-no-grow', 'align-center', 'mx-2', classes.footer)}>
          <FontAwesomeIcon
            icon={['fas', 'circle']}
            color={summary.mostRecentPublish ? theme.palette.secondary.main : theme.palette.warning.main}
            className={classes.spacingX}
          />
          <Typography color="textSecondary" variant="subtitle1" className={classes.spacingX}>
            {summary.mostRecentPublish ? 'Published' : 'Unpublished'}
          </Typography>
          {summary.mostRecentPublish &&
            <Typography color="textSecondary" variant="subtitle2" className={classes.spacingX}>
              {formatLocalDateLong(version.publishedDate.split('T')[0])}
            </Typography>
          }
          {summary.draft && summary.mostRecentPublish &&
            <>
              <Divider orientation="vertical" className={classes.spacingX} />
              <Typography color="textSecondary" variant="subtitle1">
                Active Draft
              </Typography>
            </>
          }
        </div>
      </Box>
    </Card>
  );
};

BenefitCard.propTypes = {
  summary: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onToggleOption: PropTypes.func.isRequired,
  onToggleEnabled: PropTypes.func.isRequired,

  showToast: PropTypes.func.isRequired,
};

export default connect(
  null,
  { showToast },
)(BenefitCard);
