import { Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Dialog from './dialog.component';

function AddNoteDialog(props) {
  const [note, setNote] = useState('');
  const {
    onSubmit,
    onDiscard,
    maxLength,
    disabled,
    ...rest
  } = props;

  const handleSubmit = () => onSubmit(note);

  const handleChange = (event) => {
    setNote(event.target.value);
  };

  const canSave = !disabled && note.trim().length > 0;

  return (
    <Dialog
      {...rest}
      title="Add Note"
      content={
        <TextField
          autoFocus
          multiline
          rows={3}
          value={note}
          onChange={handleChange}
          fullWidth
          inputProps={{
            maxLength: maxLength ? maxLength : undefined,
          }}
          helperText={maxLength ? `${note.length} / ${maxLength}` : undefined}
          disabled={disabled}
        />
      }
      actions={
        <>
          <Button disabled={disabled} onClick={onDiscard} variant="outlined" color="primary">Discard</Button>
          <Button disabled={!canSave} onClick={handleSubmit} variant="contained" color="primary">Save</Button>
        </>
      }
    />
  );
}

AddNoteDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
};

export default AddNoteDialog;
