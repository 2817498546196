
import {  Typography } from '@material-ui/core';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import PropTypes from 'prop-types';
import React from 'react';

export const CONFLICT_PUBLISHED_DRAFT_MESSAGE =
    'Someone else has already published this draft. ' +
    'After closing this dialog, make sure to note any unsaved changes you have made. ' +
    'Then you can safely refresh the page and create a new draft.';

export const CONFLICT_CHANGED_PUBLISH_MESSAGE =
    'Someone else has changed this draft since you started editing it. ' +
    'After closing this dialog, make sure to note any unsaved changes you have made. ' +
    'Then you can safely refresh the page and edit the latest draft.';

export const CONFLICT_CHANGED_DRAFT_MESSAGE =
    'Someone else has changed this draft since you started editing it. ' +
    'After closing this dialog, make sure to note any unsaved changes you have made. ' +
    'Then you can safely refresh the page and edit the latest draft.';

export const CONFLICT_DRAFT_EXISTS_MESSAGE = 'Draft already exists for this benefit. '
  + 'If you want to edit the existing draft, refresh the page';

export const CONFLICT_BENEFIT_NOT_IN_USE_MESSAGE = 'This benefit version is no longer in use. Returning to benefits list page...';

export const CONFLICT_DRAFT_DELETE_PUBLISHED_MESSAGE = 'Draft has been published and therefore cannot be deleted. Refresh the page to see the latest.';

export const CONFLICT_DRAFT_DELETED_MESSAGE =
         'Someone else has deleted this draft. ' +
         'After closing this dialog, make sure to note any unsaved changes you have made. ' +
         'Then you can safely refresh the page and create a new draft.';

const BenefitConflictDialog = (props) => {
  const { open, message, onConfirm } = props;

  return (
    <ConfirmationDialog onConfirm={onConfirm} hideCancel open={open} title="Editing Conflict" confirmLabel="Ok">
      <Typography>{message}</Typography>
    </ConfirmationDialog>
  );
};

BenefitConflictDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default BenefitConflictDialog;
