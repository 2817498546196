import { Grid, withStyles } from '@material-ui/core';
import { TextField, ToggleSelect } from 'modules/intake/form';
import { YES_NO_OPTIONS } from 'utilities/common';
import Hint from 'modules/common/hint.component';

import { Field } from 'formik';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class PropertyNotesForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.notes, nextProps.notes);
  }

  render() {
    Log.trace('RENDER', 'PropertyNotesForm');
    const {
      notes,
      handleChange,
      handleBlur,
      rootPath,
    } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} md={9}>
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasWarranties`}
            label="Any Warranties on Home (i.e., builder, roof, HVAC, etc.)"
            value={notes.hasWarranties}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
          />
          {notes.hasWarranties &&
            <Hint>Inform and supply listing agent with available documents if active warranty is transferable</Hint>
          }
        </Grid>

        <Grid item xs={12} md={9}>
          <Field
            component={TextField}
            name={`${rootPath}.capitalImprovements`}
            label="Capital Improvements/Remodeling Since Purchase"
            value={notes.capitalImprovements}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Field
            component={TextField}
            name={`${rootPath}.easements`}
            label="Easements"
            value={notes.easements}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Field
            component={TextField}
            name={`${rootPath}.specialAssessments`}
            label="Special Assessments"
            value={notes.specialAssessments}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Field
            component={TextField}
            name={`${rootPath}.knownDefects`}
            label="Known Defects"
            value={notes.knownDefects}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            helperText="Ex. Defective Drywall, Synthetic Stucco"
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Field
            component={TextField}
            name={`${rootPath}.outstandingInsuranceClaims`}
            label="Any Outstanding Insurance Claims"
            value={notes.outstandingInsuranceClaims}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }
}

PropertyNotesForm.propTypes = {
  notes: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyNotesForm);
