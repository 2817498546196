import { Box, Button, Grid, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as LayoutActions from 'modules/layout/layout.actions';
import * as SelfServiceActions from '../selfService.actions';
import { ADD_AUTH_POLICY_BUDGET_ADDITION_FAILURE } from '../types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { formatDate, getNow } from 'utilities/common';
import { getDelimitedValue } from 'utilities/currencyFormatter';
import { useDispatch } from 'react-redux';
import Dialog from 'modules/common/dialog.component';
import TwoDecimalFormat from 'modules/common/twoDecimalFormat.component';

const useStyles = makeStyles((theme) => ({
  row: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  button: {
    width: 100,
  },
}));

const AddPointsRow = (props) => {
  const { addition, idx, getBudgetTotal, isEditable, onAdditionChange, onNotesChange } = props;
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.row}>
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Typography variant="body1" color="textSecondary">{addition.addedBy.moveTrackUserName}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" color="textSecondary">{addition.createdDate ? formatDate(addition.createdDate) : getNow()}</Typography>
        </Grid>
        <Grid item xs={4}>
          {isEditable &&
            <TextField
              value={addition.notes || ''}
              onChange={(e) => onNotesChange(e.target.value)}
              margin="none"
            />
          }
          {!isEditable && <Typography variant="body1" color="textSecondary">{addition.notes}</Typography>}
        </Grid>
        <Grid item xs={2}>
          {isEditable &&
            <TextField
              value={addition.addition || ''}
              onChange={(value) => onAdditionChange(value)}
              InputProps={{ inputComponent: TwoDecimalFormat }}
              margin="none"
            />
          }
          {!isEditable && <Typography variant="body1">{addition.addition}</Typography> }
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{getDelimitedValue(getBudgetTotal(idx), 0, 3)}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const AddPointsDialog = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [budgetAdditions, setBudgetAdditions] = useState([]);

  const save = async () => {
    const action = await dispatch(SelfServiceActions.addBudgetAddition(props.authId, budgetAdditions[0]));
    if (action.type === ADD_AUTH_POLICY_BUDGET_ADDITION_FAILURE) {
      dispatch(LayoutActions.showToast('Failed to add to the budget.', { severity: TOAST_MESSAGE_SEVERITY_ERROR }));
    } else {
      props.onClose();
    }
  };

  const onClose = () => {
    budgetAdditions[0].addition = '';
    budgetAdditions[0].notes = '';
    props.onClose();
  };

  useEffect(() => {
    const sortedBudgetAdditions = [...props.authDetails.policy.budgetAdditions].sort((budgetAddition1, budgetAddition2) => {
      return new Date(budgetAddition2.createdDate) - new Date(budgetAddition1.createdDate);
    });
    sortedBudgetAdditions.unshift({ id: uuidv4(), addedBy: { moveTrackUserName: props.userProfile.moveTrackUsername } });
    sortedBudgetAdditions.push({ id: uuidv4(), addedBy: { moveTrackUserName: 'Auth' }, createdDate: props.authDetails.authorizationSummary.authorizationDate, notes: 'Budget Created' });
    setBudgetAdditions(sortedBudgetAdditions);
  }, [props.authDetails, props.userProfile]);

  const getBudgetTotal = (idx) => {
    let total = props.authDetails.policy.originalBudget;
    for (let i = idx; i < budgetAdditions.length; i++) {
      if (budgetAdditions[i].addition) {
        total += budgetAdditions[i].addition;
      }
    }
    return total;
  };

  return (
    <Dialog
      open={props.open}
      title="Policy Exceptions"
      showTitleClose={true}
      maxWidth="lg"
      onClose={onClose}
      actions={
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <Button size="large" className={classes.button} variant="outlined" color="primary" onClick={onClose}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button size="large" className={classes.button} variant="contained" color="primary" disabled={!budgetAdditions[0] || !budgetAdditions[0].addition} onClick={save}>Save</Button>
          </Grid>
        </Grid>
      }
      content={
        <Box width={900} mt={2}>
          <Typography variant="h6" gutterBottom>History</Typography>
          <Box maxHeight={250} className="overflow-y" py={2} px={1}>
            <Grid container spacing={0} className={classes.header}>
              <Grid item xs={2}>
                <Typography variant="body1" color="textSecondary">Update By</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" color="textSecondary">Update Date</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" color="textSecondary">Description</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" color="textSecondary">Points Added</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" color="textSecondary">Budget Total</Typography>
              </Grid>
            </Grid>
            {budgetAdditions.map((addition, idx) => {
              return (
                <AddPointsRow
                  key={addition.id}
                  addition={addition}
                  idx={idx}
                  getBudgetTotal={getBudgetTotal}
                  isEditable={idx === 0}
                  onAdditionChange={(value) => {
                    budgetAdditions[0].addition = value;
                    setBudgetAdditions([...budgetAdditions]);
                  }}
                  onNotesChange={(value) => {
                    budgetAdditions[0].notes = value;
                    setBudgetAdditions([...budgetAdditions]);
                  }}
                />
              );
            })}
          </Box>
        </Box>
      }
    />
  );
};

AddPointsRow.propTypes = {
  addition: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  getBudgetTotal: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  onNotesChange: PropTypes.func,
  onAdditionChange: PropTypes.func,
};

AddPointsDialog.propTypes = {
  authDetails: PropTypes.object.isRequired,
  authId: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddPointsDialog;
