import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

function Hint({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FontAwesomeIcon className={classes.icon} icon={['far', 'lightbulb-on']} size="lg" />
      <div>{children}</div>
    </div>
  );
}

Hint.propTypes = {
  children: PropTypes.node,
};

export default Hint;
