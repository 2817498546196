import { Grid, MenuItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Field } from 'formik';
import { compose } from 'recompose';
import { isEqual, values } from 'lodash';
import Hint from 'modules/common/hint.component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Checkbox, ChipSelect, TextField } from 'modules/intake/form';
import { TemporaryLivingBenefits, TemporaryLivingInformation } from 'modules/intake/models';
import { isNotDefined } from 'utilities/common';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import ExpansionPanel from 'modules/common/expansionPanel.component';
import ExpansionPanelDetails from 'modules/common/expansionPanelDetails.component';
import ExpansionPanelSummary from 'modules/common/expansionPanelSummary.component';
import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class TemporaryLivingForm extends Component {
  state = {
    showConfirmation: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.temporaryLiving !==  nextProps.temporaryLiving ||
      !isEqual(this.state, nextState);
  }

  renderHeader() {
    const { moveTypeId, classes, temporaryLiving, handleChange, handleBlur } = this.props;

    return (
      <div className="row align-center">
        <Typography variant="h3">Temporary Living</Typography>
        <Field
          component={Checkbox}
          className={classes.headerToggle}
          name="temporaryLiving.isTemporaryLivingServiceInitiated"
          value={temporaryLiving.isTemporaryLivingServiceInitiated}
          onChange={(event) => {
            // this value is the current state, not what it will change to if we allow it
            if (event.target.value === 'checked') {
              this.setState({ showConfirmation: true });
            } else {
              if (!temporaryLiving.isTemporaryLivingServiceInitiated && (moveTypeId === 0 || moveTypeId === 7)){
                this.setState({ showChangeMoveType: true });
              } else {
                handleChange(event);
              }
            }
          }}
          onBlur={handleBlur}
          label="Initiate Service"
        />       
      </div>
    );

  }

  handleDisableService = () => {
    // need to remember old id so backend doesn't break
    const {
      temporaryLiving: { id },
    } = this.props;
    this.props.setFieldValue('temporaryLiving', new TemporaryLivingInformation({ id }), false);
    this.props.setFieldValue('benefits.temporaryLiving', new TemporaryLivingBenefits(), false);
    this.setState({ showConfirmation: false });
  };

  render() {
    Log.trace('RENDER', 'TemporaryLivingForm');
    const { temporaryLiving, contacts, pets, handleChange, handleBlur } = this.props;
    const { showConfirmation, showChangeMoveType } = this.state;

    return (
      <div className="form-group">
        <ExpansionPanel expanded={temporaryLiving.isTemporaryLivingServiceInitiated}>
          <ExpansionPanelSummary>{this.renderHeader()}</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <Field
                  component={ChipSelect}
                  name="temporaryLiving.occupants"
                  label="Occupants in Temp Living"
                  value={temporaryLiving.occupants}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  labelAccessor={(value) => (value.firstName ? `${value.firstName} ${value.lastName}` : value.petName)}
                  labelWidth={160}
                  style={{ marginBottom: 5 }}
                >
                  {values(contacts)
                    .filter((instance) => !isNotDefined(instance.id))
                    .map((contact) => (
                      <MenuItem key={contact.id} value={contact}>
                        {`${contact.firstName} ${contact.lastName}`}
                      </MenuItem>
                    ))}
                  {values(pets)
                    .filter((instance) => !isNotDefined(instance.id))
                    .map((pet) => (
                      <MenuItem key={pet.id} value={pet}>
                        {pet.petName}
                      </MenuItem>
                    ))}
                </Field>
                <Hint>The Transferee is already included</Hint>
              </Grid>
              <Grid item xs={12} md={9}>
                <div className="row mb-1">
                  <Field
                    component={TextField}
                    name="temporaryLiving.specialRequestsPreferences"
                    label="Special Requests/Preferences"
                    value={temporaryLiving.specialRequestsPreferences}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </div>
                <div className="row mb-1">
                  <Field
                    component={TextField}
                    name="temporaryLiving.bedroomsPreferred"
                    label="Bedroom Preference"
                    value={temporaryLiving.bedroomsPreferred}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    numeric
                  />
                </div>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ConfirmationDialog
          open={showConfirmation}
          title="Disable Service?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleDisableService}
          onCancel={() => this.setState({ showConfirmation: false })}
        >
          <Typography>
            If you disable this service, you will lose all data entered in this section and any timeline entries related to this service. <br /><br />Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
        <ConfirmationDialog
          open={showChangeMoveType}
          title="Change Move Type?"
          confirmLabel="Change Move Type"
          cancelLabel="Cancel"
          onConfirm={() => {window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); this.setState({ showChangeMoveType: false });}}
          onCancel={() => this.setState({ showChangeMoveType: false })}
        >
          <Typography>
            In order to initiate Destination Services, please change the move type from either Candidate or Intern.{' '}
            <br />
            <br />
            Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

TemporaryLivingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  temporaryLiving: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  pets: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(TemporaryLivingForm);
