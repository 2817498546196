import { MenuItem, Paper, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Select, TextField, ToggleSelect } from 'modules/intake/form';
import { YES_NO_OPTIONS } from 'utilities/common';
import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class PropertyHoaForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.hoa, nextProps.hoa);
  }

  render() {
    Log.trace('RENDER', 'PropertyHoaForm');
    const {
      hoa,
      handleChange,
      handleBlur,
      rootPath,
      classes,
      metadata: { hoaPaymentScheduleTypes },
    } = this.props;

    return (
      <Paper classes={{ root: 'card-root' }}>
        <Field
          component={ToggleSelect}
          name={`${rootPath}.hasHoa`}
          label="Home Owners Association?"
          value={hoa.hasHoa}
          onChange={handleChange}
          onBlur={handleBlur}
          options={YES_NO_OPTIONS}
        />
        <div>
          <Field
            component={Select}
            name={`${rootPath}.hoaPaymentScheduleTypeId`}
            label="HOA Payment Schedule"
            className={classes.hoaSelect}
            value={hoa.hoaPaymentScheduleTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!hoa.hasHoa}
            labelWidth={146}
          >
            {values(hoaPaymentScheduleTypes).map((hoaPaymentScheduleType) => (
              <MenuItem key={hoaPaymentScheduleType.id} value={hoaPaymentScheduleType.id}>{hoaPaymentScheduleType.description}</MenuItem>
            ))}
          </Field>
          <Field
            component={TextField}
            name={`${rootPath}.hoaPaymentAmount`}
            label="HOA Payment Amount"
            value={hoa.hoaPaymentAmount}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!hoa.hasHoa}
            allowDecimal={true}
            numeric
            prefix="$"
          />
        </div>
      </Paper>
    );
  }
}

PropertyHoaForm.propTypes = {
  hoa: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyHoaForm);
