import { object } from 'yup';

import { formatDateForInput, formatForWire } from 'utilities/common';

import { REQUIRED_STRING, plusMinusFiveYearsDateSchema } from 'modules/common/constants';

export default class TimelineEntry {
  static schema = object().shape({
    description: REQUIRED_STRING,
    startDate: REQUIRED_STRING.concat(plusMinusFiveYearsDateSchema),
    endDate: plusMinusFiveYearsDateSchema,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.id = json.id;
    this.description = json.description || '';
    this.startDate = formatDateForInput(json.startDate);
    this.endDate = formatDateForInput(json.endDate);
  }

  static fromWireData(wireData) {
    return new TimelineEntry(wireData);
  }

  toWireData() {
    return formatForWire({
      id: this.id,
      description: this.description,
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }
}
