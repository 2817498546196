import * as Sentry from '@sentry/browser';
import { CssBaseline, MuiThemeProvider, Typography, createMuiTheme } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';

import Callback from 'modules/auth/callback.container';
import Login from 'modules/auth/login.container';
import auth from 'auth/auth';
import visitor from 'utilities/visitor';

import AuthenticatedRoutes from './authenticatedRoutes.layout';
import ErrorView from './error.component';
import Toast from './toast.container';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  overrides: {
    MuiTypography: {
      h4: {
        color: COLOR_PRIMARY.dark,
        textTransform: 'uppercase',
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: 0,
        marginRight: 16,
      },
      labelPlacementTop: {
        alignItems: 'flex-start',
        marginLeft: 0,
      },
    },
    MuiCardHeader: {
      title: {
        fontSize: '1.2rem',
      },
    },
  },
  palette: {
    primary: COLOR_PRIMARY,
    secondary: COLOR_SECONDARY,
    warning: COLOR_WARNING,
    action: {
      imageMaskOpacity: 0.3,
    },
  },
  props: {
    MuiFormControl: {
      margin: 'normal',
    },
    MuiGrid: {
      spacing: 1,
    },
    MuiTextField: {
      margin: 'normal',
    },
  },
  typography: {
    fontSize: 12,
    h3: {
      fontSize: '1.75rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
  },
});

const getFullPagePath = (location) => {
  const { pathname, search } = location;
  return pathname + search;
};

class MainContainer extends Component {
  static getDerivedStateFromError(error) {
    return { error };
  }

  state = {
    error: null,
  };

  componentDidUpdate(prevProps) {
    const previousPath = getFullPagePath(prevProps.location);
    const path = getFullPagePath(this.props.location);
    if (path !== previousPath) {
      visitor.pageview(path).send();
      window.localStorage.setItem('redirectAfterLogin', path);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  retryError = () => {
    this.setState({ error: null });
    this.props.history.replace('/login', {
      from: this.props.location,
    });
  };

  render() {
    const { error } = this.state;
    const { isAuthenticated } = auth;
    const showAuthenticatedRoutes = isAuthenticated();
    const { location } = this.props;

    if (error) {
      return <ErrorView error={error} onRetry={this.retryError} />;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {/* Apply font styling to all subviews */}
        <Typography component="div" className="full-height">
          {showAuthenticatedRoutes && <AuthenticatedRoutes />}
          <Switch>
            <Route exact path="/callback" component={Callback} />
            <Route exact path="/login" component={Login} />
            {!showAuthenticatedRoutes &&
              <Redirect
                from="*"
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            }
          </Switch>
          <Toast />
        </Typography>
      </MuiThemeProvider>
    );
  }
}

MainContainer.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default MainContainer;
