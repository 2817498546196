import { Button, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import visitor from 'utilities/visitor';

class ErrorView extends Component {

  componentDidMount() {
    const { error } = this.props;
    if (error && typeof error.toString === 'function') {
      visitor.exception(error.toString()).send();
    }
  }

  render() {
    const { onRetry } = this.props;
    return (
      <div className="d-flex full-height full-width">
        <div className="center-container">
          <Typography variant="h3" color="error" gutterBottom>Something went wrong</Typography>
          { typeof onRetry === 'function' &&
            <Button size="large" onClick={onRetry} variant="contained" color="primary">Try Again</Button>
          }
        </div>
      </div>
    );
  }
}

ErrorView.propTypes = {
  error: PropTypes.object,
  onRetry: PropTypes.func,
};

export default ErrorView;
