import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const SIZE = 30;

const useStyles = makeStyles((theme) => ({
  indicator: {
    borderRadius: SIZE / 2,
    height: SIZE,
    width: SIZE,
    marginTop: 26,
  },
}));

function ColorIndicator(props) {
  const classes = useStyles();

  return (
    <div className={classes.indicator} style={{ backgroundColor: props.color }} />
  );
}

ColorIndicator.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ColorIndicator;
