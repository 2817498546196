import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { AuthorizationComment } from 'modules/intake/models';
import Dialog from 'modules/common/dialog.component';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    marginRight: theme.spacing(2),
  },
  checkbox: {
    minWidth: 130,
  },
}));

function AddCommentDialog(props) {
  const classes = useStyles();
  const [comment, setComment] = useState(new AuthorizationComment(null, props.serviceTypes, props.regardingTypes));
  const {
    onSubmit,
    onDiscard,
    maxLength,
    disabled,
    serviceTypes,
    regardingTypes,
    ...rest
  } = props;

  const handleSubmit = () => onSubmit(comment);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setComment((oldComment) => {
      return new AuthorizationComment({
        ...oldComment,
        [name]: value,
      }, serviceTypes, regardingTypes);
    });
  };

  const handleToggle = (event) => {
    const { name, checked } = event.target;
    setComment((oldComment) => {
      return new AuthorizationComment({
        ...oldComment,
        [name]: checked,
      }, serviceTypes, regardingTypes);
    });
  };

  return (
    <Dialog
      {...rest}
      title="Add Comment"
      content={
        <FormGroup>
          <TextField
            autoFocus
            multiline
            rows={10}
            value={comment.comment}
            onChange={handleChange}
            fullWidth
            inputProps={{
              name: 'comment',
              maxLength: maxLength ? maxLength : undefined,
            }}
            helperText={maxLength ? `${comment.comment.length} / ${maxLength}` : undefined}
            disabled={disabled}
          />
          <div className="row">
            <FormControl className={classes.formControl} margin="none">
              <InputLabel>Service Type</InputLabel>
              <Select
                value={comment.serviceTypeId}
                onChange={handleChange}
                inputProps={{
                  name: 'serviceTypeId',
                }}
              >
                {serviceTypes.map((serviceType) => (
                  <MenuItem key={serviceType.id} value={serviceType.id}>{serviceType.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} margin="none">
              <InputLabel>Regarding Type</InputLabel>
              <Select
                value={comment.regardingTypeId}
                onChange={handleChange}
                inputProps={{
                  name: 'regardingTypeId',
                }}
              >
                {regardingTypes.map((regardingType) => (
                  <MenuItem key={regardingType.id} value={regardingType.id}>{regardingType.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={comment.isWebViewable}
                  onChange={handleToggle}
                  value="isWebViewable"
                  inputProps={{
                    name: 'isWebViewable',
                  }}
                />
              }
              label="Web Viewable"
            />
          </div>
        </FormGroup>
      }
      actions={
        <>
          <Button disabled={disabled} onClick={onDiscard} variant="outlined" color="primary">Discard</Button>
          <Button
            disabled={disabled || comment.serviceType === undefined || !comment.comment}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </>
      }
    />
  );
}

AddCommentDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  serviceTypes: PropTypes.array.isRequired,
  regardingTypes: PropTypes.array.isRequired,
};

export default AddCommentDialog;
