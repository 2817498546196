import {
  Button,
  FormLabel,
  Grid,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Field, FieldArray } from 'formik';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AutosaveDeleteButton, TextField } from 'modules/intake/form';
import { TimelineEntry } from 'modules/intake/models';

import ColorIndicator from './colorIndicator.component';
import Colors from './colors';

const styles = () => ({

});

const addDateRange = (arrayHelpers) => () => {
  arrayHelpers.push(new TimelineEntry());
};

class MiscellaneousForm extends PureComponent {
  render() {
    const { miscellaneous, handleBlur, handleChange, rootPath, isSubmitting } = this.props;

    return (
      <div className="form-group">
        <Typography gutterBottom variant="h3">Miscellaneous</Typography>
        <FieldArray
          name={`${rootPath}.miscellaneous.miscTimelineEntries`}
          render={(arrayHelpers) => (
            <Paper classes={{ root: 'card-root' }}>
              <Grid container item alignItems="center" justify="space-between">
                <FormLabel>Other Benefits</FormLabel>
                <Button disabled={isSubmitting} onClick={addDateRange(arrayHelpers)}>Add</Button>
              </Grid>
              {miscellaneous.miscTimelineEntries.map((benefit, index) => (
                <div className="input-row" key={index}>
                  <div>
                    <Field
                      component={TextField}
                      name={`${rootPath}.miscellaneous.miscTimelineEntries.${index}.description`}
                      label="Description"
                      value={benefit.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Field
                      component={TextField}
                      name={`${rootPath}.miscellaneous.miscTimelineEntries.${index}.startDate`}
                      label="Start Date"
                      value={benefit.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="date"
                    />
                    <Field
                      component={TextField}
                      name={`${rootPath}.miscellaneous.miscTimelineEntries.${index}.endDate`}
                      label="End Date"
                      value={benefit.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="date"
                    />
                    <ColorIndicator color={Colors.miscellaneous} />
                  </div>
                  <AutosaveDeleteButton disabled={isSubmitting} onClick={() => arrayHelpers.remove(index)} />
                </div>
              ))}
            </Paper>
          )}
        />
      </div>
    );
  }
}

MiscellaneousForm.propTypes = {
  classes: PropTypes.object.isRequired,
  miscellaneous: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
)(MiscellaneousForm);
