import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    paddingTop: theme.spacing(1),
  },
}))(MuiExpansionPanelDetails);

export default ExpansionPanelDetails;
