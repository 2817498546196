import { Paper, Typography, makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  label: {
    ...theme.typography.button
  },
  ctaContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

function VendorLetterAlertCard(props) {
  const classes = useStyles();
  const { errors } = props;

  //Mortgage Leads
  return (
    <Paper className={classes.container}>
      <div className={classes.ctaContainer}>
        <Typography style={{ color: '#5D9878' }} className={classes.label}>
          Last Step
        </Typography>
      </div>
      <Typography variant="h6" gutterBottom>
        Vendor Letters
      </Typography>
      {errors.map((error, i) => (
        <Typography key={i} gutterBottom>
          {error.errorMessage}
        </Typography>
      ))}
    </Paper>
  );
}

VendorLetterAlertCard.propTypes = {
  errors: PropTypes.array.isRequired
};

export default VendorLetterAlertCard;
