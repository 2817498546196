import 'react-image-crop/dist/ReactCrop.css';
import * as Sentry from '@sentry/browser';
import { Box, Button, Card, CardActionArea, CardMedia, Grid, Typography, makeStyles, withTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import classNames from 'classnames';

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { createImage, downloadImage as firebaseDownloadImage } from 'utilities/firebase';
import { getBlobFromCroppedImage, getDataUrlFromFile } from 'utilities/fileUtils';
import { showToast } from 'modules/layout/layout.actions';
import Spinner from 'modules/common/spinner.component';

const MAX_IMAGE_HEIGHT = 750;

const useStyles = makeStyles((theme) => ({
  addPhotoText: {
    color: theme.palette.common.white,
  },
  actionAreaRoot: {
    height: '100%',
  },
  imageLabel: {
    height: '100%',
    cursor: 'pointer',
  },
  noImageLabel: {
    backgroundColor: theme.palette.primary.light,
  },
  cardRoot: {
    flex: 1,
    margin: 5,
  },
  editLabel: {
    color: theme.palette.common.white,
  },
  editImageIcon: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.mobileStepper,
  },
  overlay: {
    backgroundColor: theme.palette.common.black,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: theme.palette.action.imageMaskOpacity,
  },
  spinner: {
    height: '100%',
  },
}));

const AddPhoto = (props) => {
  const { theme, setImageId, setImageUrl: propsSetImageUrl, imageId, isReadOnly, setIsProcessingImage } = props;
  const [originalImageSrc, setOriginalImageSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputElementId] = useState(uniqueId('imageUpload'));
  const imageRef = useRef();

  const classes = useStyles(props);
  const showSpinner = !imageUrl && isLoading;

  const downloadImg = async (imgId) => {
    setIsLoading(true);
    try {
      const newImageUrl = await firebaseDownloadImage(imgId);
      setImageUrl(newImageUrl);
      propsSetImageUrl(newImageUrl);
      setIsProcessingImage(false);
    } catch (e) {
      Sentry.captureException(new Error(`Download Image Error: ${JSON.stringify(e)}`));
    }
    setIsLoading(false);
  };

  const startCropping = async (event) => {
    setIsLoading(true);
    setIsProcessingImage(true);
    const dataUrl = await getDataUrlFromFile(event.target.files[0]);
    setOriginalImageSrc(dataUrl);
    setIsLoading(false);
  };

  const onImageLoaded = (img) => {
    imageRef.current = img;
    initCrop();
    return false;
  };

  const initCrop = () => {
    setCrop({ unit: '%', aspect: 4/3, width: 90 });
  };

  const cancelCrop = () => {
    setIsProcessingImage(false);
    finishCrop();
  };

  const finishCrop = () => {
    setOriginalImageSrc(null);
    setCrop(null);
  };

  const uploadImage = async (cropObj) => {
    finishCrop();
    setImageUrl(null);
    setIsLoading(true);
    try {
      const croppedBlob = await getBlobFromCroppedImage(imageRef.current, cropObj, MAX_IMAGE_HEIGHT);
      const newImageId = await createImage(croppedBlob);
      setImageId(newImageId);
    } catch (e) {
      Sentry.captureException(new Error(`Upload Image Error: ${JSON.stringify(e)}`));
      props.showToast('Failed to upload image. Please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (imageId) {
      downloadImg(imageId);
    } else {
      setImageUrl(null);
    }
    // eslint-disable-next-line
  }, [imageId]);

  let cardContent;

  if (!originalImageSrc) {
    cardContent = (
      <Box position="relative">
        <CardMedia
          className="media"
          image={imageUrl}
          children={
            <Box position="absolute" top={0} left={0} width="100%" height="100%">
              {isLoading ? (
                <Grid className={classes.spinner} container item alignItems="center" justify="center">
                  <Spinner />
                </Grid>
              ) : (
                !isReadOnly && (
                  <label
                    htmlFor={inputElementId}
                    disabled={isReadOnly}
                    className={classNames('column', 'center-container', classes.imageLabel, !imageUrl ? classes.noImageLabel : '')}
                  >
                    {!imageUrl ? (
                      <>
                        <Typography variant="button" className={classes.addPhotoText}>
                          Add a Photo *
                        </Typography>
                        <FontAwesomeIcon icon={['fas', 'camera']} size="8x" color="white" />
                      </>
                    ) : (
                      <>
                        <div className={classes.overlay} />
                        <div className={classNames('row', 'align-center', classes.editImageIcon)}>
                          <FontAwesomeIcon size="2x" className="mx" icon={['fas', 'camera']} color={theme.palette.common.white} />
                          <Typography className={classes.editLabel} variant="button">
                            Edit
                          </Typography>
                        </div>
                      </>
                    )}
                    <input disabled={isReadOnly} type="file" id={inputElementId} onChange={startCropping} hidden />
                  </label>
                )
              )}
            </Box>
          }
        />
      </Box>
    );
    if (!isReadOnly) cardContent = <CardActionArea>{cardContent}</CardActionArea>;
  } else {
    cardContent = (
      <Grid container direction="column">
        <Grid item container justify="center">
          <ReactCrop
            src={originalImageSrc}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onChange={(newCrop) => setCrop(newCrop)}
          />
        </Grid>
        <Grid container item spacing={1} justify="center">
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => uploadImage(crop)}>Save</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={initCrop}>Reset</Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={cancelCrop}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card className={classNames(classes.cardRoot, showSpinner ? 'center-container' : '')} elevation={0}>
      {cardContent}
    </Card>
  );
};

AddPhoto.propTypes = {
  theme: PropTypes.object.isRequired,
  setImageId: PropTypes.func.isRequired,
  setImageUrl: PropTypes.func.isRequired,
  imageId: PropTypes.string,
  isReadOnly: PropTypes.bool.isRequired,
  showToast: PropTypes.func.isRequired,
  setIsProcessingImage: PropTypes.func,
};

AddPhoto.defaultProps = {
  setIsProcessingImage: () => {},
};

export default compose(withTheme, connect(null, { showToast }))(AddPhoto);
