import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import InputContext from 'modules/intake/form/inputContext';

const styles = (theme) => ({
  root: {
    marginTop: 16,
  },
});

class AutosaveDeleteButton extends PureComponent {
  static contextType = InputContext;

  handleClick = () => {
    const { onClick } = this.props;
    const { submitForm } = this.context;

    onClick();
    setTimeout(() => { submitForm(); }, 0);
  };

  render() {
    const { disabled, classes } = this.props;
    return (
      <IconButton
        onClick={this.handleClick}
        disabled={disabled}
        classes={{
          root: classes.root,
        }}
      >
        <FontAwesomeIcon icon={['far', 'trash-alt']} size="lg" />
      </IconButton>
    );
  }
}

AutosaveDeleteButton.defaultProps = {
  disabled: false,
};

AutosaveDeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutosaveDeleteButton);
