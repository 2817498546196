import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { COLOR_ERROR } from 'styles/theme';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    borderColor: COLOR_ERROR.main,
    color: COLOR_ERROR.main,
  },
}));

function ConfirmationDialog(props) {
  const { title, children, cancelLabel, confirmLabel, onConfirm, onCancel, open, hideCancel, ...other } = props;
  const classes = useStyles();

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button onClick={onCancel} variant="outlined" className={classes.cancelButton}>
            {cancelLabel || 'Cancel'}
          </Button>
        )}
        <Button onClick={onConfirm} variant="contained" color="secondary">
          {confirmLabel || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  hideCancel: PropTypes.bool,
};

export default ConfirmationDialog;
