import { CALL_API } from 'middleware/api';

import {
  ADD_AUTH_POLICY_BUDGET_ADDITION_FAILURE,
  ADD_AUTH_POLICY_BUDGET_ADDITION_REQUEST,
  ADD_AUTH_POLICY_BUDGET_ADDITION_SUCCESS,
  ADD_AUTH_POLICY_NOTE_FAILURE,
  ADD_AUTH_POLICY_NOTE_REQUEST,
  ADD_AUTH_POLICY_NOTE_SUCCESS,
  GET_AUTH_POLICIES_FAILURE,
  GET_AUTH_POLICIES_REQUEST,
  GET_AUTH_POLICIES_SUCCESS,
  GET_AUTH_POLICY_DETAILS_FAILURE,
  GET_AUTH_POLICY_DETAILS_REQUEST,
  GET_AUTH_POLICY_DETAILS_SUCCESS,
  GET_POLICY_SUMMARIES_BY_CLIENT_FAILURE, 
  GET_POLICY_SUMMARIES_BY_CLIENT_REQUEST, 
  GET_POLICY_SUMMARIES_BY_CLIENT_SUCCESS,
  TOGGLE_SENT_TO_VENDOR_FAILURE,
  TOGGLE_SENT_TO_VENDOR_REQUEST, 
  TOGGLE_SENT_TO_VENDOR_SUCCESS,
} from './types';

export const getAuthPolicies = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTH_POLICIES_REQUEST, GET_AUTH_POLICIES_SUCCESS, GET_AUTH_POLICIES_FAILURE],
      authenticated: true,
      endpoint: 'authPolicyDetails',
      method: 'GET',
    },
  };
};

export const getAuthPolicyDetails = (authId) => {
  return {
    [CALL_API]: {
      types: [GET_AUTH_POLICY_DETAILS_REQUEST, GET_AUTH_POLICY_DETAILS_SUCCESS, GET_AUTH_POLICY_DETAILS_FAILURE],
      authenticated: true,
      endpoint: `authPolicyDetails/${authId}`,
      method: 'GET',
    },
  };
};

export const addNote = (authId, note) => {
  return {
    [CALL_API]: {
      types: [ADD_AUTH_POLICY_NOTE_REQUEST, ADD_AUTH_POLICY_NOTE_SUCCESS, ADD_AUTH_POLICY_NOTE_FAILURE],
      authenticated: true,
      endpoint: `authorization/${authId}/policy/note`,
      method: 'POST',
      payload: note,
    },
  };
};

export const addBudgetAddition = (authId, addition) => {
  return {
    [CALL_API]: {
      types: [ADD_AUTH_POLICY_BUDGET_ADDITION_REQUEST, ADD_AUTH_POLICY_BUDGET_ADDITION_SUCCESS, ADD_AUTH_POLICY_BUDGET_ADDITION_FAILURE],
      authenticated: true,
      endpoint: `authorization/${authId}/policy/budgetAddition`,
      method: 'POST',
      payload: { addition: addition.addition, notes: addition.notes },
    },
  };
};

export const toggleSentToVendor = (authId, optionId, sentToVendor) => {
  return {
    [CALL_API]: {
      types: [TOGGLE_SENT_TO_VENDOR_REQUEST, TOGGLE_SENT_TO_VENDOR_SUCCESS, TOGGLE_SENT_TO_VENDOR_FAILURE],
      authenticated: true,
      endpoint: `authorization/${authId}/option/${optionId}/sentToVendor?sentToVendor=${sentToVendor}`,
      method: 'PATCH',
      context: {
        authId,
        optionId,
      },
    },
  };
};

export const getPolicySummariesByClientId = (clientId) => {
  return {
    [CALL_API]: {
      types: [GET_POLICY_SUMMARIES_BY_CLIENT_REQUEST, GET_POLICY_SUMMARIES_BY_CLIENT_SUCCESS, GET_POLICY_SUMMARIES_BY_CLIENT_FAILURE],
      authenticated: true,
      endpoint: `client/${clientId}/policies/summaries`,
      method: 'GET',
    },
  };
};