import { FormControlLabel, Checkbox as MuiCheckbox, withStyles } from '@material-ui/core';
import { fieldToCheckbox } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

import ErrorMessage from 'modules/intake/form/errorMessage.component';
import InputContext from 'modules/intake/form/inputContext';

const styles = (theme) => ({
  container: ({ alignCenter }) => ({ marginTop: alignCenter ? 22 : 0 }),
});

class Checkbox extends PureComponent {
  static contextType = InputContext;

  render() {
    const { onBlur, onFocus, onChange } = this.context;
    // eslint-disable-next-line no-unused-vars
    const { classes, alignCenter, ...checkBoxProps } = this.props;
    const { className, disabled, form, label, field: { name } } = checkBoxProps;

    return (
      <div className={classNames(className, 'form-control-wrapper', classes.container)}>
        <FormControlLabel
          control={
            <MuiCheckbox
              {...fieldToCheckbox(checkBoxProps)}
              checked={this.props.value}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={(e) => {
                this.props.onChange(e);
                onChange();
                this.props.onBlur(e);
                onBlur();
              }}
              disabled={form.isSubmitting || disabled}
            />
          }
          label={label}
        />
        <ErrorMessage name={name} />
      </div>
    );
  }
}

Checkbox.defaultProps = {
  alignCenter: false,
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
  }),
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  alignCenter: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Checkbox);
