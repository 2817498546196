import { library } from '@fortawesome/fontawesome-svg-core';

import { faBallot, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import {
  faCamera,
  faCheckCircle,
  faChevronDown,
  faCircle,
  faConciergeBell,
  faExclamationCircle,
  faExclamationTriangle,
  faFileDownload,
  faFileImport,
  faHistory,
  faKey,
  faPen,
  faPenSquare,
  faPowerOff,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faIdBadge } from '@fortawesome/free-regular-svg-icons';
import { faLightbulbOn, faPaperPlane, faPlus, faPlusCircle, faStickyNote, faTrashAlt, faTruckMoving } from '@fortawesome/pro-regular-svg-icons';

const buildIconLibrary = () => {
  library.add(
    faIdBadge,
    faPlus,
    faPlusCircle,
    faPowerOff,
    faCircle,
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faSpinnerThird,
    faTrashAlt,
    faKey,
    faLightbulbOn,
    faFileImport,
    faStickyNote,
    faFileDownload,
    faCamera,
    faTruckMoving,
    faBallot,
    faPenSquare,
    faPen,
    faChevronDown,
    faHistory,
    faConciergeBell,
    faPaperPlane,
    faQuestionCircle,
  );
};

export default buildIconLibrary;
