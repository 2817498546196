import { Box } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import * as LayoutActions from 'modules/layout/layout.actions';
import * as SelfServiceActions from 'modules/selfService/selfService.actions';
import { ADD_AUTH_POLICY_NOTE_FAILURE } from '../types';
import { GET_AUTH_POLICY_DETAILS_FAILURE } from 'modules/selfService/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import AddPointsDialog from './addPointsDialog.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import NotesDrawer from 'modules/common/notesDrawer.component';
import SelfServiceBenefitList from './selfServiceBenefitList.component';
import SelfServiceDetailsHeader from 'modules/selfService/details/selfServiceDetailsHeader.component';

const SelfServiceDetailsContainer = (props) => {
  const { isLoading, authPolicyDetails, userProfile } = props;
  const [isNotesDrawerOpen, setIsNotesDrawerOpen] = useState(false);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [isPointsDialogOpen, setIsPointsDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const authId = props.match.params.authId;

  const saveNote = async (note) => {
    setIsSavingNote(true);
    const action = await dispatch(SelfServiceActions.addNote(authId, note));
    setIsSavingNote(false);
    if (action.type === ADD_AUTH_POLICY_NOTE_FAILURE) {
      dispatch(LayoutActions.showToast('Failed to add note to the authorization', { severity: TOAST_MESSAGE_SEVERITY_ERROR }));
    }
  };

  useEffect(() => {
    (async () => {
      dispatch(LayoutActions.setPageTitle('Self Service Policy Details'));
      const action = await dispatch(SelfServiceActions.getAuthPolicyDetails(authId));
      if (action.type === GET_AUTH_POLICY_DETAILS_FAILURE) {
        dispatch(LayoutActions.showToast('Failed to load Authorization Policy', { severity: TOAST_MESSAGE_SEVERITY_ERROR }));
      }
    })();
    }, []); // eslint-disable-line


  if (isLoading || !authPolicyDetails) {
    return <FullscreenSpinner />;
  }

  return (
    <Box pl={1} pr={1}>
      {authPolicyDetails && <SelfServiceDetailsHeader authPolicyDetails={authPolicyDetails} onAddNote={() => setIsNotesDrawerOpen(true)} onAddPoints={() => setIsPointsDialogOpen(true)} />}
      {authPolicyDetails &&
        <NotesDrawer
          isSubmitting={isSavingNote}
          open={isNotesDrawerOpen}
          onClose={() => setIsNotesDrawerOpen(false)}
          notes={authPolicyDetails.policy.notes}
          saveNote={(note) => saveNote(note)}
        />
      }
      {authPolicyDetails &&
        <Box marginTop="180px">
          <SelfServiceBenefitList authPolicyDetails={authPolicyDetails} />
        </Box>
      }
      {
        authPolicyDetails && userProfile &&
          <AddPointsDialog
            open={isPointsDialogOpen}
            onClose={() => setIsPointsDialogOpen(false)}
            authDetails={authPolicyDetails}
            authId={authId}
            userProfile={userProfile}
          />
      }
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    authPolicyDetails: state.selfService.authPolicyDetails,
    clientList: state.selfService.clientList,
    isLoading: state.selfService.isLoading,
    userProfile: state.auth.profile,
  };
};

SelfServiceDetailsContainer.propTypes = {
  authPolicyDetails: PropTypes.object,
  userProfile: PropTypes.object,
  match: ReactRouterPropTypes.match.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(SelfServiceDetailsContainer);
