import { Button, FormLabel, Grid, Paper, withStyles } from '@material-ui/core';

import { Field, FieldArray } from 'formik';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AutosaveDeleteButton, TextField, ToggleSelect } from 'modules/intake/form';
import { showToast } from 'modules/layout/layout.actions';
import Hint from 'modules/common/hint.component';
import Log from 'utilities/log';

import { CHANGES_SINCE_PURCHASE, LegalName } from 'modules/intake/models';
import styles from 'modules/intake/styles';

const addTitleLegalName = (arrayHelpers) => () => {
  arrayHelpers.push(new LegalName());
};

class PropertyTitleLegalNameForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.titleLegalName, nextProps.titleLegalName) ||
      this.props.isSubmitting !== nextProps.isSubmitting;
  }

  render() {
    Log.trace('RENDER', 'PropertyTitleLegalNameForm');
    const {
      titleLegalName,
      handleChange,
      handleBlur,
      rootPath,
      isSubmitting,
    } = this.props;

    return (
      <FieldArray
        name={`${rootPath}.propertyTitleLegalNames`}
        render={(arrayHelpers) => (
          <Paper classes={{ root: 'card-root' }}>
            <Grid container item alignItems="center" justify="space-between">
              <FormLabel>Legal Name on Title</FormLabel>
              <Button disabled={isSubmitting} onClick={addTitleLegalName(arrayHelpers)}>Add Legal Name</Button>
            </Grid>
            {titleLegalName.propertyTitleLegalNames.map((contact, index) => (
              <div className="input-row" key={index}>
                <div>
                  <Field
                    component={TextField}
                    name={`${rootPath}.propertyTitleLegalNames.${index}.firstName`}
                    label="First Name"
                    value={contact.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Field
                    component={TextField}
                    name={`${rootPath}.propertyTitleLegalNames.${index}.middleName`}
                    label="Middle Name/Initial"
                    value={contact.middleName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Field
                    component={TextField}
                    name={`${rootPath}.propertyTitleLegalNames.${index}.lastName`}
                    label="Last Name"
                    value={contact.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <AutosaveDeleteButton
                  disabled={titleLegalName.propertyTitleLegalNames.length === 1}
                  onClick={() => arrayHelpers.remove(index)}
                />
              </div>
            ))}
            <Field
              component={ToggleSelect}
              name={`${rootPath}.changesSincePurchase`}
              label="Since Purchase"
              labelPlacement="top"
              value={titleLegalName.changesSincePurchase}
              onChange={handleChange}
              onBlur={handleBlur}
              options={CHANGES_SINCE_PURCHASE}
              variant="spread"
              multiselect
            />
            {(titleLegalName.divorcedSincePurchase || titleLegalName.nameChangedSincePurchase) &&
              <Hint>Be mindful of title situation</Hint>
            }
          </Paper>
        )}
      />
    );
  }
}

PropertyTitleLegalNameForm.propTypes = {
  titleLegalName: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  connect(null, {
    showToast,
  }),
)(PropertyTitleLegalNameForm);
