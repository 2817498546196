import { makeStyles } from '@material-ui/core';

import React from 'react';

import Spinner from 'modules/common/spinner.component';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function FullscreenSpinner() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Spinner logo />
    </div>
  );
}

export default FullscreenSpinner;
