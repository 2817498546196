import { isEqual, memoize } from 'lodash';
import { useCallback, useState } from 'react';

export const getVersionFromSummary = (summary) => {
  return summary.mostRecentPublish || summary.draft;
};

export const getVersionFromSummaryById = (summary, versionId) => {
  return [summary.mostRecentPublish || {}, summary.draft || {}]
    .find((version) => version.versionId === versionId);
};

export const getObjectId = (obj) => {
  return obj.id != null ? obj.id : obj.tempId;
};

export const sortSummaries = memoize((summaries) => {
  const sorted = [...summaries];
  // sort enabled to the top alphabetically
  sorted.sort((summary1, summary2) => {
    if (summary1.enabled) {
      if (!summary2.enabled) {
        return -1;
      } else {
        const sortSummary1First = getVersionFromSummary(summary1).name.toLowerCase() < getVersionFromSummary(summary2).name.toLowerCase();
        return sortSummary1First ? -1 : 1;
      }
    } else {
      if (summary2.enabled) {
        return 1;
      } else {
        const sortSummary1First = getVersionFromSummary(summary1).name.toLowerCase() < getVersionFromSummary(summary2).name.toLowerCase();
        return sortSummary1First ? -1 : 1;
      }
    }
  });
  return sorted;
});

export const transformSummaryIntoBenefit = (summary) => {
  const benefit = { ...summary };
  delete benefit.draft;
  delete benefit.mostRecentPublish;
  return benefit;
};

export const mergeOptionsWithOptionDetails = (options, optionDetails) => {
  return optionDetails.map((optDetail) => {
    const correspondingOption = options.find((opt) => opt.id === optDetail.optionId);
    return { ...optDetail, ...correspondingOption };
  });
};

export const areAllQuestionsEqual = (questionsList1, questionsList2) => {
  if (questionsList1.length !== questionsList2.length) {
    return false;
  }
  return questionsList1.every((list1Question) => {
    const matchingQuestion = questionsList2.find((qd) => qd.questionId === list1Question.questionId);
    if (!matchingQuestion) {
      return false;
    }
    return areQuestionsEqual(list1Question, matchingQuestion);
  });
};

export const areQuestionsEqual = (questionDetail1, questionDetail2) => {
  return questionDetail1.text === questionDetail2.text &&
    isEqual(questionDetail1.answers, questionDetail2.answers);
};

export const useRefCallback = () =>  {
  const [refNode, setRefNode] = useState(null);
  const setRef = useCallback((node) => {
    if (node)
      setRefNode(node);
  }, []);

  return [refNode, setRef];
};
