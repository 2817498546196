import { Chip, Typography } from '@material-ui/core';
import { LocalDate } from 'js-joda';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDate, formatLocalDate } from 'utilities/common';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1),
  },
}));

function Note(props) {
  const classes = useStyles();
  const noteDate = props.note.created ?
    formatDate(props.note.created) :
    formatLocalDate(LocalDate.now().toString());

  return (
    <div className="column">
      <Typography variant="caption" className="text-right">{noteDate}</Typography>
      <Typography>{props.note.note}</Typography>
      {props.note.serviceType &&
        <Chip label={props.note.serviceType.description} className={classes.chip} />
      }
      {props.note.regardingType &&
        <Chip label={props.note.regardingType.description} className={classes.chip} />
      }
      {props.note.isWebViewable &&
        <Chip label="Web Viewable" className={classes.chip} variant="outlined" />
      }
    </div>
  );
}

Note.propTypes = {
  note: PropTypes.object.isRequired,
};

export default Note;
