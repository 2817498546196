import { Box, MenuItem, Paper, Typography, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { isEqual, memoize } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Select, TextField } from 'modules/intake/form';
import countries from 'modules/common/countries.json';
import states from 'modules/common/states.json';

import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class PropertyAddressForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.address, nextProps.address)
    || !isEqual(this.props.eeIntakeForm, nextProps.eeIntakeForm);
  }

  renderCountryOptions = memoize(() => {
    return countries.map(({ countryCode, description }) => (
      <MenuItem key={countryCode} value={countryCode}>{description}</MenuItem>
    ));
  });

  renderStateOptions = memoize((countryCode) => {
    const useCountryCode = countryCode || 'USA';
    return states.filter((state) => state.countryCode === useCountryCode)
      .map(({ code, description }) => (
        <MenuItem key={code} value={code}>{description}</MenuItem>
      ));
  });

  handleCountryChange = (event) => {
    const { rootPath, handleChange } = this.props;
    // if country changed, clear out state
    handleChange({ target: { name: `${rootPath}.stateCode`, value: '' } });
    handleChange(event);
  };

  render() {
    Log.trace('RENDER', 'PropertyAddressForm');
    const {
      address,
      handleChange,
      handleBlur,
      rootPath,
      classes,
      eeIntakeForm,
    } = this.props;

    return (
    
      <Paper classes={{ root: 'card-root' }}>
        <Typography gutterBottom variant="h4">Departure Address</Typography>
        <Field
          component={TextField}
          name={`${rootPath}.address1`}
          label="Address 1"
          value={address.address1}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        />
        {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.address1 && (
          <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
        )}

        <Field
          component={TextField}
          name={`${rootPath}.address2`}
          label="Address 2"
          value={address.address2}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        />
        {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.address2 && (
          <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
        )}

        <Field
          component={Select}
          name={`${rootPath}.countryCode`}
          label="Country"
          value={address.countryCode}
          onChange={this.handleCountryChange}
          onBlur={handleBlur}
          fullWidth
          labelWidth={48}
        >
          {this.renderCountryOptions()}
        </Field>
        {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.departureCountryCode && (
          <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box>
            <Field
              component={TextField}
              name={`${rootPath}.city`}
              label="City"
              value={address.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.departureCity && (
              <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
            )}
          </Box>

          <Box>
            <Field
              component={Select}
              name={`${rootPath}.stateCode`}
              label="State"
              value={address.stateCode}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.stateSelect}
              labelWidth={34}
            >
              {this.renderStateOptions(address.countryCode)}
            </Field>
            {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.departureStateCode && (
              <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
            )}
          </Box>

          <Box>
            <Field
              component={TextField}
              name={`${rootPath}.postalCode`}
              label="Zip/Postal Code"
              value={address.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.departurePostalCode && (
              <Typography style={{ color:'green' }}> ^ Sourced from EE</Typography>
            )}
          </Box>
        </Box>
      </Paper>
    );
  }
}

PropertyAddressForm.propTypes = {
  address: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyAddressForm);
