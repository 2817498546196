import {
  Chip,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  OutlinedInput,
  withStyles,
} from '@material-ui/core';
import { fieldToSelect } from 'formik-material-ui';
import { without } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ErrorMessage from 'modules/intake/form/errorMessage.component';
import InputContext from 'modules/intake/form/inputContext';

const styles = (theme) => ({
  formControl: {
    minWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  select: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minHeight: 40,
  },
  selectMenu: {
    marginTop: 56,
  },
});

class ChipSelect extends PureComponent {
  static defaultProps = {
    labelAccessor: (value) => value.description,
  };
  static contextType = InputContext;

  render() {
    const {
      label,
      classes,
      children,
      fullWidth,
      labelWidth,
      field: { name },
    } = this.props;
    const { labelAccessor, ...selectProps } = this.props;
    const { onBlur, onFocus, onChange } = this.context;

    return (
      <FormControl
        variant="outlined"
        fullWidth={fullWidth}
        className={classes.formControl}
      >
        <InputLabel>{label}</InputLabel>
        <MuiSelect
          {...fieldToSelect(selectProps)}
          onBlur={(event) => {
            this.props.onBlur(event);
            onBlur(event);
          }}
          onFocus={onFocus}
          onChange={(event) => {
            this.props.onChange(event);
            onChange(event);
          }}
          multiple
          input={<OutlinedInput name={name} labelWidth={labelWidth || 100} />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => {
                console.log(value);
                return (
                  <Chip
                    key={value.id}
                    label={labelAccessor(value)}
                    className={classes.chip}
                    onDelete={() => {
                      this.props.form.setFieldValue(name, without(selected, value));
                      // this.props.onBlur();
                      onBlur();
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                );
              })}
            </div>
          )}
          classes={{ select: classes.select }}
          MenuProps={{
            classes: { paper: classes.selectMenu },
          }}
        >
          {children}
        </MuiSelect>
        <ErrorMessage name={name} />
      </FormControl>
    );
  }
}

ChipSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  labelAccessor: PropTypes.func,
  labelWidth: PropTypes.number,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }),
};

export default withStyles(styles)(ChipSelect);
