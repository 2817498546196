import { Box, Divider, Grid, List, ListItem, Paper, Typography } from '@material-ui/core';
import { DateTimeFormatter, ZonedDateTime } from 'js-joda';
import { Locale } from '@js-joda/locale_en-us';
import { connect } from 'react-redux';
import { getBenefitHistory } from './benefit.actions';
import { isEmpty } from 'lodash';
import Dialog from 'modules/common/dialog.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

const DATE_FORMATTER = DateTimeFormatter.ofPattern('dd MMMM YYYY').withLocale(Locale.US);

const prepareHistoryEntries = (history) => {
  const sortedHistory = history.sort((a, b) => {
    //sort by publishedDate desc
    //if either is a draft, it ranks at top
    return !a.isPublished ? -1 : !b.isPublished ? 1 : new Date(b.publishedDate) - new Date(a.publishedDate);
  });

  const versionedHistory = sortedHistory.map((h, i) => {
    h.versionId = history.length - i;
    return h;
  });

  return versionedHistory;
};

const HistoryRow = (props) => {
  const { historyRow } = props;
  return (
    <ListItem>
      <Grid container>
        <Grid item>
          <Box color="secondary.main">{`V${historyRow.versionId} |`}</Box>
        </Grid>
        {!historyRow.isPublished ? (
          <Grid item>DRAFT</Grid>
        ) : (
          <>
            <Grid item xs={2}>
              {ZonedDateTime.parse(historyRow.publishedDate).format(DATE_FORMATTER)}
            </Grid>
            <Grid item xs={3}>
              {historyRow.publishedBy ? historyRow.publishedBy.moveTrackUserName : ''}
            </Grid>
            <Grid item xs={6}>
              {historyRow.publishNotes}
            </Grid>
          </>
        )}
      </Grid>
    </ListItem>
  );
};

HistoryRow.propTypes = {
  historyRow: PropTypes.object.isRequired,
};

const HistoryRows = (props) => {
  const { history } = props;
  return (
    history && (
      <Paper elevation={1}>
        <Box maxHeight={250} overflow="auto">
          <List>
            {history.map((h, i) => {
              return (
                <Fragment key={i}>
                  <HistoryRow key={i} historyRow={h} />
                  {i + 1 < history.length && <Divider />}
                </Fragment>
              );
            })}
          </List>
        </Box>
      </Paper>
    )
  );
};

HistoryRows.propTypes = {
  history: PropTypes.array.isRequired,
};

const BenefitHistoryDialog = (props) => {
  const { open: propsOpen, onClose: propsOnClose, benefitId, getBenefitHistory: propsGetBenefitHistory } = props;
  const [history, setHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);


  useEffect(() => {
    (async () => {
      if (propsOpen && !history) {
        const r = await propsGetBenefitHistory(benefitId);
        const historyEntries = r.response;
        if (isEmpty(historyEntries)) {
          setErrorMessage('There was a problem loading the history. Please try again');
        } else {
          setHistory(prepareHistoryEntries(historyEntries));
        }
        setIsLoading(false);
      }
    })();
  }, [propsOpen, history, benefitId, propsGetBenefitHistory]);

  return (
    <Dialog
      open={propsOpen}
      title="History"
      showTitleClose={true}
      maxWidth="lg"
      onClose={propsOnClose}
      onExited={() => {
        setHistory(null);
      }}
      content={
        <Box width={700} px={1}>
          {isLoading ? (
            <FullscreenSpinner />
          ) : errorMessage ? (
            <Typography variant="h5" color="error">
              {errorMessage}
            </Typography>
          ) : (
            <>
              <Box mb={1} mt={2} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                Updates
              </Box>
              <HistoryRows history={history} />
            </>
          )}
        </Box>
      }
    />
  );
};

BenefitHistoryDialog.propTypes = {
  benefitId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  getBenefitHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    getBenefitHistory: state.getBenefitHistory,
  };
};

export default connect(mapStateToProps, {
  getBenefitHistory,
})(BenefitHistoryDialog);
