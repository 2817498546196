import { Box, Button, Chip, MenuItem, Paper, Typography, makeStyles } from '@material-ui/core';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import ChipInput from 'material-ui-chip-input-v5';
//import { MuiChipsInput } from 'mui-chips-input';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { COST_TYPES } from './constants';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { costLookupByTableSelector } from './benefit.selectors';
import { showToast } from 'modules/layout/layout.actions';
import AddPhoto from './addPhoto.component';
import Dialog from 'modules/common/dialog.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import TRCInputValidator from './form/TRCInputValidator';
import TwoDecimalFormat from 'modules/common/twoDecimalFormat.component';

const useStyles = makeStyles((theme) => (
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 350,
    },
    okButton: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    cardRoot: {
      width: 300,
    },
  }
));

const formRef = React.createRef();

const BenefitOptionDialog = (props) => {
  const {
    open,
    costType,
    columns,
    apiCostLookupOptionFields,
    apiCostLookupAuthorizationFields,
    tableCostLookupAuthorizationFields,
    isLoading,
    setBenefitOption,
    otherOpts,
  } = props;

  const classes = useStyles();

  const initSelectedOpt = useCallback(() => {
    const selectedOpt = {
      name: '',
      tempId: uuidv4(),
      description: '',
      imageId: '',
      costVariables: {},
      cost: null,
    };
    if (columns) {
      selectedOpt.costVariables = Object.keys(columns).reduce((costVariables, colName) => {
        costVariables[colName] = '';
        return costVariables;
      }, {});
    }
    if (apiCostLookupOptionFields) {
      selectedOpt.costVariables = apiCostLookupOptionFields.reduce((costVariables, field) => {
        costVariables[field.name] = '';
        return costVariables;
      }, {});
    }
    return selectedOpt;
  }, [columns, apiCostLookupOptionFields]);

  const [selectedOpt, setSelectedOpt] = useState(initSelectedOpt());
  const [isEdit, setIsEdit] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isProcessingImage, setIsProcessingImage] = useState(false);

  const resetState = () => {
    setSelectedOpt(initSelectedOpt());
    setIsEdit(false);
  };

  const submit = () => {
    if (otherOpts.some((otherOpt) => otherOpt.name === selectedOpt.name)) {
      props.showToast('Each option must have a unique name.', { severity: TOAST_MESSAGE_SEVERITY_ERROR, userMustDismiss: false });
      return;
    }
    setBenefitOption(selectedOpt);
    close();
  };

  const close = () => {
    resetState();
    props.close();
  };

  const renderAuthFields = (authFields) => {
    return (
      <ChipInput
        value={authFields.map((field) => field.label)}
        label="Authorization variables used to calculate the cost:"
        InputProps={{ readOnly: true }}
        chipRenderer={({ chip }) => (
          <Chip
            className="mr"
            key={chip}
            label={chip}
          />
        )}
        disableUnderline
        fullWidth
      />
      //<MuiChipsInput value={authFields.map((field) => field.label)} label="Authorization Variables" disabled={true} />
    );
  };

  useEffect(() => {
    if (props.selectedOpt) {
      setSelectedOpt({ ...props.selectedOpt });
      setIsEdit(true);
    } else {
      setSelectedOpt(initSelectedOpt());
      setIsEdit(false);
    }
  }, [props.selectedOpt, initSelectedOpt]);

  useEffect(() => { // check form validity
    (async () => {
      if (!formRef.current) {
        return;
      }
      let formValid = await formRef.current.isFormValid();
      if (!isEmpty(selectedOpt.costVariables)) {
        formValid = formValid && Object.values(selectedOpt.costVariables).every((val) => !!val);
      }
      setIsFormValid(formValid);
    })();
  }, [selectedOpt, costType]);

  return (
    <Dialog
      open={open}
      title={
        <div className="row-no-basis justify-space-between">
          <Typography variant="h5">
            {`Benefit Option ${isEdit ? 'Edit' : 'Add'}`}
          </Typography>
        </div>
      }
      content={
        <>
          {isLoading && <FullscreenSpinner />}
          {!isLoading &&
            <div className={classes.root}>
              <AddPhoto
                classes={{ cardRoot: classes.cardRoot }}
                setImageId={(imageId) => setSelectedOpt({ ...selectedOpt, imageId })}
                setImageUrl={(imageUrl) => setSelectedOpt((v) => {v.imageUrl = imageUrl; return v;})}
                imageId={selectedOpt.imageId}
                isReadOnly={false}
                setIsProcessingImage={setIsProcessingImage}
              />
              <ValidatorForm ref={formRef} onSubmit={() => {}}>
                <TRCInputValidator
                  component={TextValidator}
                  name="optionName"
                  label="Benefit Option Title"
                  onChange={(value) => setSelectedOpt({ ...selectedOpt, name: value })}
                  value={selectedOpt.name}
                  required
                  fullWidth
                />
                <TRCInputValidator
                  component={TextValidator}
                  name="optionDescription"
                  label="Description"
                  onChange={(value) => setSelectedOpt({ ...selectedOpt, description: value })}
                  value={selectedOpt.description}
                  required
                  fullWidth
                />
                {
                  costType === COST_TYPES.AMOUNT ?
                    <TRCInputValidator
                      component={TextValidator}
                      InputProps={{ inputComponent: TwoDecimalFormat }}
                      name="cost"
                      label="Amount"
                      onChange={(value) => setSelectedOpt({ ...selectedOpt, cost: value })}
                      value={selectedOpt.cost}
                      required
                      fullWidth
                    /> :
                    <Paper elevation={3}>
                      <Box p={2}>
                        <Typography variant="body1" gutterBottom>Cost Variables: </Typography>
                        {costType === COST_TYPES.TABLE_LOOKUP &&
                          Object.keys(columns).map((columnName) => (
                            <TRCInputValidator
                              component={SelectValidator}
                              key={columnName}
                              name={`costVariables.${columnName}`}
                              value={selectedOpt.costVariables[columnName]}
                              label={columns[columnName].label}
                              onChange={(value) => {
                                setSelectedOpt({
                                  ...selectedOpt,
                                  costVariables: { ...selectedOpt.costVariables, [columnName]: value },
                                });
                              }}
                              required
                              fullWidth
                            >
                              {columns[columnName].values.map((colVal) => (
                                <MenuItem
                                  key={colVal}
                                  value={colVal}
                                >
                                  {colVal}
                                </MenuItem>
                              ))
                              }
                            </TRCInputValidator>
                          ))
                        }
                        {costType === COST_TYPES.API_CALC &&
                          apiCostLookupOptionFields.map((field) => (
                            <TRCInputValidator
                              component={TextValidator}
                              key={field.id}
                              name={`costVariables.${field.name}`}
                              value={selectedOpt.costVariables[field.name]}
                              label={field.label}
                              onChange={(value) => {
                                setSelectedOpt({
                                  ...selectedOpt,
                                  costVariables: { ...selectedOpt.costVariables, [field.name]: value },
                                });
                              }}
                              required
                              fullWidth
                            />
                          ))
                        }
                        {renderAuthFields(costType === COST_TYPES.API_CALC ? apiCostLookupAuthorizationFields : tableCostLookupAuthorizationFields)}
                      </Box>
                    </Paper>
                }
              </ValidatorForm>
            </div>
          }
        </>
      }
      actions={
        <div className={classNames('row-no-grow', 'justify-end', 'my')}>
          <Button size="large" onClick={close}>Cancel</Button>
          <Button
            className={classes.okButton}
            size="large"
            variant="contained"
            color="primary"
            onClick={submit}
            disabled={!isFormValid || isProcessingImage}
          >
            Ok
          </Button>
        </div>
      }
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const isLoading = state.benefit.isLoadingCostLookup;
  let toReturn = { isLoading };
  if (ownProps.costType === COST_TYPES.TABLE_LOOKUP) {
    toReturn = { ...toReturn, ...mapTableLookupProps(state, ownProps) };
  }
  return toReturn;
};

const mapTableLookupProps = (state, ownProps) => {
  if (ownProps.costLookupKey) {
    const costLookupByTable = costLookupByTableSelector(state)(ownProps.costLookupKey);
    if (costLookupByTable) {
      return {
        columns: costLookupByTable.optionFields,
        costList: costLookupByTable.costList,
      };
    }
  }
  return {
    columns: {},
    costList: [],
  };
};

BenefitOptionDialog.propTypes = {
  setBenefitOption: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  costType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  otherOpts: PropTypes.array.isRequired,
  costLookupKey: PropTypes.string,
  selectedOpt: PropTypes.object,
  apiCostLookupOptionFields: PropTypes.array,
  apiCostLookupAuthorizationFields: PropTypes.array,
  tableCostLookupAuthorizationFields: PropTypes.array,
  columns: PropTypes.object,
  costList: PropTypes.array,

  showToast: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { showToast })(BenefitOptionDialog);
