import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import React from 'react';

import { INTEGER_MAX } from './constants';

const PositiveIntegerFormat = (props) => {
  const { onChange, inputRef, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.floatValue !== undefined ? Math.min(values.floatValue, INTEGER_MAX) : null);
      }}
      decimalScale={0}
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

PositiveIntegerFormat.propTypes = {
  inputRef: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PositiveIntegerFormat;
