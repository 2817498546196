import { CALL_INTAKE_API } from 'middleware/intakeApi';

import {
  GET_CLIENTS_FAILURE, GET_CLIENTS_REQUEST, GET_CLIENTS_SUCCESS,
  GET_RELOCATION_CONSULTANTS_FAILURE, GET_RELOCATION_CONSULTANTS_REQUEST, GET_RELOCATION_CONSULTANTS_SUCCESS,
} from './types';

export const getClients = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_CLIENTS_REQUEST, GET_CLIENTS_SUCCESS, GET_CLIENTS_FAILURE],
      authenticated: true,
      endpoint: 'clients',
      method: 'GET',
    },
  };
};

export const getRelocationConsultants = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_RELOCATION_CONSULTANTS_REQUEST, GET_RELOCATION_CONSULTANTS_SUCCESS, GET_RELOCATION_CONSULTANTS_FAILURE],
      authenticated: true,
      endpoint: 'relocationConsultants',
      method: 'GET',
    },
  };
};
