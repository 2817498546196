import { object } from 'yup';

import { formatDateForInput, formatForWire } from 'utilities/common';

import { plusMinusFiveYearsDateSchema } from 'modules/common/constants';

export default class FinalMoveBenefits {
  static schema = object().shape({
    transfereeTravelStartDate: plusMinusFiveYearsDateSchema,
    transfereeTravelEndDate: plusMinusFiveYearsDateSchema,
    familyTravelStartDate: plusMinusFiveYearsDateSchema,
    familyTravelEndDate: plusMinusFiveYearsDateSchema,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.transfereeTravelStartDate = formatDateForInput(json.transfereeTravelStartDate);
    this.transfereeTravelEndDate = formatDateForInput(json.transfereeTravelEndDate);
    this.familyTravelStartDate = formatDateForInput(json.familyTravelStartDate);
    this.familyTravelEndDate = formatDateForInput(json.familyTravelEndDate);
  }

  static fromWireData(wireData) {
    return new FinalMoveBenefits(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      transfereeTravelStartDate: this.transfereeTravelStartDate,
      transfereeTravelEndDate: this.transfereeTravelEndDate,
      familyTravelStartDate: this.familyTravelStartDate,
      familyTravelEndDate: this.familyTravelEndDate,
    });
  }
}
