import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Dialog from 'modules/common/dialog.component';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 200,
  },
  selectMenu: {
    minHeight: 16,
  },
}));

function ReassignIntakeDialog(props) {
  const [relocationConsultant, setUser] = useState(props.assignee);
  const classes = useStyles();

  const {
    onSubmit,
    onCancel,
    disabled,
    ...rest
  } = props;

  const handleSubmit = () => onSubmit(relocationConsultant);

  const handleChange = (event) => {
    setUser(event.target.value);
  };

  return (
    <Dialog
      {...rest}
      title="Reassign Intake Record"
      content={
        <>
          <Typography>Select a Relocation Consultant to reassign this record</Typography>
          <FormControl className={classes.formControl} disabled={disabled}>
            <InputLabel>Assignee</InputLabel>
            <Select
              value={relocationConsultant}
              onChange={handleChange}
              classes={{ selectMenu: classes.selectMenu }}
            >
              {props.relocationConsultants.map((consultant) => (
                <MenuItem key={consultant.id} value={consultant}>{consultant.fullName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      }
      actions={
        <>
          <Button disabled={disabled} onClick={onCancel} variant="outlined" color="primary">Cancel</Button>
          <Button disabled={disabled} onClick={handleSubmit} variant="contained" color="primary">Save</Button>
        </>
      }
    />
  );
}

ReassignIntakeDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  relocationConsultants: PropTypes.array.isRequired,
  assignee: PropTypes.object.isRequired,
};

export default ReassignIntakeDialog;
