import { Button, ButtonGroup, FormControlLabel, withStyles } from '@material-ui/core';
import { without } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

import ErrorMessage from 'modules/intake/form/errorMessage.component';
import InputContext from 'modules/intake/form/inputContext';
import styles from 'modules/intake/form/toggleStyles';

class ToggleSelect extends PureComponent {
  static contextType = InputContext;

  handleChange = (selectedValue) => {
    const { multiselect, value } = this.props;
    const { onBlur, onFocus, onChange } = this.context;

    let selected;
    if (multiselect) {
      if (Array.isArray(value) && value.includes(selectedValue)) {
        selected = without(value, selectedValue);
      } else if (Array.isArray(value)) {
        selected = [...value, selectedValue];
      } else {
        selected = [value, selectedValue];
      }
    } else {
      if (value === selectedValue) {
        selected = null;
      } else {
        selected = selectedValue;
      }
    }

    onFocus();
    this.props.onChange({
      target: {
        name: this.props.field.name,
        value: selected,
      },
    });

    this.props.onBlur(this.props.field.name);
    this.props.form.setFieldTouched(this.props.field.name, true);
    onChange();
    onBlur();
  };

  isSelected = (option) => {
    const { multiselect, value } = this.props;
    if (multiselect) {
      return Array.isArray(value) && value.includes(option.id);
    }
    return value === option.id;
  };

  renderGrouped() {
    const { classes, options, disabled, form } = this.props;
    return (
      <ButtonGroup classes={{ root: classes.toggleSelectRoot }} disabled={form.isSubmitting || disabled}>
        {options.map((option) => (
          <Button
            key={option.id}
            classes={{
              root: classNames({ [classes.selectedRoot]: this.isSelected(option) }),
              label: classNames({ [classes.selectedLabel]: this.isSelected(option) }),
            }}
            onClick={() => this.handleChange(option.id)}
          >
            {option.description}
          </Button>
        ))}
      </ButtonGroup>
    );
  }

  renderSpread() {
    const { classes, options, disabled, form } = this.props;
    return (
      <div className={classes.toggleSelectRoot}>
        {options.map((option) => (
          <Button
            key={option.id}
            disabled={form.isSubmitting || disabled}
            classes={{
              root: classNames(classes.toggleButton, { [classes.selectedRoot]: this.isSelected(option) }),
              label: classNames({ [classes.selectedLabel]: this.isSelected(option) }),
            }}
            onClick={() => this.handleChange(option.id)}
            variant="outlined"
          >
            {option.description}
          </Button>
        ))}
      </div>
    );
  }

  render() {
    const { className, field: { name }, disabled, form, label, labelPlacement, variant, classes } = this.props;

    let toggle;
    switch (variant) {
      case 'grouped':
        toggle = this.renderGrouped();
        break;
      case 'spread':
        toggle = this.renderSpread();
        break;
      default:
        return null;
    }

    return (
      <div className={classNames(className, 'form-control-wrapper', classes.container)}>
        <FormControlLabel
          control={toggle}
          label={label}
          labelPlacement={labelPlacement}
          disabled={form.isSubmitting || disabled}
        />
        <ErrorMessage name={name} />
      </div>
    );
  }
}

ToggleSelect.defaultProps = {
  alignCenter: false,
  multiselect: false,
  variant: 'grouped',
  labelPlacement: 'start',
};

ToggleSelect.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  value: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any.isRequired,
    description: PropTypes.string.isRequired,
  })).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  multiselect: PropTypes.bool,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['grouped', 'spread']),
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  alignCenter: PropTypes.bool,
};

export default withStyles(styles)(ToggleSelect);
