export const GET_AUTH_POLICIES_REQUEST = 'GET_AUTH_POLICIES_REQUEST';
export const GET_AUTH_POLICIES_FAILURE = 'GET_AUTH_POLICIES_FAILURE';
export const GET_AUTH_POLICIES_SUCCESS = 'GET_AUTH_POLICIES_SUCCESS';

export const GET_AUTH_POLICY_DETAILS_REQUEST = 'GET_AUTH_POLICY_DETAILS_REQUEST';
export const GET_AUTH_POLICY_DETAILS_FAILURE = 'GET_AUTH_POLICY_DETAILS_FAILURE';
export const GET_AUTH_POLICY_DETAILS_SUCCESS = 'GET_AUTH_POLICY_DETAILS_SUCCESS';

export const ADD_AUTH_POLICY_NOTE_REQUEST = 'ADD_AUTH_POLICY_NOTE_REQUEST';
export const ADD_AUTH_POLICY_NOTE_FAILURE = 'ADD_AUTH_POLICY_NOTE_FAILURE';
export const ADD_AUTH_POLICY_NOTE_SUCCESS = 'ADD_AUTH_POLICY_NOTE_SUCCESS';

export const ADD_AUTH_POLICY_BUDGET_ADDITION_REQUEST = 'ADD_AUTH_POLICY_BUDGET_ADDITION_REQUEST';
export const ADD_AUTH_POLICY_BUDGET_ADDITION_FAILURE = 'ADD_AUTH_POLICY_BUDGET_ADDITION_FAILURE';
export const ADD_AUTH_POLICY_BUDGET_ADDITION_SUCCESS = 'ADD_AUTH_POLICY_BUDGET_ADDITION_SUCCESS';

export const TOGGLE_SENT_TO_VENDOR_REQUEST = 'TOGGLE_SENT_TO_VENDOR_REQUEST';
export const TOGGLE_SENT_TO_VENDOR_FAILURE = 'TOGGLE_SENT_TO_VENDOR_FAILURE';
export const TOGGLE_SENT_TO_VENDOR_SUCCESS = 'TOGGLE_SENT_TO_VENDOR_SUCCESS';

export const GET_POLICY_SUMMARIES_BY_CLIENT_REQUEST = 'GET_POLICY_SUMMARIES_BY_CLIENT_REQUEST';
export const GET_POLICY_SUMMARIES_BY_CLIENT_FAILURE = 'GET_POLICY_SUMMARIES_BY_CLIENT_FAILURE';
export const GET_POLICY_SUMMARIES_BY_CLIENT_SUCCESS = 'GET_POLICY_SUMMARIES_BY_CLIENT_SUCCESS';

export const BenefitStatusEnum = Object.freeze({
  NONE : 'NONE',
  NO_AVAILABLE_OPTIONS: 'NO_AVAILABLE_OPTIONS',
  IN_PROGRESS : 'PENDING',
  HAS_PENDING_SELECTED_OPTIONS: 'OPTION_SELECTED',
  ALL_SELECTED_OPTIONS_CONFIRMED: 'ALL_SELECTED_OPTIONS_CONFIRMED',
  ALL_OPTIONS_CONFIRMED: 'ALL_OPTIONS_CONFIRMED',
  SKIPPED: 'SKIPPED',
});