import { Button, MenuItem, Menu as MuiMenu, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {},
  icon: {
    '& > *:first-child': {
      fontSize: 14,
    },
  },
}));

const Menu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles(props);

  const handleClick = (item) => {
    setAnchorEl(null);
    item.action();
  };

  return (
    <div>
      <Button
        size="large"
        variant="contained"
        color="primary"
        classes={{ root: classes.button, iconSizeLarge: classes.icon }}
        endIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} color="white" />}
        disabled={props.disabled}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {props.label}
      </Button>
      <MuiMenu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={anchorEl != null}
        onClose={() => setAnchorEl(null)}
      >
        {props.items.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => handleClick(item)}
          >
            {item.label}
          </MenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Menu;
