import { withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { IS_RENTED_OPTIONS, YES_NO_OPTIONS, isType } from 'utilities/common';
import { TextField, ToggleSelect } from 'modules/intake/form';
import Hint from 'modules/common/hint.component';
import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class PropertyFeaturesForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.features, nextProps.features);
  }

  render() {
    Log.trace('RENDER', 'PropertyFeaturesForm');
    const {
      features,
      rootPath,
      handleChange,
      handleBlur,
      metadata: {
        basementFinishTypes,
        houseHeatTypes,
        housePoolTypes,
        houseSewageTypes,
        houseWaterSourceTypes,
        solarPanelOwnTypes,
      },
    } = this.props;

    return (
      <>
        <div className="row mb-1">
          <Field
            component={TextField}
            name={`${rootPath}.numberBedrooms`}
            label="Bedrooms"
            value={features.numberBedrooms}
            onChange={handleChange}
            onBlur={handleBlur}
            numeric
          />
          <Field
            component={TextField}
            name={`${rootPath}.numberBathrooms`}
            label="Bathrooms"
            value={features.numberBathrooms}
            onChange={handleChange}
            onBlur={handleBlur}
            numeric
          />
          <Field
            component={TextField}
            name={`${rootPath}.squareFootage`}
            label="Square Feet"
            value={features.squareFootage}
            onChange={handleChange}
            onBlur={handleBlur}
            numeric
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasBasement`}
            label="Basement"
            value={features.hasBasement}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
          />
          <Field
            component={ToggleSelect}
            name={`${rootPath}.basementFinishTypeId`}
            value={features.basementFinishTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            options={values(basementFinishTypes)}
            label="Finished?"
            disabled={!features.hasBasement}
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasGarage`}
            label="Garage"
            value={features.hasGarage}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            alignCenter
          />
          <Field
            component={ToggleSelect}
            name={`${rootPath}.isGarageAttached`}
            value={features.isGarageAttached}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            label="Attached?"
            disabled={!features.hasGarage}
            alignCenter
          />
          <Field
            component={TextField}
            name={`${rootPath}.numberGarageParkingSpots`}
            label="Parking Spaces"
            value={features.numberGarageParkingSpots}
            onChange={handleChange}
            onBlur={handleBlur}
            numeric
            disabled={!features.hasGarage}
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasFireplace`}
            label="Fireplace"
            value={features.hasFireplace}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.houseHeatTypeId`}
            value={features.houseHeatTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            options={values(houseHeatTypes)}
            variant="spread"
            label="Heat Type"
            labelPlacement="top"
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.houseWaterSourceTypeId`}
            value={features.houseWaterSourceTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            options={values(houseWaterSourceTypes)}
            variant="spread"
            label="Water Type"
            labelPlacement="top"
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.houseSewageTypeId`}
            value={features.houseSewageTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            options={values(houseSewageTypes)}
            variant="spread"
            label="Sewer Type"
            labelPlacement="top"
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasUndergroundTank`}
            label="Any Underground Tanks?"
            value={features.hasUndergroundTank}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            alignCenter
          />
          <Field
            component={TextField}
            name={`${rootPath}.undergroundTankLastServiceDate`}
            label="Last Pumped/Serviced"
            value={features.undergroundTankLastServiceDate}
            onChange={handleChange}
            onBlur={handleBlur}
            type="date"
          />
        </div>
        <div className="row mb-1 align-end">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasPool`}
            value={features.hasPool}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            label="Pool?"
          />
          <Field
            component={ToggleSelect}
            name={`${rootPath}.housePoolTypeId`}
            value={features.housePoolTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            options={values(housePoolTypes)}
            label="Type"
            disabled={!features.hasPool}
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasHotTub`}
            label="Hot Tub?"
            value={features.hasHotTub}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasSprinklerSystem`}
            label="Sprinkler System?"
            value={features.hasSprinklerSystem}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasWaterSoftener`}
            value={features.hasWaterSoftener}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            label="Water Softener?"
          />
          <Field
            component={ToggleSelect}
            name={`${rootPath}.isWaterSoftenerRented`}
            value={features.isWaterSoftenerRented}
            onChange={handleChange}
            onBlur={handleBlur}
            options={IS_RENTED_OPTIONS}
            disabled={!features.hasWaterSoftener}
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasAlarmSystem`}
            label="Alarm System"
            value={features.hasAlarmSystem}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            alignCenter
          />
          <Field
            component={TextField}
            name={`${rootPath}.alarmSystemCompany`}
            label="Company"
            value={features.alarmSystemCompany}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!features.hasAlarmSystem}
          />
          <Field
            component={TextField}
            name={`${rootPath}.alarmSystemContractTerm`}
            label="Contract Term"
            value={features.alarmSystemContractTerm}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!features.hasAlarmSystem}
          />
        </div>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasSolarPanels`}
            label="Solar Panels"
            value={features.hasSolarPanels}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
          />
          <Field
            component={ToggleSelect}
            name={`${rootPath}.solarPanelOwnTypeId`}
            label="Type"
            value={features.solarPanelOwnTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            options={values(solarPanelOwnTypes)}
            disabled={!features.hasSolarPanels}
          />
          <Field
            component={ToggleSelect}
            name={`${rootPath}.isSolarPanelStayingWithProperty`}
            label="Staying With Property"
            value={features.isSolarPanelStayingWithProperty}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            disabled={!features.hasSolarPanels}
          />
          {isType('LEASE', features.solarPanelOwnTypeId, solarPanelOwnTypes) &&
            <Hint>Provide copy of contract to agent</Hint>
          }
        </div>
      </>
    );
  }
}

PropertyFeaturesForm.propTypes = {
  features: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyFeaturesForm);
