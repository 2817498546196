import { withStyles } from '@material-ui/core/styles';

import { LocalDate, TemporalAdjusters } from 'js-joda';
import { compose } from 'recompose';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { isRecent } from 'utilities/common';
import Calendar from 'modules/common/calendar.component';
import Log from 'utilities/log';

import Colors from './colors';

const styles = (theme) => ({
});

const getMonth = (date) => {
  if (date) {
    try {
      const parsedDate = LocalDate.parse(date);
      if (isRecent(parsedDate)) {
        return { month: parsedDate.monthValue(), year: parsedDate.year() };
      }
    } catch (err) {
      return null;
    }
  }
  return null;
};

const getMonthRange = (startDate, endDate) => {
  if (!startDate) {
    return [getMonth(endDate)];
  } else if (!endDate) {
    return [getMonth(startDate)];
  }

  const months = [];
  const startMonth = LocalDate.parse(startDate).with(TemporalAdjusters.firstDayOfMonth());
  const endMonth = LocalDate.parse(endDate).with(TemporalAdjusters.firstDayOfMonth());

  let currentMonth = startMonth;
  while (currentMonth.compareTo(endMonth) <= 0) {
    if (isRecent(currentMonth)) {
      months.push({ month: currentMonth.monthValue(), year: currentMonth.year() });
    }
    currentMonth = currentMonth.plusMonths(1);
  }

  return months;
};

const getMarker = (startDate, endDate) => {
  if (startDate) {
    try {
      const parsedStartDate = LocalDate.parse(startDate);
      if (isRecent(parsedStartDate)) {
        if (endDate) {
          const parsedEndDate = LocalDate.parse(endDate);
          if (parsedEndDate.compareTo(parsedStartDate) >= 0) {
            return { startDate, endDate };
          }
        } else {
          return { startDate };
        }
      }
    } catch (err) {
      return null;
    }
  }
  return null;
};

class BenefitsTimelineCalendar extends PureComponent {
  getAuthorizationYearMonths = () => {
    const { authorizationDate } = this.props;
    const parsedAuthDate = LocalDate.parse(authorizationDate);
    return new Array(13).fill(0, 0, 13).map((_, index) => {
      const month = parsedAuthDate.plusMonths(index);
      return { month: month.monthValue(), year: month.year() };
    });
  };

  getRequiredMonths = () => {
    const {
      newJobStartDate,
      benefits: {
        relocationAllowance: { paymentDate },
        candidateIntern: {
          interviewStartDate,
          interviewEndDate,
          travelStartDate: candidateInternTravelStartDate,
          travelEndDate: candidateInternTravelEndDate,
          internProposedStartDate,
          internProposedEndDate,
        },
        homesaleProcess: {
          sendAgentInfoDate,
          preferredBmaAppointmentDate,
          listingStartDate,
          listingEndDate,
          inspectionStartDate,
          appraisalStartDate,
          approximateAcquisitionDate,
        },
        householdGoods: {
          preferredSurveyDate,
          preferredPackDate,
          possibleStorageStartDate,
          possibleStorageEndDate,
          preferredDeliveryDate,
        },
        temporaryLiving: {
          estimatedStartDate,
          estimatedEndDate,
        },
        houseHunting: {
          travelStartDate,
          travelEndDate,
        },
        finalMove: {
          transfereeTravelStartDate,
          transfereeTravelEndDate,
          familyTravelStartDate,
          familyTravelEndDate,
        },
        spousalAssistance: {
          estimatedDate,
        },
        miscellaneous: {
          miscTimelineEntries,
        },
      },
    } = this.props;

    const monthData = [
      ...this.getAuthorizationYearMonths(),
      getMonth(newJobStartDate),
      getMonth(paymentDate),
      getMonth(sendAgentInfoDate),
      getMonth(preferredBmaAppointmentDate),
      ...getMonthRange(listingStartDate, listingEndDate),
      getMonth(inspectionStartDate),
      getMonth(appraisalStartDate),
      getMonth(approximateAcquisitionDate),
      getMonth(preferredSurveyDate),
      getMonth(preferredPackDate),
      ...getMonthRange(possibleStorageStartDate, possibleStorageEndDate),
      getMonth(preferredDeliveryDate),
      ...getMonthRange(estimatedStartDate, estimatedEndDate),
      ...getMonthRange(travelStartDate, travelEndDate),
      ...getMonthRange(transfereeTravelStartDate, transfereeTravelEndDate),
      ...getMonthRange(familyTravelStartDate, familyTravelEndDate),
      getMonth(estimatedDate),
      ...getMonthRange(interviewStartDate, interviewEndDate),
      ...getMonthRange(candidateInternTravelStartDate, candidateInternTravelEndDate),
      ...getMonthRange(internProposedStartDate, internProposedEndDate),
    ];

    const additionalMonths = miscTimelineEntries.reduce((monthList, { description, startDate, endDate }) => {
      return description ? [...monthList, ...getMonthRange(startDate, endDate)]: monthList;
    }, []);

    const filtered = [...monthData, ...additionalMonths].filter((instance) => instance !== null);
    return uniqBy(filtered, (instance) => `${instance.month}/${instance.year}`)
      .sort((a, b) => {
        const dateA = LocalDate.of(a.year, a.month, 1);
        const dateB = LocalDate.of(b.year, b.month, 1);
        return dateA.compareTo(dateB);
      });
  };

  getMarkers = () => {
    const {
      benefits: {
        relocationAllowance: { paymentDate },
        candidateIntern: {
          interviewStartDate,
          interviewEndDate,
          travelStartDate: candidateInternTravelStartDate,
          travelEndDate: candidateInternTravelEndDate,
          internProposedStartDate,
          internProposedEndDate,
        },
        homesaleProcess: {
          sendAgentInfoDate,
          preferredBmaAppointmentDate,
          listingStartDate,
          listingEndDate,
          inspectionStartDate,
          appraisalStartDate,
          approximateAcquisitionDate,
        },
        householdGoods: {
          preferredSurveyDate,
          preferredPackDate,
          possibleStorageStartDate,
          possibleStorageEndDate,
          preferredDeliveryDate,
        },
        temporaryLiving: {
          estimatedStartDate,
          estimatedEndDate,
        },
        houseHunting: {
          travelStartDate,
          travelEndDate,
        },
        finalMove: {
          transfereeTravelStartDate,
          transfereeTravelEndDate,
          familyTravelStartDate,
          familyTravelEndDate,
        },
        spousalAssistance: {
          estimatedDate,
        },
        miscellaneous: {
          miscTimelineEntries,
        },
      },
    } = this.props;

    const relocationAllowanceDates = [
      getMarker(paymentDate),
    ].filter((instance) => instance !== null);

    const candidateInternDates = [
      getMarker(interviewStartDate, interviewEndDate),
      getMarker(candidateInternTravelStartDate, candidateInternTravelEndDate),
      getMarker(internProposedStartDate, internProposedEndDate),
    ].filter((instance) => instance !== null);

    const homesaleProcessDates = [
      getMarker(sendAgentInfoDate),
      getMarker(preferredBmaAppointmentDate),
      getMarker(listingStartDate, listingEndDate),
      getMarker(inspectionStartDate),
      getMarker(appraisalStartDate),
      getMarker(approximateAcquisitionDate),
    ].filter((instance) => instance !== null);

    const hhgDates = [
      getMarker(preferredSurveyDate),
      getMarker(preferredPackDate),
      getMarker(possibleStorageStartDate, possibleStorageEndDate),
      getMarker(preferredDeliveryDate),
    ].filter((instance) => instance !== null);

    const temporaryLivingDates = [
      getMarker(estimatedStartDate, estimatedEndDate),
    ].filter((instance) => instance !== null);

    const houseHuntingDates = [
      getMarker(travelStartDate, travelEndDate),
    ].filter((instance) => instance !== null);

    const finalMoveDates = [
      getMarker(transfereeTravelStartDate, transfereeTravelEndDate),
      getMarker(familyTravelStartDate, familyTravelEndDate),
    ].filter((instance) => instance !== null);

    const spousalAssistanceDates = [
      getMarker(estimatedDate),
    ].filter((instance) => instance !== null);

    const miscellaneousDates = miscTimelineEntries.map(({ description, startDate, endDate }) => {
      return description ? getMarker(startDate, endDate): null;
    }).filter((instance) => instance !== null);

    return [{
      color: Colors.relocationAllowance,
      dates: relocationAllowanceDates,
    }, {
      color: Colors.candidateIntern,
      dates: candidateInternDates,
    }, {
      color: Colors.houseHunting,
      dates: houseHuntingDates,
    }, {
      color: Colors.homesaleProcess,
      dates: homesaleProcessDates,
    }, {
      color: Colors.finalMove,
      dates: finalMoveDates,
    }, {
      color: Colors.temporaryLiving,
      dates: temporaryLivingDates,
    }, {
      color: Colors.householdGoods,
      dates: hhgDates,
    }, {
      color: Colors.spousalAssistance,
      dates: spousalAssistanceDates,
    }, {
      color: Colors.miscellaneous,
      dates: miscellaneousDates,
    }];
  };

  render() {
    Log.trace('RENDER', 'BenefitsTimelineCalendar');

    const monthsToShow = this.getRequiredMonths();
    const markers = this.getMarkers();

    return (
      <>
        {monthsToShow.map(({ month, year }, i) => (
          <Calendar
            key={i}
            month={month}
            year={year}
            markers={markers}
          />
        ))}
      </>
    );
  }
}

BenefitsTimelineCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
  newJobStartDate: PropTypes.string,
  authorizationDate: PropTypes.string,
  benefits: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
)(BenefitsTimelineCalendar);
