import { object, string } from 'yup';

import { formatForWire, isNotDefined, parseNumbers } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, REQUIRED_STRING } from 'modules/common/constants';

export default class PropertyCreditBalance {
  static schema = object().shape({
    name: string().max(300, MAX_LENGTH_ERROR_MESSAGE),
    propertyCreditBalanceTypeId: REQUIRED_STRING,
    balance: REQUIRED_STRING,
    rate: REQUIRED_STRING,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.name = json.name || '';
    this.propertyCreditBalanceTypeId = isNotDefined(json.propertyCreditBalanceTypeId) ? '':
      json.propertyCreditBalanceTypeId;
    this.balance = json.balance || '';
    this.rate = json.rate || '';
  }

  static fromWireData(wireData) {
    return new PropertyCreditBalance(wireData);
  }

  toWireData() {
    return formatForWire({
      name: this.name,
      propertyCreditBalanceTypeId: this.propertyCreditBalanceTypeId,
      ...parseNumbers({
        balance: this.balance,
        rate: this.rate,
      }),
    });
  }
}
