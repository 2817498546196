import { Grid, Typography, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Checkbox, TextField, ToggleSelect } from 'modules/intake/form';
import { RelocationAllowanceBenefits } from 'modules/intake/models';
import { YES_NO_OPTIONS } from 'utilities/common';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';

import ColorIndicator from './colorIndicator.component';
import Colors from './colors';

const styles = () => ({});

class RelocationAllowanceForm extends Component {

  state = {
    showConfirmation: false,
  };

  handleDisableService = () => {
    this.props.setFieldValue('benefits.relocationAllowance', new RelocationAllowanceBenefits({ hasRelocationAllowance: false }), false);
    this.setState({ showConfirmation: false });
  };

  render() {
    const { relocationAllowance, handleBlur, handleChange, rootPath } = this.props;
    const { showConfirmation } = this.state;

    return (
      <div className="form-group">
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography gutterBottom variant="h3" className={relocationAllowance.hasRelocationAllowance ? '' : 'disabled'}>
              Relocation Allowance
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Field
              component={ToggleSelect}
              name={`${rootPath}.relocationAllowance.hasRelocationAllowance`}
              value={relocationAllowance.hasRelocationAllowance}
              onChange={(event) => {
                if (relocationAllowance.hasRelocationAllowance === event.target.value ||
                  event.target.value === null)
                  return;
                if (relocationAllowance.hasRelocationAllowance && !event.target.value) {
                  this.setState({ showConfirmation: true });
                } else {
                  handleChange(event);
                }
              }}
              onBlur={handleBlur}
              options={YES_NO_OPTIONS}
            />
          </Grid>
        </Grid>
        {relocationAllowance.hasRelocationAllowance && (
          <>
            <div className="row">
              <Field
                component={Checkbox}
                name={`${rootPath}.relocationAllowance.didExplain`}
                value={relocationAllowance.didExplain}
                onChange={handleChange}
                prelocation
                onBlur={handleBlur}
                label="Explain purpose, provide suggestions"
              />
            </div>
            <div className="row">
              <Field
                component={TextField}
                name={`${rootPath}.relocationAllowance.relocationAmount`}
                label="Allowance Amount"
                value={relocationAllowance.relocationAmount}
                onChange={handleChange}
                onBlur={handleBlur}
                allowDecimal={true}
                numeric
                prefix="$"
              />
            </div>
            <div className="row">
              <Field
                component={TextField}
                name={`${rootPath}.relocationAllowance.paymentDate`}
                label="Payment Date"
                value={relocationAllowance.paymentDate}
                onChange={handleChange}
                onBlur={handleBlur}
                type="date"
              />
              <ColorIndicator color={Colors.relocationAllowance} />
            </div>
          </>
        )}
        <ConfirmationDialog
          open={showConfirmation}
          title="Disable Option?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleDisableService}
          onCancel={() => this.setState({ showConfirmation: false })}
        >
          <Typography>
            If you disable this option, you will lose all data entered in this section.{' '}
            <br />
            <br />
            Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

RelocationAllowanceForm.propTypes = {
  classes: PropTypes.object.isRequired,
  relocationAllowance: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(RelocationAllowanceForm);
