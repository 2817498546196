import { Typography, makeStyles } from '@material-ui/core';

import { LocalDate } from 'js-joda';
import { get } from 'lodash';
import { isCandidateMoveType, isInternMoveType } from 'modules/intake/models/authorizationInformation';
import PropTypes from 'prop-types';
import React from 'react';

import { formatLocalDate, isRecent } from 'utilities/common';

const CANDIDATEINTERNDATES = [
  { description: 'New Job Start', path: 'primaryInformation.newJobStartDate' },
  { description: 'Allowance Payment', path: 'benefits.relocationAllowance.paymentDate' },
  { description: 'Candidate/Intern Interview Start', path: 'benefits.candidateIntern.interviewStartDate' },
  { description: 'Candidate/Intern Interview End', path: 'benefits.candidateIntern.interviewEndDate' },
  { description: 'Candidate/Intern Travel Start', path: 'benefits.candidateIntern.travelStartDate' },
  { description: 'Candidate/Intern End', path: 'benefits.candidateIntern.travelEndDate' },
  { description: 'Candidate/Intern Proposed Start', path: 'benefits.candidateIntern.internProposedStartDate' },
  { description: 'Candidate/Intern Proposed End', path: 'benefits.candidateIntern.internProposedEndDate' },
  { description: 'Preferred Survey', path: 'benefits.householdGoods.preferredSurveyDate' },
  { description: 'Preferred Pack & Load', path: 'benefits.householdGoods.preferredPackDate' },
  { description: 'Estimated Storage In', path: 'benefits.householdGoods.possibleStorageStartDate' },
  { description: 'Estimated Storage Out', path: 'benefits.householdGoods.possibleStorageEndDate' },
  { description: 'Goal Delivery', path: 'benefits.householdGoods.preferredDeliveryDate' },
  { description: 'Estimated Temp Living Start', path: 'benefits.temporaryLiving.estimatedStartDate' },
  { description: 'Estimated Temp Living End', path: 'benefits.temporaryLiving.estimatedEndDate' },
  { description: 'House Hunting Travel Start', path: 'benefits.houseHunting.travelStartDate' },
  { description: 'House Hunting Travel End', path: 'benefits.houseHunting.travelEndDate' },
  { description: 'Transferee Move Travel Start', path: 'benefits.finalMove.transfereeTravelStartDate' },
  { description: 'Transferee Move Travel End', path: 'benefits.finalMove.transfereeTravelEndDate' },
  { description: 'Family Move Travel Start', path: 'benefits.finalMove.familyTravelStartDate' },
  { description: 'Family Move Travel End', path: 'benefits.finalMove.familyTravelEndDate' },
  { description: 'Spousal Assistance', path: 'benefits.spousalAssistance.estimatedDate' },
];

const DATES = [
  { description: 'New Job Start', path: 'primaryInformation.newJobStartDate' },
  { description: 'Allowance Payment', path: 'benefits.relocationAllowance.paymentDate' },
  { description: 'Send Agent Info', path: 'benefits.homesaleProcess.sendAgentInfoDate' },
  { description: 'Preferred BMA Appt', path: 'benefits.homesaleProcess.preferredBmaAppointmentDate' },
  { description: 'Goal for Listing', path: 'benefits.homesaleProcess.listingStartDate' },
  { description: 'Listing End', path: 'benefits.homesaleProcess.listingEndDate' },
  { description: 'Inspection Start', path: 'benefits.homesaleProcess.inspectionStartDate' },
  { description: 'Appraisal Start', path: 'benefits.homesaleProcess.appraisalStartDate' },
  { description: 'Approx Acquisition', path: 'benefits.homesaleProcess.approximateAcquisitionDate' },
  { description: 'Preferred Survey', path: 'benefits.householdGoods.preferredSurveyDate' },
  { description: 'Preferred Pack & Load', path: 'benefits.householdGoods.preferredPackDate' },
  { description: 'Estimated Storage In', path: 'benefits.householdGoods.possibleStorageStartDate' },
  { description: 'Estimated Storage Out', path: 'benefits.householdGoods.possibleStorageEndDate' },
  { description: 'Goal Delivery', path: 'benefits.householdGoods.preferredDeliveryDate' },
  { description: 'Estimated Temp Living Start', path: 'benefits.temporaryLiving.estimatedStartDate' },
  { description: 'Estimated Temp Living End', path: 'benefits.temporaryLiving.estimatedEndDate' },
  { description: 'House Hunting Travel Start', path: 'benefits.houseHunting.travelStartDate' },
  { description: 'House Hunting Travel End', path: 'benefits.houseHunting.travelEndDate' },
  { description: 'Transferee Move Travel Start', path: 'benefits.finalMove.transfereeTravelStartDate' },
  { description: 'Transferee Move Travel End', path: 'benefits.finalMove.transfereeTravelEndDate' },
  { description: 'Family Move Travel Start', path: 'benefits.finalMove.familyTravelStartDate' },
  { description: 'Family Move Travel End', path: 'benefits.finalMove.familyTravelEndDate' },
  { description: 'Spousal Assistance', path: 'benefits.spousalAssistance.estimatedDate' },
  { description: 'Estimate Start Date', path: 'primaryInformation.estimatedStartDate' },
  { description: 'Estimate End Date', path: 'primaryInformation.estimatedEndDate' },
];

const parseDate = (date) => {
  try {
    return LocalDate.parse(date);
  } catch (err) {
    // failed to parse, expected sometimes
    return null;
  }
};

const useStyles = makeStyles((theme) => ({
  dateValue: {
    fontWeight: 500,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
}));

const isCandidateOrInternType = (values, metadata) => {
  const moveType = values.authorizationInformation.moveTypeId;
  if (isCandidateMoveType(moveType, metadata.moveTypes) || isInternMoveType(moveType, metadata.moveTypes))
  {
    return true;
  }
  return false;
};

const getDates = (values, metadata) => {
  const isCandidateorIntern = isCandidateOrInternType(values, metadata);
  if (isCandidateorIntern)
  {
    const dates = CANDIDATEINTERNDATES.reduce((parsedDates, { description, path }) => {
      const value = get(values, path, null);
      if (typeof value === 'string') {
        const date = parseDate(value);
        return (date && isRecent(date)) ? [ ...parsedDates, { description, date }]: parsedDates;
      }
      return parsedDates;
    }, []);

    // special case for miscellaneous dates
    get(values, 'benefits.miscellaneous.miscTimelineEntries', []).forEach(({ description, startDate, endDate }) => {
      if (description && startDate) {
        const date = parseDate(startDate);
        if (date && isRecent(date)) {
          dates.push({ description: `${description} - Start`, date });
        }
      }
      if (description && endDate) {
        const date = parseDate(endDate);
        if (date && isRecent(date)) {
          dates.push({ description: `${description} - End`, date });
        }
      }
    });

    return dates.sort((a, b) => a.date.compareTo(b.date)); 
  }
  else {
    const dates = DATES.reduce((parsedDates, { description, path }) => {
      const value = get(values, path, null);
      if (typeof value === 'string') {
        const date = parseDate(value);
        return (date && isRecent(date)) ? [ ...parsedDates, { description, date }]: parsedDates;
      }
      return parsedDates;
    }, []);

    // special case for miscellaneous dates
    get(values, 'benefits.miscellaneous.miscTimelineEntries', []).forEach(({ description, startDate, endDate }) => {
      if (description && startDate) {
        const date = parseDate(startDate);
        if (date && isRecent(date)) {
          dates.push({ description: `${description} - Start`, date });
        }
      }
      if (description && endDate) {
        const date = parseDate(endDate);
        if (date && isRecent(date)) {
          dates.push({ description: `${description} - End`, date });
        }
      }
    });

    return dates.sort((a, b) => a.date.compareTo(b.date));
  }
};

function BenefitsTimelineDates({ values, metadata }) {
  const classes = useStyles();

  return (
    <>
      <Typography gutterBottom variant="h4">Important Dates</Typography>
      {getDates(values, metadata).map((instance) => (
        <div key={instance.description} className="row align-center justify-space-between mb-1">
          <Typography>{instance.description}</Typography>
          <Typography className={classes.dateValue}>{formatLocalDate(instance.date)}</Typography>
        </div>
      ))}
    </>
  );
}

BenefitsTimelineDates.propTypes = {
  values: PropTypes.object.isRequired,
};

export default BenefitsTimelineDates;
