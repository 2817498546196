import { memoize } from 'lodash';
import React, { Component } from 'react';

export const cachedWithRouteOnEnter = memoize((component, callback) => {
  return withRouteOnEnter(callback)(component);
});

export const withRouteOnEnter = (callback) => (BaseComponent) => {
  const routeOnEnterCallback = (props) => {
    if (callback && typeof callback === 'function') {
      callback(props);
    }
  };

  class routeOnEnterComponent extends Component {
    componentWillMount() {
      routeOnEnterCallback(this.props);
    }

    componentWillReceiveProps(nextProps) {
      // eslint-disable-next-line react/prop-types
      if (nextProps.location !== this.props.location) {
        routeOnEnterCallback(nextProps);
      }
    }

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  return routeOnEnterComponent;
};