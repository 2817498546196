export default {
  today: '#6E4191',
  newJobStartDate: '#DD4126',
  relocationAllowance: '#F26227',
  candidateIntern: '#673AB7',
  houseHunting: '#8CBA80',
  homesaleProcess: '#35959C',
  finalMove: '#42CAFD',
  temporaryLiving: '#8693AB',
  householdGoods: '#F1B11A',
  spousalAssistance: '#D11149',
  miscellaneous: '#274C77',
};
