import { Box, Button, Grid, MenuItem, Paper, Typography, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { values } from 'lodash';
import Log from 'utilities/log';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Checkbox, ChipSelect, Select, TextField } from 'modules/intake/form';
import { SECTIONS, ValidationMode } from 'modules/intake/constants';
import { hideToast, showToast } from 'modules/layout/layout.actions';
import { setValidationMode } from 'modules/intake/intake.actions';
import Hint from 'modules/common/hint.component';
import Spinner from 'modules/common/spinner.component';

import { ERROR } from './errorLevels';
import { mortgageLeadsAreValid } from '../../utils';
import VendorLetterAlertCard from './vendorLetterAlertCard.component';
import MortgageLeadsAlertCard from './mortgageLeadsAlertCard.component';
import ValidationAlertCard from './validationAlertCard.component';

const styles = (theme) => ({
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  card: {
    padding: theme.spacing(2),
  },
  cardsContainer: {
    columnCount: 3,
    columnGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      columnCount: 1,
    },
  },
  cardContainer: {
    '-webkit-column-break-inside': 'avoid',
    pageBreakInside: 'avoid',
    breakInside: 'avoid',
    marginBottom: theme.spacing(2),
  },
  mortgageLeads: {
    paddingTop: 40,
  },
  tabBorder: {
    borderLeft: `2px solid ${theme.palette.grey[400]}`,
    marginLeft: theme.spacing(2),
  },
});

const typeOfRelocation = [{ id: 3, description: 'Goverment' }, { id: 2, description: 'Corporate' }, { id: 1, description: 'Unkown' }];
const status = [{ id: 1, description: 'Home Owner' }, { id: 2, description: 'Renter' }, { id: 3, description: 'Unknown' }];
const closingCostMethods = [{ id: 1, description: 'Direct Bill' }, { id: 2, description: 'Lump Sum' }, { id: 3, description: 'Reimbursement' }, { id: 4, description: 'None' }, { id: 5, description: 'Unknown' }];

class Summary extends PureComponent {
  state = {
    didSubmit: false,
  };

  componentDidMount() {
    Log.trace('Summary Mounted', 'Summary');

    this.props.setValidationMode(ValidationMode.Strict);
    setTimeout(async () => {
      await this.props.submitForm();
      this.setState({ didSubmit: true });
    });
  } 

  render() {
    const { classes, isValidating, validationResults, failedPrimaryValidation, isValid, onSectionFocus, submitForm, errors, moveTypeId, mortgageVendors, vendorLetters, handleChange, handleBlur, hhgInitiated, tempLivingInitiated, newDestination } = this.props;
    const { didSubmit } = this.state;

    let mortgageLeadErrors = [];
    if (moveTypeId === 1 && mortgageVendors.dynamicVendors.length > 0)
    {
      if (moveTypeId === 1 && !mortgageLeadsAreValid(mortgageVendors, moveTypeId)) mortgageLeadErrors.push({ errorMessage: 'Mortgage lead reason cannot be empty.' });
      if (moveTypeId === 1 && mortgageVendors && !mortgageVendors.closingCostMethod) mortgageLeadErrors.push({ errorMessage: 'Closing Cost Method must be selected.' });
      if (moveTypeId === 1 && mortgageVendors && !mortgageVendors.typeOfRelocation) mortgageLeadErrors.push({ errorMessage: 'Type of Relocation must be selected.' });
      if (moveTypeId === 1 && mortgageVendors && !mortgageVendors.statusOfTransferee) mortgageLeadErrors.push({ errorMessage: 'Status must be selected.' });
      if (moveTypeId === 1 && mortgageVendors && !mortgageVendors.directBillEligible) mortgageLeadErrors.push({ errorMessage: 'Is Direct Bill Eligible must be selected.' });
      if (moveTypeId === 1 && mortgageVendors && mortgageVendors.benefitsCap && !mortgageVendors.amountOfCap) mortgageLeadErrors.push({ errorMessage: 'Cap Amount must be entered.' });
    }
    else
    {
      if (moveTypeId === 1 && !mortgageLeadsAreValid(mortgageVendors, moveTypeId)) mortgageLeadErrors.push({ errorMessage: 'Mortgage lead reason cannot be empty.' });
    }

    let vendorLetterErrors = [];
    if (moveTypeId === 1 && vendorLetters.dynamicLetters.length === 0 && (!vendorLetters.reason || (vendorLetters.reason && vendorLetters.reason.length === 0))) {
      vendorLetterErrors.push({errorMessage: 'Vendor letter reason cannot be empty.'})
    }
           
    const renderMortgageLeads = () => {
      if (moveTypeId === 1) {
        return (
          <div className={classes.mortgageLeads}>
            <Typography gutterBottom variant="h3">Mortgage Leads</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Field
                  component={ChipSelect}
                  name={'mortgageVendors.dynamicVendors'}
                  label="Mortgage Leads"
                  value={mortgageVendors.dynamicVendors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelAccessor={(value) => (value.name)}
                  fullWidth
                  labelWidth={82}
                >
                  {values(mortgageVendors.staticVendors).map((type) => (
                    <MenuItem key={type.id} value={type}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* {mortgageVendors.dynamicVendors.length === 3 && (
                  <Field
                    component={TextField}
                    name="mortgageVendors.message"
                    label="Message"
                    value={mortgageVendors.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                )} */}
                {mortgageVendors.dynamicVendors.length < 3 && (
                  <div>
                    <Field
                      component={TextField}
                      name="mortgageVendors.reason"
                      label="Reason for Deselection"
                      value={mortgageVendors.reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                    />
                    <Hint>Explain your reason for deselecting the mortgage lender (required).</Hint>
                  </div>
                )}
              </Grid>
            </Grid>
            <div className={classes.tabBorder}>
              <Box sx={{ marginLeft: '16px' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <Field
                      component={Select}
                      name="mortgageVendors.closingCostMethod"
                      label="Closing Cost Method"
                      value={mortgageVendors.closingCostMethod}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                    >
                      {values(closingCostMethods).map((method) => (
                        <MenuItem key={method.id} value={method.id}>{method.description}</MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      component={Select}
                      name="mortgageVendors.typeOfRelocation"
                      label="Type of Relocation"
                      value={mortgageVendors.typeOfRelocation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                    >
                      {values(typeOfRelocation).map((method) => (
                        <MenuItem key={method.id} value={method.id}>{method.description}</MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      component={TextField}
                      name="mortgageVendors.coApplicantEmail"
                      label="Co-Applicant Email"
                      value={mortgageVendors.coApplicantEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      component={TextField}
                      name="mortgageVendors.coApplicantPhone"
                      label="Co-Applicant Phone"
                      value={mortgageVendors.coApplicantPhone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      component={Select}
                      name="mortgageVendors.statusOfTransferee"
                      label="Status"
                      value={mortgageVendors.statusOfTransferee}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                    >
                      {values(status).map((method) => (
                        <MenuItem key={method.id} value={method.id}>{method.description}</MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      component={Select}
                      name="mortgageVendors.directBillEligible"
                      label="Is Direct Bill Eligible"
                      value={mortgageVendors.directBillEligible}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                    >
                      <MenuItem key={''} value={''}>{''}</MenuItem>
                      <MenuItem key={'No'} value={'No'}>{'No'}</MenuItem>
                      <MenuItem key={'Yes'} value={'Yes'}>{'Yes'}</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Box sx={{ margin: '10px' }}>
                      <Field
                        component={Checkbox}
                        name={'mortgageVendors.spanishSpeaking'}
                        value={mortgageVendors.spanishSpeaking}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Is Spanish Speaking"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Box sx={{ margin: '10px' }}>
                      <Field
                        component={Checkbox}
                        name={'mortgageVendors.benefitsCap'}
                        value={mortgageVendors.benefitsCap}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Has Benefits Cap"
                      />
                    </Box>
                  </Grid>
                  {mortgageVendors.benefitsCap && 
                    <Grid item xs={12} md={2}>
                      <Field
                        component={TextField}
                        name="mortgageVendors.amountOfCap"
                        label="Cap Amount"
                        value={mortgageVendors.amountOfCap}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        allowDecimal={true}
                        numeric
                        prefix="$"
                      />
                    </Grid>
                  }
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      name="mortgageVendors.message"
                      label="Comments"
                      value={mortgageVendors.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      minRows={5}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        );
      }
    };

    ///////////// VendorLetters  //////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////    
    const renderVendorLetters = () => {  

      if (moveTypeId === 1) {
        return (
          <div className={classes.mortgageLeads}>
            <Typography gutterBottom variant="h3">Vendor Letters</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Field
                  component={ChipSelect}
                  name={'vendorLetters.dynamicLetters'}
                  label="Vendor Letters"
                  value={vendorLetters.dynamicLetters}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelAccessor={(value) => (value.name)}
                  fullWidth
                  labelWidth={82}
                >  
                  {values(vendorLetters.staticLetters).map((type) => (
                    <MenuItem key={type.id} value={type}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                {vendorLetters.dynamicLetters.length === 0 && (
                  <div>
                    <Field
                      component={TextField}
                      name="vendorLetters.reason"
                      label="Vendor Letter Reason"
                      value={vendorLetters.reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                    />
                    <Hint>Explain your reason for not selecting any letters (required).</Hint>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        );
      }
    };

    if (isValidating || !didSubmit) {
      return (
        <div className={classes.spinnerContainer}>
          <Spinner logo />
        </div>
      );
    }

    if (hhgInitiated || tempLivingInitiated)
    {
      if (newDestination.isRenting || newDestination.isPurchasing)
      {
        if (isValid && mortgageLeadErrors.length === 0 && vendorLetterErrors.length === 0) {
          return (
            <div className={classes.container}>
              <Paper className={classes.card}>
                <Typography variant="h6">No validation errors!</Typography>
              </Paper>
              {renderMortgageLeads()}
              {renderVendorLetters()}
            </div>
          );
        }
      }
      else
      {
        return (
          <div className={classes.container}>
            <Paper className={classes.card}>
              <Typography variant="h6">HHG Service and/or Temporary Living Service have been authorized. Please update the New Destination information before submitting!</Typography>
            </Paper>
          </div>
        );
      }
    }

    if (isValid && mortgageLeadErrors.length === 0 && vendorLetterErrors.length === 0) {
      return (
        <div className={classes.container}>
          <Paper className={classes.card}>
            <Typography variant="h6">No validation errors!</Typography>
          </Paper>
          {renderMortgageLeads()}
          {renderVendorLetters()}
        </div>
      );
    }

    if (!validationResults) {
      return (
        <div className={classes.container}>
          <Paper className={classes.card}>
            <Typography variant="h6" color="error" gutterBottom>
              Failed to validate record
            </Typography>
            <Button onClick={submitForm} variant="contained" color="primary">
              Try Again
            </Button>
          </Paper>
        </div>
      );
    }

    if (failedPrimaryValidation) {
      if (Object.keys(errors).length > 0) {
        return (
          <div className={classes.container}>
            <Paper className={classes.card}>
              <Typography variant="h6" color="error" gutterBottom>
                Unable to submit due to missing information
              </Typography>
              <Typography variant="h6" color="error" gutterBottom>
                Please check that minimum required data is entered
              </Typography>
            </Paper>
          </div>
        );
      }
    }

    const results = validationResults
      .filter((instance) => !instance.result.isValid)
      .map((instance) => ({
        section: SECTIONS.find((section) => section.group === instance.name),
        errors: instance.result.errors,
      }));

    
    return (
      <div className={classes.container}>
        <div className={classes.cardsContainer}>
          {results.map((resultGroup, i) => {
            if (resultGroup.section.label !== 'Vendor Letters') {
              return (
                <div className={classes.cardContainer} key={i}>
                  <ValidationAlertCard
                    level={ERROR}
                    groupLabel={resultGroup.section.label}
                    errors={resultGroup.errors}
                    onClick={() => onSectionFocus(resultGroup.section)}
                  />
                </div>
              )
            } else {
              return null;
            }
          })}
          {mortgageLeadErrors.length !== 0 && (
            <div className={classes.cardContainer}>
              <MortgageLeadsAlertCard
                errors={mortgageLeadErrors}
              />
            </div>
          )}
          {vendorLetterErrors.length !== 0 && (
            <div className={classes.cardContainer}>
              <VendorLetterAlertCard
                errors={vendorLetterErrors}
              />
            </div>
          )}
        </div>
        {renderMortgageLeads()}
        {renderVendorLetters()}
      </div>
    );
  }
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
  isValidating: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  failedPrimaryValidation: PropTypes.bool.isRequired,
  validationResults: PropTypes.array,
  onSectionFocus: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  hideToast: PropTypes.func.isRequired,
  setValidationMode: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  moveTypeId: PropTypes.number,
  mortgageVendors: PropTypes.object,
  vendorLetters: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { isValidating, validationResults } = state.intake;
  return {
    isValidating,
    validationResults,
  };
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      showToast,
      hideToast,
      setValidationMode,
    },
  ),
)(Summary);