import * as Sentry from '@sentry/browser';
import produce from 'immer';

import {
  GET_USER_PROFILE_FAILURE, GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS,
} from './types';

const initialState = {
  isLoading: false,
  profile: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
      draft.isLoading = true;
      break;

    case GET_USER_PROFILE_SUCCESS:
      draft.isLoading = false;
      draft.profile = action.response;
      Sentry.setUser(draft.profile);
      break;

    case GET_USER_PROFILE_FAILURE:
      draft.isLoading = false;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
