import { object, string } from 'yup';

import { formatForWire } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE } from 'modules/common/constants';

export default class Address {
  static schema = object().shape({
    city: string().max(40, MAX_LENGTH_ERROR_MESSAGE),
    stateCode: string().max(3, MAX_LENGTH_ERROR_MESSAGE),
    postalCode: string().max(10, MAX_LENGTH_ERROR_MESSAGE),
    countryCode: string().max(3, MAX_LENGTH_ERROR_MESSAGE),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.address1 = json.address1 || '';
    this.address2 = json.address2 || '';
    this.city = json.city || '';
    this.stateCode = json.stateCode || '';
    this.postalCode = json.postalCode || '';
    this.countryCode = json.countryCode || '';
  }

  static fromWireData(wireData) {
    return new Address(wireData);
  }

  toWireData() {
    return formatForWire({
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      stateCode: this.stateCode,
      postalCode: this.postalCode,
      countryCode: this.countryCode,
    });
  }
}
