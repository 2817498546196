import { array, object } from 'yup';

import { formatForWire, isNotDefined, parseNumbers } from 'utilities/common';

import { NULLABLE_STRING } from 'modules/common/constants';
import Contact from './contact';
import Email from './email';
import Pet from './pet';
import Phone from './phone';

export default class FamilyInformation {
  static schema = object().shape({
    maritalStatusId: NULLABLE_STRING,
    taxFilingStatusId: NULLABLE_STRING,
    contacts: array().of(Contact.schema),
    pets: array().of(Pet.schema),
    phoneNumbers: array().of(Phone.schema),
    emailAddresses: array().of(Email.schema),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.maritalStatusId = isNotDefined(json.maritalStatusId) ? '': String(json.maritalStatusId);
    this.taxFilingStatusId = isNotDefined(json.taxFilingStatusId) ? '': String(json.taxFilingStatusId);
    this.contacts = json.contacts;
    this.contactPreferenceTypeId = json.contactPreferenceTypeId;
    this.pets = json.pets ? json.pets.map((pet) => new Pet(pet)): [];
    this.phoneNumbers = json.phoneNumbers ? json.phoneNumbers.map((phoneNumber) => new Phone(phoneNumber)): [];
    this.emailAddresses = json.emailAddresses ? json.emailAddresses.map((emailAddress) => new Email(emailAddress)): [];
  }

  static fromWireData(wireData) {
    return new FamilyInformation(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      contacts: this.contacts.map((instance) => instance.toWireData()),
      contactPreferenceTypeId: this.contactPreferenceTypeId,
      pets: this.pets.map((pet) => pet.toWireData()),
      phoneNumbers: this.phoneNumbers.map((instance) => instance.toWireData()),
      emailAddresses: this.emailAddresses.map((instance) => instance.toWireData()),
      ...parseNumbers({
        maritalStatusId: this.maritalStatusId,
        taxFilingStatusId: this.taxFilingStatusId,
      }),
    });
  }
}
