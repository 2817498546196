import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import React from 'react';

const TwoDecimalFormat = (props) => {
  const { onChange, inputRef, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.floatValue !== undefined ? values.floatValue : null);
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

TwoDecimalFormat.propTypes = {
  inputRef: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TwoDecimalFormat;