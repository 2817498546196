import { Button, Grid, Typography, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Checkbox, TextField } from 'modules/intake/form';

import { defaultEndDate } from 'utilities/common';
import ColorIndicator from './colorIndicator.component';
import Colors from './colors';

const styles = () => ({});

class CandidateInternForm extends PureComponent {

  handleProposedStartDate = (event, rootPath) => {
    const newEstimatedStartDate = event.target.value;
    this.props.setFieldValue('primaryInformation.estimatedStartDate', newEstimatedStartDate);
    this.props.setFieldValue(`${rootPath}.candidateIntern.internProposedStartDate`, newEstimatedStartDate);
  };

  handleProposedEndDate = (event, rootPath) => {
    const newEstimatedEndDate = event.target.value;
    this.props.setFieldValue('primaryInformation.estimatedEndDate', newEstimatedEndDate);
    this.props.setFieldValue(`${rootPath}.candidateIntern.internProposedEndDate`, newEstimatedEndDate);
  };

  render() {
    const { candidateIntern, isFormEnabled, onChangeMoveType, isIntern, handleBlur, handleChange, setFieldValue, rootPath } = this.props;

    return (
      <div className="form-group">
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography gutterBottom variant="h3" className={isFormEnabled ? '':  'disabled'}>
              Candidate/Intern
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Button variant="outlined"
              color="secondary"
              onClick={onChangeMoveType}
            > Change Move Type 
            </Button>
          </Grid>
        </Grid>
        {isFormEnabled && (
          <>
            {!isIntern &&
              <>
                <div className="row">
                  <Field
                    component={TextField}
                    name={`${rootPath}.candidateIntern.interviewStartDate`}
                    label="Interview Start Date"
                    value={candidateIntern.interviewStartDate}
                    onChange={(e) => {
                      defaultEndDate(e.target.value, candidateIntern.interviewEndDate, `${rootPath}.candidateIntern.interviewEndDate`, setFieldValue);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    type="date"
                  />
                  <Field
                    component={TextField}
                    name={`${rootPath}.candidateIntern.interviewEndDate`}
                    label="Interview End Date"
                    value={candidateIntern.interviewEndDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                  />
                  <ColorIndicator color={Colors.candidateIntern} />
                </div>
                <div className="row">
                  <Field
                    component={TextField}
                    name={`${rootPath}.candidateIntern.travelStartDate`}
                    label="Travel Start Date"
                    value={candidateIntern.travelStartDate}
                    onChange={(e) => {
                      defaultEndDate(e.target.value, candidateIntern.travelEndDate, `${rootPath}.candidateIntern.travelEndDate`, setFieldValue);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    type="date"
                  />
                  <Field
                    component={TextField}
                    name={`${rootPath}.candidateIntern.travelEndDate`}
                    label="Travel End Date"
                    value={candidateIntern.travelEndDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                  />
                  <ColorIndicator color={Colors.candidateIntern} />
                </div>
              </>
            }
            <div className="row">
              <Field
                component={Checkbox}
                name={`${rootPath}.candidateIntern.hasAreaTour`}
                value={candidateIntern.hasAreaTour}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Has Area Tour"
              />
            </div>
            {isIntern && (
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.candidateIntern.internProposedStartDate`}
                  label="Intern Proposed Start Date"
                  value={candidateIntern.internProposedStartDate}
                  onChange={(e) => {
                    defaultEndDate(e.target.value, candidateIntern.internProposedEndDate, `${rootPath}.candidateIntern.internProposedEndDate`, setFieldValue);
                    this.handleProposedStartDate(e, rootPath);
                  }}
                  onBlur={handleBlur}
                  type="date"
                />
                <Field
                  component={TextField}
                  name={`${rootPath}.candidateIntern.internProposedEndDate`}
                  label="Intern Proposed End Date"
                  value={candidateIntern.internProposedEndDate}
                  onChange={(e) => {
                    this.handleProposedEndDate(e, rootPath);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.candidateIntern} />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

CandidateInternForm.propTypes = {
  classes: PropTypes.object.isRequired,
  candidateIntern: PropTypes.object.isRequired,
  isIntern: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  onChangeMoveType: PropTypes.func.isRequired,
  isFormEnabled: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(CandidateInternForm);
