import { Button, Grid, Link, Typography } from '@material-ui/core';

import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Checkbox, TextField, ToggleSelect } from 'modules/intake/form';
import { HhgBenefits } from 'modules/intake/models';
import { calculateDuration, calculateEndDate, formatDateForInput } from 'utilities/common';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';

import { YES_NO_OPTIONS } from 'utilities/common';
import ColorIndicator from './colorIndicator.component';
import Colors from './colors';

class HouseholdGoodsForm extends Component {

  state = {
    showConfirmation: false,
  };

  handleDisableStorage = () => {
    const {
      householdGoods,
    } = this.props;

    householdGoods.possibleStorageStartDate = '';
    householdGoods.possibleStorageEndDate = '';
    householdGoods.hasDestinationStorage = false;

    this.props.setFieldValue('benefits.householdGoods',
      new HhgBenefits(householdGoods, this.props.temporaryLiving), false);
    this.setState({ showConfirmation: false });
  };

  render() {
    const { householdGoods, isFormEnabled, isServiceEnabled, onEnableService, rootPath, handleBlur, handleChange, isHHGSelfMove } = this.props;
    const { showConfirmation } = this.state;

    return (
      <div className="form-group">
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography gutterBottom variant="h3" className={isFormEnabled ? '':  'disabled'}>Household Goods</Typography>
          </Grid>
          <Grid item md={6}>
            {!isFormEnabled && (
              <Button variant="outlined"
                color="secondary"
                onClick={onEnableService}
              > Enable Service
              </Button>
            )}
            {
              isFormEnabled &&
                <Button variant="outlined" color="secondary" onClick={onEnableService}>{
                  isServiceEnabled ? 'Disable Service' : 'Enable Service'
                }</Button>
            }
          </Grid>
        </Grid>
        {
          (isFormEnabled && !isHHGSelfMove) &&
            <>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.householdGoods.didReview`}
                  value={householdGoods.didReview}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Review main policy points, weight limits, restricted items storage notes"
                />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.householdGoods.notes`}
                  label="Notes"
                  value={householdGoods.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </div>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.householdGoods.didConfirmProvider`}
                  value={householdGoods.didConfirmProvider}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!householdGoods.provider}
                  label={`Client's Main Provider is: ${householdGoods.providerLink ? '': householdGoods.provider}`}
                />
                {householdGoods.providerLink &&
                  <Link target="blank" href={householdGoods.providerLink}>{householdGoods.provider}</Link>
                }
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.householdGoods.preferredSurveyDate`}
                  label="Preferred Survey Date"
                  value={householdGoods.preferredSurveyDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.householdGoods} />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.householdGoods.preferredPackDate`}
                  label="Preferred Pack & Load"
                  value={householdGoods.preferredPackDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.householdGoods} />
              </div>
           
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.householdGoods.preferredDeliveryDate`}
                  label="Goal Delivery Date"
                  value={householdGoods.preferredDeliveryDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.householdGoods} />
              </div>
              <div className="row">
                <Grid item md={6}>
                  <Field
                    component={ToggleSelect}
                    name={`${rootPath}.householdGoods.hasDestinationStorage`}
                    value={householdGoods.hasDestinationStorage}
                    onChange={
                      (event) => {
                        if (householdGoods.hasDestinationStorage === event.target.value ||
                        event.target.value === null)
                          return;
                        if (householdGoods.hasDestinationStorage && !event.target.value) {
                          this.setState({ showConfirmation: true });
                        } else {
                          handleChange(event);
                        }
                      }
                    }
                    label="Storage In Transit"
                    onBlur={handleBlur}
                    options={YES_NO_OPTIONS}
                  />
                </Grid>
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.householdGoods.possibleStorageStartDate`}
                  label="Estimated Storage In Date"
                  value={householdGoods.possibleStorageStartDate}
                  onChange={(event) => {
                    handleChange(event);
                    const endDate = formatDateForInput(calculateEndDate(event.target.value, householdGoods.storageLength));
                    this.props.setFieldValue(`${rootPath}.householdGoods.possibleStorageEndDate`, endDate);
                  }}
                  onBlur={handleBlur}
                  type="date"
                  disabled={!householdGoods.hasDestinationStorage}
                />
                <Field
                  component={TextField}
                  name={`${rootPath}.householdGoods.storageLength`}
                  label="Storage Length"
                  value={householdGoods.storageLength}
                  onChange={(event) => {
                    handleChange(event);
                    const endDate = formatDateForInput(calculateEndDate(householdGoods.possibleStorageStartDate, event.target.value));
                    if (endDate) {
                      this.props.setFieldValue(`${rootPath}.householdGoods.possibleStorageEndDate`, endDate);
                    }
                  }}
                  onBlur={handleBlur}
                  numeric
                  suffix=" days"
                  disabled={!householdGoods.hasDestinationStorage}
                />
                <Field
                  component={TextField}
                  name={`${rootPath}.householdGoods.possibleStorageEndDate`}
                  label="Estimated Storage Out Date"
                  value={householdGoods.possibleStorageEndDate}
                  onChange={(event) => {
                    handleChange(event);
                    const duration = calculateDuration(householdGoods.possibleStorageStartDate, event.target.value);
                    this.props.setFieldValue(`${rootPath}.householdGoods.storageLength`, duration < 0 ? '' : duration);
                  }}
                  onBlur={handleBlur}
                  type="date"
                  disabled={!householdGoods.hasDestinationStorage}
                />
                <ColorIndicator color={Colors.householdGoods} />
              </div>
            </>
        }
        {
          (isFormEnabled && isHHGSelfMove) &&
            <>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.householdGoods.didReview`}
                  value={householdGoods.didReview}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Review main policy points, weight limits, restricted items storage notes"
                />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.householdGoods.notes`}
                  label="Notes"
                  value={householdGoods.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.householdGoods.preferredPackDate`}
                  label="Estimated Pack & Load"
                  value={householdGoods.preferredPackDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.householdGoods} />
              </div>
            </>
        }
        <ConfirmationDialog
          open={showConfirmation}
          title="Disable Option?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleDisableStorage}
          onCancel={() => this.setState({ showConfirmation: false })}
        >
          <Typography>
            If you disable this option, you will lose all data entered in this section.{' '}
            <br />
            <br />
            Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

HouseholdGoodsForm.propTypes = {
  householdGoods: PropTypes.object.isRequired,
  temporaryLiving: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onEnableService: PropTypes.func.isRequired,
  isFormEnabled: PropTypes.bool.isRequired,
  isServiceEnabled: PropTypes.bool.isRequired,
};

export default HouseholdGoodsForm;
