import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      cursor: 'auto !important',
    },
  },
  content: {
    marginTop: 0,
    marginBottom: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

export default ExpansionPanelSummary;
