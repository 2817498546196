import { withStyles } from '@material-ui/core/styles';

import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

const styles = (theme) => ({
  step: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(-2),
    height: 32,
    width: 32,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: 16,
    textAlign: 'center',
    lineHeight: '32px',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

class StepLabel extends PureComponent {
  render() {
    const { classes, children, active } = this.props;
    return (
      <span className={classNames(classes.step, { [classes.active]: active })}>{children}</span>
    );
  }
}

StepLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
};

export default compose(
  withStyles(styles),
)(StepLabel);
