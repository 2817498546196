/* eslint-disable react/prop-types */
import { MenuItem, TextField, withStyles } from '@material-ui/core';

import { chain } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import memoize from 'memoize-one';

import { GET_CLIENTS_FAILURE, GET_RELOCATION_CONSULTANTS_FAILURE } from 'modules/common/types';
import { getClients, getRelocationConsultants } from 'modules/common/common.actions';
import { setPageTitle, showToast } from 'modules/layout/layout.actions';
import Log from 'utilities/log';
import auth from 'auth/auth';

import AddNoteDialog from '../../common/addNoteDialog.component';
import DeleteIntakeRecordDialog from './deleteIntakeRecordDialog.component';
import ReassignIntakeDialog from './reassignIntakeDialog.component';
import WebAccessDialog from './webAccessDialog.component';

import {
  ADD_NOTE_FAILURE,
  ASSIGN_INTAKE_RECORD_FAILURE,
  DELETE_INTAKE_RECORD_FAILURE,
  GET_INTAKE_FORM_METADATA_FAILURE,
  GET_INTAKE_RECORD_FAILURE,
  GET_INTAKE_SUMMARIES_FAILURE,
  SET_INTAKE_RECORD_URGENCY_FAILURE,
} from '../types';
import { NOTE_MAX_LENGTH } from '../../common/constants';
import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../layout/types';
import {
  addNoteToRecord,
  assignIntakeRecordToRelocationConsultant,
  clearCachedIntakeRecord,
  deleteIntakeRecord,
  getIntakeFormMetadata,
  getIntakeRecord,
  getIntakeSummaries,
  grantWebAccess,
  setIntakeRecordUrgency,
} from '../intake.actions';
import IntakeRecordsGrid from './intakeRecordsGrid.component';
import WebAccessAlterationsDialog from './webAccessAlterationsDialog.component';

const styles = (theme) => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

class IntakeRecordsView extends PureComponent {
  state = {
    clientId: -1,
    relocationConsultantId: -1,
    addingNoteForRecord: null,
    isAddingNote: false,
    reassigningRecord: null,
    deletingRecord: null,
    isReassigningRecord: false,
    isDeletingRecord: false,
    webAccessRecord: null,
    showWebAccessModal: false,
    showAlterWebAccessModal: false,
  };

  async componentDidMount() {
    this.props.setPageTitle('My Transferees');

    if (!this.props.intakeSummaries || this.props.requiresSummaryRefresh) {
      let { type } = await this.props.getIntakeSummaries();
      if (type === GET_INTAKE_SUMMARIES_FAILURE) {
        this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.metadata) {
      let { type } = await this.props.getIntakeFormMetadata();
      if (type === GET_INTAKE_FORM_METADATA_FAILURE) {
        this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.clients) {
      let { type } = await this.props.getClients();
      if (type === GET_CLIENTS_FAILURE) {
        this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.relocationConsultants && auth.userHasRole('admin')) {
      let { type } = await this.props.getRelocationConsultants();
      if (type === GET_RELOCATION_CONSULTANTS_FAILURE) {
        this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }
  }

  handleSummaryChange = async (record) => {
    const { intakeRecordId, intakeRecordUrgency } = record;
    const { type } = await this.props.setIntakeRecordUrgency(intakeRecordId, intakeRecordUrgency);
    if (type === SET_INTAKE_RECORD_URGENCY_FAILURE) {
      this.props.showToast('Failed to set urgency, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  };

  handleSummarySelect = (record) => {
    this.props.history.push(`/intakeRecords/${record.intakeRecordId}`);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleNoteAdded = async (note) => {
    this.setState({ isAddingNote: true });
    const { type } = await this.props.addNoteToRecord(note, this.state.addingNoteForRecord);
    this.setState({ isAddingNote: false });
    if (type === ADD_NOTE_FAILURE) {
      this.props.showToast('Failed to save note, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      // kick off data refresh
      this.props.getIntakeSummaries();
      this.props.clearCachedIntakeRecord(this.state.addingNoteForRecord.intakeRecordId);
      this.setState({ addingNoteForRecord: null });
    }
  };

  handleAddNote = (record) => {
    this.setState({ addingNoteForRecord: record });
  };

  handleReassigned = async (relocationConsultant) => {
    this.setState({ isReassigningRecord: true });
    const { type } = await this.props.assignIntakeRecordToRelocationConsultant(this.state.reassigningRecord, relocationConsultant);
    this.setState({ isReassigningRecord: false });
    if (type === ASSIGN_INTAKE_RECORD_FAILURE) {
      this.props.showToast('Failed to reassign record, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      this.setState({ reassigningRecord: null });
      // kick off data refresh
      this.props.getIntakeSummaries();
    }
  };

  handleReassign = (record) => {
    this.setState({ reassigningRecord: record });
  };

  handleDeleted = async () => {
    this.setState({ isDeletingRecord: true });
    const { type } = await this.props.deleteIntakeRecord(this.state.deletingRecord);
    this.setState({ isDeletingRecord: false });
    if (type === DELETE_INTAKE_RECORD_FAILURE) {
      this.props.showToast('Failed to delete record, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      this.setState({ deletingRecord: null });
      // kick off data refresh
      this.props.getIntakeSummaries();
    }
  };

  handleDelete = (record) => {
    this.setState({ deletingRecord: record });
  };

  filterSummaries = memoize((intakeSummaries, clientId, relocationConsultantId) => {
    return intakeSummaries.filter((summary) => {
      return (clientId === -1 || summary.client.id === clientId) &&
        (relocationConsultantId === -1 || summary.relocationConsultant.id === relocationConsultantId);
    });
  });


  getClientList = memoize((clients) => {
    if (!clients) {
      return [];
    }
    return chain(clients)
      .values()
      .uniqWith(clients, (a, b) => a.description === b.description)
      .value()
      .sort((a, b) => String(a.description).localeCompare(b.description))
      .filter((instance) => instance.description);
  });

  getRelocationConsultantList = memoize((relocationConsultants) => {
    if (!relocationConsultants) {
      return [];
    }
    return chain(relocationConsultants)
      .values()
      .uniqWith(relocationConsultants, (a, b) => a.fullName === b.fullName)
      .value()
      .sort((a, b) => String(a.fullName).localeCompare(b.fullName))
      .filter((instance) => instance.fullName);
  });

  webAccessClicked = async (record) => {
    this.setState({ showWebAccessModal: true });
    let resp = await this.props.getIntakeRecord(record.intakeRecordId);
    if (resp.type === GET_INTAKE_RECORD_FAILURE) {
      this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
    let intakeRecord = resp.response;
    this.setState({ webAccessRecord: intakeRecord });
  }

  alterWebAccessClicked = async (record) => {
    this.setState({ showAlterWebAccessModal: true });
    let resp = await this.props.getIntakeRecord(record.intakeRecordId);
    if (resp.type === GET_INTAKE_RECORD_FAILURE) {
      this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
    let intakeRecord = resp.response;
    this.setState({ webAccessRecord: intakeRecord });
  }

  render() {
    Log.trace('RENDER', 'IntakeRecordsView');
    const { clientId, relocationConsultantId, addingNoteForRecord, isAddingNote, reassigningRecord, isReassigningRecord, deletingRecord, isDeletingRecord, showWebAccessModal, showAlterWebAccessModal, webAccessRecord } = this.state;
    const { classes, clients, relocationConsultants, intakeSummaries, isLoading } = this.props;
    const intakeRecords = this.filterSummaries(intakeSummaries || [], clientId, relocationConsultantId);
    const sortedClients = this.getClientList(clients);
    let sortedRelocationConsultants = [];
    let assignee = null;

    if (auth.userHasRole('admin')) {
      sortedRelocationConsultants = this.getRelocationConsultantList(relocationConsultants);
      assignee = reassigningRecord ? sortedRelocationConsultants.find((user) => user.id === reassigningRecord.relocationConsultant.id) : null;
    }

    return (
      <Fragment>
        <div className={classes.headerContainer}>
          <TextField
            select
            label="Clients"
            className={classes.textField}
            value={clientId}
            onChange={this.handleChange('clientId')}
            SelectProps={{
              autoWidth: true,
            }}
            variant="outlined"
          >
            <MenuItem value={-1}>
              <em>All Clients</em>
            </MenuItem>
            {sortedClients.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
          {auth.userHasRole('admin') &&
            <TextField
              select
              label="Consultants"
              className={classes.textField}
              value={relocationConsultantId}
              onChange={this.handleChange('relocationConsultantId')}
              SelectProps={{
                autoWidth: true,
              }}
              variant="outlined"
            >
              <MenuItem value={-1}>
                <em>All Consultants</em>
              </MenuItem>
              {sortedRelocationConsultants.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.fullName}
                </MenuItem>
              ))}
            </TextField>
          }
        </div>
        <div className="px-2">
          <IntakeRecordsGrid
            intakeRecords={intakeRecords}
            onChange={this.handleSummaryChange}
            onSelect={this.handleSummarySelect}
            onAddNote={this.handleAddNote}
            onReassign={this.handleReassign}
            onDelete={this.handleDelete}
            onGrantWebAccess={this.webAccessClicked}
            onAlterWebAccess={this.alterWebAccessClicked}
            isLoading={isLoading}
          />
        </div>
        {addingNoteForRecord && // forces note to clear each time, allows for retrying save if it fails
          <AddNoteDialog
            disabled={isAddingNote}
            open={true}
            onDiscard={() => this.setState({ addingNoteForRecord: null })}
            onSubmit={this.handleNoteAdded}
            maxLength={NOTE_MAX_LENGTH}
          />
        }
        {reassigningRecord &&
          <ReassignIntakeDialog
            disabled={isReassigningRecord}
            open={true}
            onCancel={() => this.setState({ reassigningRecord: null })}
            onSubmit={this.handleReassigned}
            assignee={assignee}
            relocationConsultants={sortedRelocationConsultants}
          />
        }
        {deletingRecord &&
          <DeleteIntakeRecordDialog
            disabled={isDeletingRecord}
            open={true}
            onCancel={() => this.setState({ deletingRecord: null })}
            onSubmit={this.handleDeleted}
          />
        }
        {showWebAccessModal && 
          <WebAccessDialog
            disabled={isDeletingRecord}
            open={true}
            onCancel={() => this.setState({ showWebAccessModal: false, webAccessRecord: null })}
            onSubmit={this.handleDeleted}
            record={webAccessRecord}
          />
        }
        {showAlterWebAccessModal && 
          <WebAccessAlterationsDialog
            disabled={isDeletingRecord}
            open={true}
            onCancel={() => this.setState({ showAlterWebAccessModal: false, webAccessRecord: null })}
            onSubmit={this.handleDeleted}
            record={webAccessRecord}
          />
        }
      </Fragment>
    );
  }
}

IntakeRecordsView.propTypes = {
  setPageTitle: PropTypes.func.isRequired,
  getIntakeSummaries: PropTypes.func.isRequired,
  setIntakeRecordUrgency: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  intakeSummaries: PropTypes.array,
  classes: PropTypes.object.isRequired,
  clients: PropTypes.object,
  relocationConsultants: PropTypes.object,
  getClients: PropTypes.func.isRequired,
  getRelocationConsultants: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showToast: PropTypes.func.isRequired,
  requiresSummaryRefresh: PropTypes.bool.isRequired,
  getIntakeFormMetadata: PropTypes.func.isRequired,
  metadata: PropTypes.object,
  addNoteToRecord: PropTypes.func.isRequired,
  assignIntakeRecordToRelocationConsultant: PropTypes.func.isRequired,
  deleteIntakeRecord: PropTypes.func.isRequired,
  clearCachedIntakeRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    intake: { isLoading, intakeSummaries, requiresSummaryRefresh, metadata },
    common: { clients, relocationConsultants },
  } = state;
  return { isLoading, intakeSummaries, clients, relocationConsultants, requiresSummaryRefresh, metadata };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    getIntakeSummaries,
    getIntakeRecord,
    setIntakeRecordUrgency,
    getClients,
    getRelocationConsultants,
    showToast,
    getIntakeFormMetadata,
    addNoteToRecord,
    assignIntakeRecordToRelocationConsultant,
    deleteIntakeRecord,
    grantWebAccess,
    clearCachedIntakeRecord,
  }),
)(IntakeRecordsView);
