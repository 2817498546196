import * as Sentry from '@sentry/browser';
import { SET_SELF_SERVICE_ERROR_EVENT_ID } from './apiError.types';
import Log from 'utilities/log';
import Symbol from 'es6-symbol';
import auth from 'auth/auth';
import history from 'browserHistory';

export const API_BASE_URL = process.env.REACT_APP_API_URL|| 'http://localhost:3001/';

function callApi(token, endpoint, authenticated, method = 'GET', payload) {
  const baseUrl = `${API_BASE_URL}v1/internal/`;

  // include credentials
  // let config = { headers: {}, credentials: "include" };

  // no credentials
  let config = { headers: {} };

  if (authenticated) {
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      Log.trace('No token available.');
      throw new Error('No token saved!');
    }
  }
  config.method = method;
  if (typeof payload !== 'undefined') {
    //Is this form data?
    if (Object.prototype.toString.call(payload) === '[object FormData]') {
      config.body = payload;
    } else {
      config.body = JSON.stringify(payload);
      config.headers['Content-Type'] = 'application/json; charset=utf-8';
    }
  }

  // add cookie to request
  return fetch(baseUrl + endpoint, config)
    .then((response) => {
      if (response.status > 399) {
        Log.trace(`Error during fetch to ${baseUrl + endpoint}: `, response.status);
      }
      return response.json()
        .then((json) => {
          return ({ json, response });
        })
        .catch((e) => {
          return ({
            json: [],
            response,
          });
        });
    })
    .then(({ json, response }) => {
      if (response.status === 401) {
        return Promise.reject({ authenticationRequired: true });
      }
      if (response.status === 409) {
        return Promise.reject({ isCollision: true, ...json });
      }
      if (!response.ok) {
        return Promise.reject(json);
      }
      let metadata = {};
      if (response.headers.get('X-Total-Count')) {
        metadata.totalCount = Number(response.headers.get('X-Total-Count'));
      }
      return { json, metadata };
    });
}

export const CALL_API = Symbol('Call API');

export default ({ dispatch, getState }) => (next) => (action) => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint, types, authenticated, method, payload, options, context } = callAPI;

  const [requestType, successType, errorType] = types;

  next({
    type: requestType,
    payload,
    context,
  });

  let jwt;
  if (authenticated) {
    try {
      jwt = auth.getAccessToken();
    } catch (e) {
      Log.trace('ERROR GETTING TOKEN', e);
    }
  }

  // Passing the authenticated boolean back in our data will let us distinguish
  // between normal and secret quotes
  return callApi(jwt, endpoint, authenticated, method, payload).then(
    (response) => {
      return next({
        response: response.json,
        metadata: response.metadata,
        authenticated,
        payload,
        type: successType,
        options,
        context,
      });
    },
    async (error) => {
      const resp = {
        type: errorType,
        context,
        isCollision: error.isCollision,
      };
      if (error.messages) {
        resp.messages = error.messages;
      } else if (error.message) {
        resp.messages = [error.message];
      } else {
        resp.messages = ['There was an error.'];
      }

      Sentry.captureException(new Error(`API Error: ${JSON.stringify(resp)}`));
      dispatch({ type: SET_SELF_SERVICE_ERROR_EVENT_ID, payload: error.eventId });

      const nextRes = next(resp);
      if (error.authenticationRequired) {
        const wasRenewed = await auth.renewSession();
        if (!wasRenewed) {
          history.replace('/login', {
            from: { pathname: history.location.pathname },
          });
        }
      }
      return nextRes;
    },
  );
};
