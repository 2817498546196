import { Button, Grid, Typography, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { TextField } from 'modules/intake/form';
import { calculateDuration, calculateEndDate, defaultEndDate, formatDateForInput } from 'utilities/common';

import ColorIndicator from './colorIndicator.component';
import Colors from './colors';

const styles = () => ({

});

class HouseHuntingForm extends PureComponent {
  render() {
    const { houseHunting, isFormEnabled, onEnableService, handleBlur, handleChange, rootPath } = this.props;

    return (
      <div className="form-group">
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography gutterBottom variant="h3" className={isFormEnabled ? '':  'disabled'}>House Hunting</Typography>
          </Grid>
          <Grid item md={6}>
            <Button variant="outlined" color="secondary" onClick={onEnableService}>{
              isFormEnabled ? 'Disable Service' : 'Enable Service'
            }</Button>
          </Grid>
        </Grid>
        {
          isFormEnabled && 
            <div className="row">
              <Field
                component={TextField}
                name={`${rootPath}.houseHunting.travelStartDate`}
                label="Travel Start Date"
                value={houseHunting.travelStartDate}
                onChange={(event) => {
                  handleChange(event);
                  if (houseHunting.travelLength) {
                    const endDate = formatDateForInput(calculateEndDate(event.target.value, houseHunting.travelLength));
                    this.props.setFieldValue(`${rootPath}.houseHunting.travelEndDate`, endDate);
                  } else {
                    const endDate = defaultEndDate(event.target.value, houseHunting.travelEndDate, `${rootPath}.houseHunting.travelEndDate`, this.props.setFieldValue);
                    const duration = calculateDuration(event.target.value, endDate);
                    this.props.setFieldValue(`${rootPath}.houseHunting.travelLength`, duration < 0 ? '' : duration);
                  }
                }}
                onBlur={handleBlur}
                type="date"
              />
              <Field
                component={TextField}
                name={`${rootPath}.houseHunting.travelLength`}
                label="Travel Length"
                value={houseHunting.travelLength}
                onChange={(event) => {
                  handleChange(event);
                  const endDate = formatDateForInput(calculateEndDate(houseHunting.travelStartDate, event.target.value));
                  if (endDate) {
                    this.props.setFieldValue(`${rootPath}.houseHunting.travelEndDate`, endDate);
                  }
                }}
                onBlur={handleBlur}
                numeric
                suffix=" days"
              />
              <Field
                component={TextField}
                name={`${rootPath}.houseHunting.travelEndDate`}
                label="Travel End Date"
                value={houseHunting.travelEndDate}
                onChange={(event) => {
                  handleChange(event);
                  const duration = calculateDuration(houseHunting.travelStartDate, event.target.value);
                  this.props.setFieldValue(`${rootPath}.houseHunting.travelLength`, duration < 0 ? '' : duration);
                }}
                onBlur={handleBlur}
                type="date"
              />
              <ColorIndicator color={Colors.houseHunting} />
            </div>
        }
      </div>
    );
  }
}

HouseHuntingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  houseHunting: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onEnableService: PropTypes.func.isRequired,
  isFormEnabled: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
)(HouseHuntingForm);
