import { Grid, Typography } from '@material-ui/core';

import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SpousalAssistanceBenefits } from 'modules/intake/models';
import { TextField, ToggleSelect } from 'modules/intake/form';
import { YES_NO_OPTIONS } from 'utilities/common';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';

import ColorIndicator from './colorIndicator.component';
import Colors from './colors';

class SpousalAssistanceForm extends Component {

  state = {
    showConfirmation: false,
  };

  handleDisableService = () => {
    this.props.setFieldValue('benefits.spousalAssistance', new SpousalAssistanceBenefits({ isEligible: false }), false);
    this.setState({ showConfirmation: false });
  };

  render() {
    const { spousalAssistance, handleBlur, handleChange, rootPath } = this.props;
    const { showConfirmation } = this.state;

    return (
      <div className="form-group">
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography gutterBottom variant="h3" className={spousalAssistance.isEligible ? '' : 'disabled'}>Spousal Assistance</Typography>
          </Grid>
          <Grid item md={6}>
            <Field
              component={ToggleSelect}
              name={`${rootPath}.spousalAssistance.isEligible`}
              value={spousalAssistance.isEligible}
              onChange={(event) => {
                if (spousalAssistance.isEligible === event.target.value ||
                  event.target.value === null)
                  return;
                if (spousalAssistance.isEligible && !event.target.value) {
                  this.setState({ showConfirmation: true });
                } else {
                  handleChange(event);
                }
              }}
              onBlur={handleBlur}
              options={YES_NO_OPTIONS}
            />
          </Grid>
        </Grid>
        {
          spousalAssistance.isEligible && 
            <div className="row">
              <Field
                component={TextField}
                name={`${rootPath}.spousalAssistance.estimatedDate`}
                label="Estimated Date"
                value={spousalAssistance.estimatedDate}
                onChange={handleChange}
                onBlur={handleBlur}
                type="date"
                disabled={spousalAssistance.isEligible !== true}
              />
              <ColorIndicator color={Colors.spousalAssistance} />
            </div>
        }
        <ConfirmationDialog
          open={showConfirmation}
          title="Disable Option?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleDisableService}
          onCancel={() => this.setState({ showConfirmation: false })}
        >
          <Typography>
            If you disable this option, you will lose all data entered in this section.{' '}
            <br />
            <br />
            Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

SpousalAssistanceForm.propTypes = {
  spousalAssistance: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default SpousalAssistanceForm;
