import { combineReducers } from 'redux';

import apiError from 'middleware/apiError.reducer';
import auth from 'modules/auth/auth.reducer';
import benefit from 'modules/benefit/benefit.reducer';
import common from 'modules/common/common.reducer';
import intake from 'modules/intake/intake.reducer';
import layout from 'modules/layout/layout.reducer';
import selfService from 'modules/selfService/selfService.reducer';

const rootReducer = combineReducers({
  auth,
  intake,
  common,
  layout,
  benefit,
  selfService,
  apiError,
});

export default rootReducer;
