import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { isEmpty } from 'lodash';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    width: '100%',
  },
  suggestionsContainerOpen: {
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.tooltip,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  limitContainerHeight: {
    maxHeight: 250,
    overflow: 'auto',
  },
});

class CustomAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue || '',
    };
  }

  handleChange = (event, { newValue }) => {
    if (this.props.clearSelectionOnEdit === true) newValue = '';
    if (newValue === '' && this.props.onInputClear) this.props.onInputClear();

    this.setState({
      value: newValue,
    });

    if (this.props.onInputEdit) this.props.onInputEdit(newValue);
  };

  handleSelection = (event, selection) => {
    if (this.props.clearInputOnSelect === true) {
      this.handleChange(null, { newValue: '' });
    }

    this.props.handleSelection(event, selection);
  };

  renderInput = (inputProps) => {
    const { classes, ref, label, ...other } = inputProps;

    return (
      <TextField
        type="text"
        variant="outlined"
        fullWidth
        label={label}
        classes={{ root: 'mb-0' }}
        helperText={!isEmpty(this.props.helperText) ? this.props.helperText : ''}
        InputProps={{
          inputRef: ref,
          classes: {
            input: classes.input,
          },
          ...other,
        }}
      />
    );
  };

  renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options;
    const { logo } = this.props;
    return (
      <Paper {...containerProps} square>
        {children}
        {children && logo}
      </Paper>
    );
  };

  handleBlur = (event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      this.handleSelection(event, { suggestion: highlightedSuggestion });
    }

    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(event);
    }
  };

  render() {
    const { classes, logo } = this.props;

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classNames(classes.suggestionsContainerOpen, {
            [classes.limitContainerHeight]: !logo,
          }),
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={this.props.suggestions}
        onSuggestionsFetchRequested={this.props.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.props.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSelection}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        getSuggestionValue={this.props.getSuggestionValue}
        renderSuggestion={this.props.renderSuggestion}
        shouldRenderSuggestions={(value) => this.props.showSuggestionOnFocus ? true : value.trim().length > 0}
        inputProps={{
          classes,
          label: this.props.label,
          placeholder: this.props.placeholder,
          value: this.state.value,
          onChange: this.handleChange,
          onBlur: this.handleBlur,
          onFocus: this.props.onFocus,
          disabled: this.props.disabled,
        }}
      />
    );
  }
}

CustomAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  handleSuggestionsFetchRequested: PropTypes.func.isRequired,
  handleSuggestionsClearRequested: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  showSuggestionOnFocus: PropTypes.bool,
  onInputClear: PropTypes.func,
  onInputEdit: PropTypes.func,
  clearInputOnSelect: PropTypes.bool,
  suggestions: PropTypes.array.isRequired,
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  clearSelectionOnEdit: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  logo: PropTypes.node,
};

export default withStyles(styles)(CustomAutocomplete);
